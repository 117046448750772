import { AppThunkAction } from 'store';

import i18n from 'i18n-js';

import agent from 'api/agent';
import { RestaurantPaymentDetailsModel } from 'api/models';
import { RestaurantCreateModel } from 'store/restaurantsStore/reducer';
import globalStore from 'store/globalStore';
import { RestaurantDetailsAction } from './actions';
import { RestaurantDetails, QRData, RestaurantContactPerson } from './reducer';

export const actionCreators = {
    getRestaurantDetails:
        (id: string): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'RESTAURANT_DETAILS_GET_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.GetDetails(id)
                .then((response) =>
                    dispatch({
                        type: 'RESTAURANT_DETAILS_GET_SUCCESS',
                        restaurant: response,
                    })
                )
                .catch((e) => {
                    dispatch({
                        type: 'RESTAURANT_DETAILS_GET_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    updateRestaurantDetails:
        (id: string, restaurant: RestaurantCreateModel): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'RESTAURANT_DETAILS_UPDATE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.Update(id, restaurant)
                .then((response) => {
                    dispatch({
                        type: 'RESTAURANT_DETAILS_UPDATE_SUCCESS',
                        restaurant: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'RESTAURANT_DETAILS_UPDATE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    getRestaurantInformation: (): AppThunkAction<RestaurantDetailsAction> => (dispatch) => {
        dispatch({
            type: 'GET_RESTAURANT_INFORMATION_START',
        });
        globalStore.actionCreators.showSpiner()(dispatch);
        agent.Restaurants.GetRestaurantInformation()
            .then((response) => {
                dispatch({
                    type: 'GET_RESTAURANT_INFORMATION_SUCCESS',
                    restaurantInformation: response,
                });
            })
            .catch((e) => {
                dispatch({
                    type: 'GET_RESTAURANT_INFORMATION_ERROR',
                });
                globalStore.actionCreators.showToaster('error', e)(dispatch);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },
    updateRestaurantInformation:
        (model: RestaurantDetails): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({ type: 'UPDATE_RESTAURANT_INFORMATION_START' });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.UpdateRestaurantInformation(model)
                .then((response: any) => {
                    dispatch({
                        type: 'UPDATE_RESTAURANT_INFORMATION_SUCCESS',
                        restaurantInformation: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'UPDATE_RESTAURANT_INFORMATION_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    getCuisineTypes: (): AppThunkAction<RestaurantDetailsAction> => (dispatch) => {
        dispatch({
            type: 'GET_CUISINE_TYPES_START',
        });
        globalStore.actionCreators.showSpiner()(dispatch);
        agent.Restaurants.GetCuisineTypes()
            .then((response) => {
                dispatch({
                    type: 'GET_CUISINE_TYPES_SUCCESS',
                    data: response,
                });
            })
            .catch((e) => {
                dispatch({
                    type: 'GET_CUISINE_TYPES_ERROR',
                });
                globalStore.actionCreators.showToaster('error', e)(dispatch);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },
    getQrCodes:
        (params = {}): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_QR_CODES_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.GetQrs(params)
                .then((response) => {
                    dispatch({
                        type: 'GET_QR_CODES_SUCCESS',
                        data: response,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_QR_CODES_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    createQrCode:
        (qr: QRData, params: {}): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'CREATE_QR_CODE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.CreateQrCode(qr, params)
                .then(() => {
                    // @ts-ignore
                    actionCreators.getQrCodes(params)(dispatch);
                    dispatch({
                        type: 'CREATE_QR_CODE_SUCCESS',
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.createdSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'CREATE_QR_CODE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    updateQrCode:
        (qr: QRData): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_QR_CODE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.UpdateQrCode(qr)
                .then(() => {
                    dispatch({
                        type: 'UPDATE_QR_CODE_SUCCESS',
                        data: qr,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'UPDATE_QR_CODE_ERROR' });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    deleteQrCode:
        (qrs: string[]): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'DELETE_QR_CODE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.DeleteQrCode(qrs)
                .then(() => {
                    dispatch({
                        type: 'DELETE_QR_CODE_SUCCESS',
                        data: qrs,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.deletedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'DELETE_QR_CODE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    updateContactPersons:
        (users: RestaurantContactPerson[]): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_CONTACT_PERSONS',
                users: users,
            });
        },
    updatePaymentDetails:
        (model: RestaurantPaymentDetailsModel): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_PAYMENT_DETAILS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.UpdatePaymentDetails(model)
                .then((response) => {
                    dispatch({
                        type: 'UPDATE_PAYMENT_DETAILS_SUCCESS',
                        data: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'UPDATE_PAYMENT_DETAILS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    updateQrCodesName:
        (name: string): AppThunkAction<RestaurantDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_QR_CODES_NAME_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.UpdateQrCodesName(name)
                .then((response) => {
                    dispatch({
                        type: 'UPDATE_QR_CODES_NAME_SUCCESS',
                        data: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'UPDATE_QR_CODES_NAME_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    getRestaurantResponsibleUsers: (): AppThunkAction<RestaurantDetailsAction> => (dispatch) => {
        dispatch({
            type: 'GET_RESTAURANT_RESPONSIBLE_USERS_START',
        });
        agent.Users.GetResponsibleUsers()
            .then((response) => {
                dispatch({
                    type: 'GET_RESTAURANT_RESPONSIBLE_USERS_SUCCESS',
                    data: response,
                });
            })
            .catch((e) => {
                dispatch({
                    type: 'GET_RESTAURANT_RESPONSIBLE_USERS_ERROR',
                });
                globalStore.actionCreators.showToaster('error', e)(dispatch);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },
};
