import { Action, Reducer } from 'redux';
import { RolesAction } from './actions';

export interface RolesState {
    roles: RoleData[];
}

export interface RoleData {
    id: string;
    name: string;
}

const initialState: RolesState = {
    roles: [],
};

export const reducer: Reducer<RolesState> = (
    state: RolesState | undefined,
    incomingAction: Action
): RolesState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as RolesAction;
    switch (action.type) {
        case 'ROLES_GET_SUCCESS':
            return {
                ...state,
                roles: action.users,
            };
        default:
            return state;
    }
};
