import React from 'react';

import { Chip, makeStyles } from '@material-ui/core';

import { roleColor } from 'constants/maps';
import { regexHelper } from 'helpers/regexHelper';
import { Roles } from 'constants/enums';
import i18n from 'i18n-js';

export type RoleLabelSize = 'large' | 'medium' | 'small';

interface UserRoleProps {
    role: string;
    size?: RoleLabelSize;
}

const useStyles = makeStyles({
    admin: {
        backgroundColor: '#5FCF55',
    },
    restaurantOwner: {
        backgroundColor: '#7B9AE9',
    },
    default: {
        backgroundColor: '#e0e0e0',
    },
    label: {
        '& span': {
            padding: 0,
        },
    },
    large: {
        width: 133,
    },
    medium: {
        width: 62,
    },
    small: {
        width: 18,
        height: 18,
        borderRadius: 100,
    },
});

export default function UserRoleLabel(props: UserRoleProps) {
    const classes = useStyles();

    function prepareName(name: string) {
        switch (name) {
            case Roles.EMPLOYEE:
                return 'E';
            case Roles.OWNER:
                return 'O';
            default:
                return name.substr(0, 1);
        }
    }

    function getName(name: string) {
        if (i18n.locale === 'de') {
            switch (name) {
                case 'Restaurant Owner':
                    return 'Restaurantbesitzer';
                case 'Owner':
                    return 'Inhaber';
                case 'Employee':
                    return 'Arbeitnehmer';
                case 'Mobile User':
                    return 'Mobiler Benutzer';
                case 'Restaurant Employee':
                    return 'Restaurantmitarbeiter';
            }
        }
        return name;
    }
    return (
        <>
            {!props.size || props.size === 'large' ? (
                <Chip
                    size="small"
                    label={getName(
                        props.role?.replace(regexHelper.capitalCharacters, ' $1').trim()
                    )}
                    className={`${classes.label} ${classes.large}`}
                    style={{ backgroundColor: roleColor.get(props.role) }}
                />
            ) : props.size === 'medium' ? (
                <Chip
                    size="small"
                    label={getName(
                        props.role?.split(/(?=[A-Z])/).length > 1
                            ? props.role?.split(/(?=[A-Z])/)[1]
                            : props.role
                    )}
                    className={`${classes.label} ${classes.medium}`}
                    style={{ backgroundColor: roleColor.get(props.role) }}
                />
            ) : (
                <Chip
                    size="small"
                    label={getName(prepareName(props.role))}
                    className={`${classes.label} ${classes.small}`}
                    style={{ backgroundColor: roleColor.get(props.role) }}
                />
            )}
        </>
    );
}
