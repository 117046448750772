import React from 'react';

import { Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import i18n from 'i18n-js';

import { useStyles } from '../styles';

interface WorkingDayWarningProps {
    open: boolean;
    onClose: () => void;
    workingHours: string;
}

export default function WorkingDayWarning(props: WorkingDayWarningProps) {
    const classes = useStyles();
    return (
        <Dialog open={props.open} aria-labelledby="form-dialog-title">
            <DialogTitle className={classes.warningTitle}>{i18n.t('common.warning')}</DialogTitle>
            <DialogContent>
                <p>
                    <span className={classes.warningSubTitle}>
                        {i18n.t('navigationTabs.workingDayWarning')}.
                    </span>
                    <span className={classes.warningHours}>({props.workingHours})</span>
                </p>
                <p>
                    <span>{i18n.t('navigationTabs.allOpenOrders')}</span>
                    <span>{i18n.t('navigationTabs.allOpenOrdersCheck')}</span>
                </p>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onClose}
                        className={classes.closeButton}
                    >
                        {i18n.t('navigationTabs.iGotIt')}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
