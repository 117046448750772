import React, { useState, useEffect } from 'react';

import { images } from 'assets';
import { OrderStatus } from 'constants/enums';
import { statusColor } from 'constants/arrays';

interface OrderListStatusProps {
    status: OrderStatus;
    showReady: boolean;
}

export default function OrdersListStatus(props: OrderListStatusProps) {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const delayRender = setTimeout(() => {
            setReady(props.showReady);
        }, 500);
        return () => clearTimeout(delayRender);
    }, [props.showReady]);

    return ready ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <img src={images.icons.triangleAtention} alt="triangle-atention" />
            <div
                style={{
                    width: 12,
                    height: 12,
                    borderRadius: 50,
                    backgroundColor: statusColor[props.status],
                }}
            />
        </div>
    ) : (
        <div
            style={{
                width: 12,
                height: 12,
                borderRadius: 50,
                backgroundColor: statusColor[props.status],
                marginLeft: '60%',
            }}
        />
    );
}
