import React from 'react';
import { useSelector } from 'react-redux';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import i18n from 'i18n-js';

import { MenuData } from 'store/menusStore/reducer';
import { EditMenuForm } from '../MenusPage/components';
import { MenuType } from 'constants/enums';
import { images } from 'assets';
import { ApplicationState } from 'store';
import { useStyles } from './styles';

interface MenuEditModalProps {
    menu: MenuData;
    isOpen: boolean;
    isDetails: boolean;
    handleClose: () => void;
}

export const MenuEditModal = (props: MenuEditModalProps) => {
    const classes = useStyles();
    const menus = useSelector((state: ApplicationState) => state.menus.menus);

    return (
        <Dialog open={props.isOpen} aria-labelledby="form-dialog-title" className={classes.dialog}>
            {props.menu.type === MenuType.Standard && (
                <DialogTitle id="form-dialog-title">
                    {i18n.t('restaurant.editStandartMenu')}
                </DialogTitle>
            )}
            {props.menu.type === MenuType.Daily && (
                <DialogTitle id="form-dialog-title">
                    {i18n.t('restaurant.editDailyMenu')}
                    <img src={images.icons.breakfastLogo} alt="breakfast" />
                </DialogTitle>
            )}
            <DialogContent>
                <EditMenuForm
                    cancel={props.handleClose}
                    menu={props.menu}
                    isDetails={props.isDetails}
                    menus={menus.reduce((result: string[], current) => {
                        if (props.menu.name !== current.name) {
                            result.push(current.name.toUpperCase());
                        }
                        return result;
                    }, [])}
                />
            </DialogContent>
        </Dialog>
    );
};
