import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Form, Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import i18n from 'i18n-js';
import * as FormikFields from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';

import { AddIngredientsModal, Input } from 'components/shared';
import { default as restaurantStore } from 'store/restaurantDetailsStore';
import agent from 'api/agent';
import { DishCreateModel, IngredientModel, TaxResponseModel } from 'api/models';
import { images } from 'assets';
import categoryStore from 'store/categoryStore';
import { DishData, SectionData } from 'store/menuDetailsStore/reducer';
import { trimLeftAndRight } from 'helpers/helperFunctions';
import ImageContainer, {
    ImageContainerRefProps,
} from 'components/shared/components/ImageContainer';
import AllergensSelect from 'components/shared/components/AllergensSelect';
import ServiceTaxSelect from './ServiceTaxSelect';
import { OrderServices, TaxType, Positions } from 'constants/enums';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';
import { regexHelper } from 'helpers/regexHelper';

export interface DishManagementDialogProps {
    dish: DishData;
    disabled: boolean;
    sections: SectionData[];
    action: (model: DishCreateModel, id?: string, sectionId?: string) => void;
    isOpen: boolean;
    onClose: (event?: React.MouseEvent<HTMLElement>) => void;
    forCopy?: boolean;
}

export default function DishManagementDialog(props: DishManagementDialogProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const categoryDetails = useAppSelector((state) => state.category.categoryDetails);
    const restaurant = useAppSelector((state) => state.restaurantDetails.restaurantInformation);
    const user = useAppSelector((state) => state.user);
    const dishes = useRef<string[]>([]);
    const childRef = useRef<ImageContainerRefProps>(null);
    const [showAddIngredientsModal, setShowAddIngredientsModal] = useState(false);
    const [taxes, setTaxes] = useState<TaxResponseModel>({
        id: '',
        standardRate: 0,
        reducedRate: 0,
        specialRateForAccommodation: 0,
    });
    const [isDeniedTaxSetup, setIsDeniedTaxSetup] = useState(true);
    const [showAdditionalForm, setShowAdditionalForm] = useState(false);
    const [advancedSettingsHasError, setAdvancedSettingsHasError] = useState(false);

    function preventProp(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
    }

    function onCancel() {
        setShowAdditionalForm(false);
        props.onClose();
    }

    function handleShowAdvancedSettingsError(hasError: boolean) {
        setAdvancedSettingsHasError(hasError);
    }

    useEffect(() => {
        setIsDeniedTaxSetup(
            user.restaurants.find((x) => x.id === user.currentRestaurantId)?.currentPosition !==
                Positions.MANAGER
        );
        if (!restaurant.id) {
            dispatch(restaurantStore.actionCreators.getRestaurantInformation());
        }
        if (!categoryDetails) {
            dispatch(categoryStore.actionCreators.getCategoryDetails());
        } else {
            dishes.current = categoryDetails!.dishes.reduce((result: string[], current) => {
                if (current.name !== props.dish.name) {
                    result.push(current.name.toUpperCase());
                }
                return result;
            }, []);
        }
        agent.Restaurants.GetTaxes().then((result) => setTaxes(result));
    }, [categoryDetails]); // eslint-disable-line

    const initalFormValues = {
        name: props.forCopy ? 'copy of ' + props.dish.name : props.dish.name,
        description: props.dish.description,
        shortDescription: props.dish.shortDescription,
        price: props.dish.price!.toFixed(2),
        photo: props.dish.photo,
        isSpicy: props.dish.isSpicy,
        isVegetarian: props.dish.isVegetarian,
        isVegan: props.dish.isVegan,
        isHalal: props.dish.isHalal,
        section: props.dish.sectionId,
        addToGallery: props.dish.addToGallery,
        category: props.dish.categoryId,
        photoSelected: props.dish.photo.length > 0,
        allergens: props.dish.allergens,
        ingredients: props.dish.ingredients,
        primeCost: props.dish.primeCost?.toFixed(2) ?? 0,
        vorortTax: props.dish.vorortTax ?? TaxType.None,
        homeDeliveryTax: props.dish.homeDeliveryTax ?? TaxType.None,
        takeAwayTax: props.dish.takeAwayTax ?? TaxType.None,
        barcode: props.dish.barcode,
        stockQuantity: props.dish.stockQuantity,
        minimumStock: props.dish.minimumStock,
        notFood: props.dish.notFood,
        color: props.dish.color,
    };

    function validateTaxes(name: string, values: any) {
        switch (name) {
            case 'vorortTax':
                if (!restaurant.hasOnSite) {
                    return true;
                }
                break;
            case 'homeDeliveryTax':
                if (!restaurant.hasTakeOut) {
                    return true;
                }
                break;
            case 'takeAwayTax':
                if (!restaurant.hasTakeAway) {
                    return true;
                }
                break;
            default:
                return true;
        }
        if (restaurant.hasOnSite && values.vorortTax !== TaxType.None) {
            return true;
        }
        if (restaurant.hasTakeAway && values.takeAwayTax !== TaxType.None) {
            return true;
        }
        if (restaurant.hasTakeOut && values.homeDeliveryTax !== TaxType.None) {
            return true;
        }
        return false;
    }

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !dishes.current.includes(value?.toUpperCase())
            ),
        shortDescription: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Short description',
            })
        ),
        price: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .positive(i18n.t('form.errors.greaterZero'))
            .test('is-decimal', i18n.t('form.errors.twoDigitsAfterComma'), (val?: number) => {
                if (val) {
                    return regexHelper.twoDigisAfterComma.test(val.toString());
                }
                return true;
            })
            .required(
                i18n.t('form.errors.required', {
                    name: 'Price',
                })
            ),
        primeCost: yup
            .number()
            .test('is-decimal', i18n.t('form.errors.twoDigitsAfterComma'), (val?: number) => {
                if (val) {
                    return regexHelper.twoDigisAfterComma.test(val.toString());
                }
                return true;
            })
            .typeError(i18n.t('form.errors.onlyDigits'))
            .min(0, i18n.t('form.errors.greaterZero')),
        category: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Category',
            })
        ),
        section: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Section',
            })
        ),
        vorortTax: yup
            .string()
            .test(
                'selectsCheck',
                i18n.t('form.errors.atLeasOneTaxSelected'),
                (value: any, context: any) => validateTaxes('vorortTax', context.parent)
            ),
        homeDeliveryTax: yup
            .string()
            .test(
                'selectsCheck',
                i18n.t('form.errors.atLeasOneTaxSelected'),
                (value: any, context: any) => validateTaxes('homeDeliveryTax', context.parent)
            ),
        takeAwayTax: yup
            .string()
            .test(
                'selectsCheck',
                i18n.t('form.errors.atLeasOneTaxSelected'),
                (value: any, context: any) => validateTaxes('takeAwayTax', context.parent)
            ),
        barcode: yup
            .string()
            .matches(
                regexHelper.lettersAndNumbersOnly,
                i18n.t('form.errors.lettersAndNumbersOnly')
            ),
        stockQuantity: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .min(0, i18n.t('form.errors.greaterZero')),
        minimumStock: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .min(0, i18n.t('form.errors.greaterZero')),
    });

    function formDisabled(values: any) {
        let result = !props.forCopy;
        if (!props.forCopy && values) {
            result = JSON.stringify(initalFormValues) === JSON.stringify(values);
        }
        return result;
    }

    function addToAllergens(
        ingredients: IngredientModel[],
        values: any,
        setFieldValue: (key: string, value: any) => void
    ) {
        const newAllergens: string[] = [...values.allergens];
        ingredients.forEach((eachIngredient) => {
            eachIngredient.allergens.forEach((allergen) => {
                if (!newAllergens.includes(allergen)) {
                    newAllergens.push(allergen);
                }
            });
        });
        setFieldValue('allergens', newAllergens);
    }

    return (
        <>
            <Dialog
                maxWidth={'md'}
                open={props.isOpen}
                onClick={preventProp}
                aria-labelledby="form-dialog-title"
                className={clsx(classes.dialog, {
                    [classes.dialogExtended]: showAdditionalForm,
                })}
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={clsx(classes.dialogTitle, {
                        [classes.dialogTitleExtended]: showAdditionalForm,
                    })}
                >
                    {props.disabled ? props.dish.name : i18n.t('common.editItem')}{' '}
                    <div onClick={() => setShowAdditionalForm(true)}>
                        {advancedSettingsHasError && !showAdditionalForm ? (
                            <div className={classes.hasError}>
                                <h5>
                                    <img src={images.icons.settingsError} alt="settings" />
                                    {i18n.t('common.advancedSettings')}
                                </h5>
                                <span>{i18n.t('form.errors.hasIncorrectData')}</span>
                            </div>
                        ) : (
                            <h5>
                                <img src={images.icons.settings} alt="settings" />
                                {i18n.t('common.advancedSettings')}
                            </h5>
                        )}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initalFormValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            childRef?.current
                                ?.uploadImage()
                                .then((photo) => {
                                    values.photo = photo;
                                    let price = values.price.replaceAll(' ', '');
                                    const model: DishCreateModel = {
                                        name: trimLeftAndRight(values.name),
                                        description: trimLeftAndRight(values.description),
                                        shortDescription: trimLeftAndRight(values.shortDescription),
                                        price: +price,
                                        photo: values.photo,
                                        isSpicy: values.isSpicy,
                                        isVegetarian: values.isVegetarian,
                                        isVegan: values.isVegan,
                                        isHalal: values.isHalal,
                                        sectionId: values.section,
                                        kitchenId: '',
                                        addToGallery: values.addToGallery,
                                        categoryId: values.category,
                                        allergens: values.allergens,
                                        ingredients: values.ingredients,
                                        primeCost: +values.primeCost,
                                        vorortTax: values.vorortTax,
                                        homeDeliveryTax: values.homeDeliveryTax,
                                        takeAwayTax: values.takeAwayTax,
                                        barcode: values.barcode,
                                        stockQuantity: values.stockQuantity,
                                        minimumStock: values.minimumStock,
                                        notFood: values.notFood,
                                        color: props.dish.color,
                                    };
                                    props.action(model, props.dish.id);
                                })
                                .then(() => {
                                    resetForm();
                                    props.onClose();
                                })
                                .catch((error) => alert(error));
                        }}
                    >
                        {({ values, touched, setFieldValue, errors, submitForm }) => (
                            <Form className={classes.root}>
                                <>
                                    <div className={classes.formWrapper}>
                                        <div
                                            className={clsx(classes.formEdit, {
                                                [classes.formEditExtended]: showAdditionalForm,
                                            })}
                                        >
                                            <div className={classes.photoContainer}>
                                                <ImageContainer
                                                    disabled={props.disabled}
                                                    ref={childRef}
                                                    photo={values.photo}
                                                    onChange={(selected, fileUrl) => {
                                                        setFieldValue('photoSelected', selected);
                                                        setFieldValue('photo', fileUrl);
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    visibility: props.disabled
                                                        ? 'hidden'
                                                        : 'visible',
                                                    textAlign: 'start',
                                                    padding: '9px 0 8px 0',
                                                }}
                                            >
                                                <Input
                                                    disabled={!values.photoSelected}
                                                    component={FormikFields.CheckboxWithLabel}
                                                    variant="filled"
                                                    checked={values.addToGallery}
                                                    name="addToGallery"
                                                    type="checkbox"
                                                    Label={{
                                                        label: i18n.t('form.addPhotoToGallery'),
                                                    }}
                                                    placeholder={i18n.t('form.addPhotoToGallery')}
                                                    color="primary"
                                                />
                                            </div>
                                            <h6 className={classes.sectionTitle}>
                                                {i18n.t('common.mainInformation')}
                                            </h6>
                                            <Input
                                                disabled={props.disabled}
                                                variant="filled"
                                                fullWidth={true}
                                                name="name"
                                                type="text"
                                                error={errors.name}
                                                label={i18n.t('form.itemName')}
                                                placeholder={i18n.t('form.itemName')}
                                            />
                                            <Input
                                                disabled={props.disabled}
                                                variant="filled"
                                                fullWidth={true}
                                                name="shortDescription"
                                                error={errors.shortDescription}
                                                type="text"
                                                label={i18n.t('form.shortDescription')}
                                                placeholder={i18n.t('form.shortDescription')}
                                            />
                                            <Input
                                                disabled={props.disabled}
                                                variant="filled"
                                                fullWidth={true}
                                                name="description"
                                                type="textarea"
                                                minRows={4}
                                                label={i18n.t('form.description')}
                                                placeholder={i18n.t('form.description')}
                                            />
                                            <Input
                                                disabled={props.disabled}
                                                variant="filled"
                                                fullWidth={false}
                                                name="price"
                                                type="text"
                                                error={errors.price}
                                                label={i18n.t('form.price')}
                                                placeholder={i18n.t('form.price')}
                                            />
                                        </div>
                                        <div
                                            className={clsx(classes.formEdit, {
                                                [classes.formEditExtended]: showAdditionalForm,
                                            })}
                                        >
                                            <div>
                                                <h6
                                                    className={classes.sectionTitle}
                                                    style={{ marginTop: 0 }}
                                                >
                                                    {i18n.t('navigationTabs.tax')}
                                                </h6>
                                                <ServiceTaxSelect
                                                    service={OrderServices.OrderInRestaurant}
                                                    selectedTax={values.vorortTax}
                                                    taxes={taxes}
                                                    onSelect={(tax: TaxType) =>
                                                        setFieldValue('vorortTax', tax)
                                                    }
                                                    disabled={
                                                        isDeniedTaxSetup || !restaurant.hasOnSite
                                                    }
                                                    error={errors.vorortTax}
                                                />
                                                <ServiceTaxSelect
                                                    service={OrderServices.TakeAway}
                                                    selectedTax={values.takeAwayTax}
                                                    taxes={taxes}
                                                    onSelect={(tax: TaxType) =>
                                                        setFieldValue('takeAwayTax', tax)
                                                    }
                                                    disabled={
                                                        isDeniedTaxSetup || !restaurant.hasTakeAway
                                                    }
                                                    error={errors.takeAwayTax}
                                                />
                                                <ServiceTaxSelect
                                                    service={OrderServices.HomeDelivery}
                                                    selectedTax={values.homeDeliveryTax}
                                                    taxes={taxes}
                                                    onSelect={(tax: TaxType) =>
                                                        setFieldValue('homeDeliveryTax', tax)
                                                    }
                                                    styles={{ marginBottom: 0 }}
                                                    disabled={
                                                        isDeniedTaxSetup ||
                                                        !restaurant.hasHomeDelivery
                                                    }
                                                    error={errors.homeDeliveryTax}
                                                />
                                            </div>
                                            <div>
                                                <h6 className={classes.sectionTitle}>
                                                    {i18n.t('common.other')}
                                                </h6>
                                                <Autocomplete
                                                    id="section"
                                                    disabled={true}
                                                    options={props.sections.map((x) => x.id)}
                                                    getOptionLabel={(option) =>
                                                        props.sections.find((x) => x.id === option)
                                                            ?.name ?? ''
                                                    }
                                                    value={values.section}
                                                    getOptionSelected={(option, value) =>
                                                        option === value
                                                    }
                                                    onChange={(e, value) => {
                                                        setFieldValue('section', value || '');
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            name="section"
                                                            variant="filled"
                                                            fullWidth
                                                            label={
                                                                Boolean(
                                                                    touched.section &&
                                                                        errors.section
                                                                )
                                                                    ? i18n.t(
                                                                          'form.errors.required',
                                                                          {
                                                                              name: 'Section',
                                                                          }
                                                                      )
                                                                    : i18n.t('common.section')
                                                            }
                                                            error={Boolean(
                                                                touched.section && errors.section
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <Autocomplete
                                                    id="category"
                                                    disabled={props.disabled}
                                                    options={
                                                        categoryDetails?.categories.map(
                                                            (x) => x.id
                                                        ) || []
                                                    }
                                                    getOptionLabel={(option) =>
                                                        categoryDetails?.categories.find(
                                                            (x) => x.id === option
                                                        )?.name ?? ''
                                                    }
                                                    value={values.category}
                                                    getOptionSelected={(option, value) =>
                                                        option === value
                                                    }
                                                    onChange={(e, value) =>
                                                        setFieldValue('category', value || '')
                                                    }
                                                    renderInput={(params) => (
                                                        <>
                                                            <TextField
                                                                {...params}
                                                                name="categories"
                                                                label={
                                                                    Boolean(
                                                                        touched.category &&
                                                                            errors.category
                                                                    )
                                                                        ? i18n.t(
                                                                              'form.errors.required',
                                                                              {
                                                                                  name: 'Category',
                                                                              }
                                                                          )
                                                                        : i18n.t(
                                                                              'common.categories'
                                                                          )
                                                                }
                                                                variant="filled"
                                                                fullWidth
                                                                error={Boolean(
                                                                    touched.category &&
                                                                        errors.category
                                                                )}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <AllergensSelect
                                                    values={values.allergens}
                                                    onChange={(vals: string[]) =>
                                                        setFieldValue('allergens', vals)
                                                    }
                                                    styles={{ marginBottom: 28 }}
                                                />
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.manageIngredientButton}
                                                    onClick={() => setShowAddIngredientsModal(true)}
                                                >
                                                    {`+ ${i18n
                                                        .t('restaurant.manageIngredients')
                                                        .toUpperCase()}`}
                                                </Button>
                                                <AddIngredientsModal
                                                    defaultIngredients={props.dish.ingredients}
                                                    isVisible={showAddIngredientsModal}
                                                    onClose={() => {
                                                        setShowAddIngredientsModal(false);
                                                    }}
                                                    onSave={(ingredients) => {
                                                        addToAllergens(
                                                            ingredients,
                                                            values,
                                                            setFieldValue
                                                        );
                                                        setFieldValue('ingredients', ingredients);
                                                        setShowAddIngredientsModal(false);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {showAdditionalForm && (
                                            <div
                                                className={clsx(classes.formEdit, {
                                                    [classes.formEditExtended]: showAdditionalForm,
                                                })}
                                            >
                                                <h4
                                                    className={classes.additionalHeader}
                                                    onClick={() => setShowAdditionalForm(false)}
                                                >
                                                    <img src={images.icons.cross} alt="cross" />{' '}
                                                    {i18n.t('common.advancedSettings')}
                                                </h4>
                                                <Input
                                                    disabled={props.disabled}
                                                    variant="filled"
                                                    fullWidth={true}
                                                    name="barcode"
                                                    type="text"
                                                    label={i18n.t('form.barcode')}
                                                    error={errors.barcode}
                                                    placeholder={i18n.t('form.barcode')}
                                                    style={{ marginTop: 18 }}
                                                />
                                                <Input
                                                    disabled={props.disabled}
                                                    variant="filled"
                                                    fullWidth={false}
                                                    name="primeCost"
                                                    type="text"
                                                    label={i18n.t('form.primeCost')}
                                                    error={errors.primeCost}
                                                    placeholder={i18n.t('form.primeCost')}
                                                />
                                                <Input
                                                    disabled={props.disabled}
                                                    variant="filled"
                                                    fullWidth={true}
                                                    name="stockQuantity"
                                                    type="text"
                                                    label={i18n.t('form.stockQuantity')}
                                                    error={errors.stockQuantity}
                                                    placeholder={i18n.t('form.stockQuantity')}
                                                />
                                                <Input
                                                    disabled={props.disabled}
                                                    variant="filled"
                                                    fullWidth={true}
                                                    name="minimumStock"
                                                    type="text"
                                                    label={i18n.t('form.minimumStock')}
                                                    error={errors.minimumStock}
                                                    placeholder={i18n.t('form.minimumStock')}
                                                />
                                                <Input
                                                    component={FormikFields.CheckboxWithLabel}
                                                    disabled={props.disabled}
                                                    variant="filled"
                                                    name="notFood"
                                                    type="checkbox"
                                                    Label={{ label: i18n.t('form.notFood') }}
                                                    placeholder={i18n.t('form.notFood')}
                                                    inputProps={{
                                                        'aria-label': 'secondary checkbox',
                                                    }}
                                                    color="primary"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={clsx(classes.bottomPanel, {
                                            [classes.bottomPanelExtended]: showAdditionalForm,
                                        })}
                                    >
                                        <div className={classes.bottomContainer}>
                                            <Input
                                                component={FormikFields.CheckboxWithLabel}
                                                disabled={props.disabled}
                                                variant="filled"
                                                name={'isSpicy'}
                                                type="checkbox"
                                                Label={{ label: i18n.t('form.spicy') }}
                                                placeholder={i18n.t('form.spicy')}
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                color="primary"
                                            />
                                            <img
                                                src={images.icons.chili}
                                                alt="chili-pepper"
                                                className={classes.bottomIcon}
                                            />
                                            <Input
                                                component={FormikFields.CheckboxWithLabel}
                                                disabled={props.disabled}
                                                variant="filled"
                                                name={'isVegetarian'}
                                                type="checkbox"
                                                Label={{ label: i18n.t('form.vegetarian') }}
                                                placeholder={i18n.t('form.vegetarian')}
                                                color="primary"
                                            />
                                            <img
                                                src={images.icons.leaf}
                                                alt="green-leaf"
                                                className={classes.bottomIcon}
                                            />
                                            <Input
                                                component={FormikFields.CheckboxWithLabel}
                                                disabled={props.disabled}
                                                variant="filled"
                                                name={'isVegan'}
                                                type="checkbox"
                                                Label={{ label: i18n.t('form.vegan') }}
                                                placeholder={i18n.t('form.vegan')}
                                                color="primary"
                                            />
                                            <img
                                                src={images.icons.doubleLeaf}
                                                alt="double-leaf"
                                                className={classes.bottomIcon}
                                            />
                                            <Input
                                                component={FormikFields.CheckboxWithLabel}
                                                disabled={props.disabled}
                                                variant="filled"
                                                name={'isHalal'}
                                                type="checkbox"
                                                Label={{ label: i18n.t('form.halal') }}
                                                placeholder={i18n.t('form.halal')}
                                                color="primary"
                                            />
                                            <img
                                                src={images.icons.halal}
                                                alt="halal"
                                                className={classes.bottomIcon}
                                            />
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            {props.disabled ? (
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    onClick={onCancel}
                                                >
                                                    {i18n.t('button.close')}
                                                </Button>
                                            ) : (
                                                <>
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        onClick={onCancel}
                                                        className={classes.mRight10}
                                                    >
                                                        {i18n.t('button.cancel')}
                                                    </Button>
                                                    <Button
                                                        disabled={formDisabled(values)}
                                                        onClick={submitForm}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {i18n.t('button.save')}
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                                <HandleFormikHelper action={handleShowAdvancedSettingsError} />
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
}

function HandleFormikHelper({ action }: { action: (hasError: boolean) => void }) {
    const { errors } = useFormikContext();
    useEffect(() => {
        const hasError =
            'barcode' in errors ||
            'primeCost' in errors ||
            'stockQuantity' in errors ||
            'minimumStock' in errors;
        action(hasError);
    }, [errors]);
    return null;
}
