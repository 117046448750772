import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import * as yup from 'yup';
import { Button, Tab, Tabs, InputAdornment } from '@material-ui/core';
import { Formik, Form } from 'formik';

import { useStyles } from './styles';
import globalStore from 'store/globalStore';
import { Input } from 'components/shared';
import agent from 'api/agent';
import { regexHelper } from 'helpers/regexHelper';
import { TaxResponseModel, TaxRequestModel } from 'api/models';

export default function AdminFinance() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState<TaxResponseModel>({
        id: '',
        standardRate: 0,
        reducedRate: 0,
        specialRateForAccommodation: 0,
    });
    const [formChanged, setFormChanged] = useState(false);
    const formRef = useRef<any>();

    function getTaxes() {
        dispatch(globalStore.actionCreators.showSpiner());
        agent.Restaurants.GetTaxes()
            .then((result) => setInitialValues(result))
            .catch((e) => dispatch(globalStore.actionCreators.showToaster('error', e)))
            .finally(() => dispatch(globalStore.actionCreators.hideSpiner()));
    }

    function updateTaxes(model: TaxResponseModel) {
        dispatch(globalStore.actionCreators.showSpiner());
        agent.Restaurants.UpdateTaxes(model.id, model as TaxRequestModel)
            .then((result) => setInitialValues(result))
            .then(() =>
                dispatch(
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )
                )
            )
            .catch((e) => dispatch(globalStore.actionCreators.showToaster('error', e)))
            .finally(() => dispatch(globalStore.actionCreators.hideSpiner()));
    }

    const validationSchema = yup.object().shape({
        standardRate: yup
            .number()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Standart rate',
                })
            )
            .moreThan(0, 'Min 0.1')
            .lessThan(99.01, 'Max 99')
            .test('is-decimal', i18n.t('form.errors.twoDigitsAfterComma'), (val?: number) => {
                if (val) {
                    return regexHelper.twoDigisAfterComma.test(val.toString());
                }
                return true;
            }),
        reducedRate: yup
            .number()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Reduced Rate',
                })
            )
            .moreThan(0, 'Min 0.1')
            .lessThan(99.01, 'Max 99')
            .test('is-decimal', i18n.t('form.errors.twoDigitsAfterComma'), (val?: number) => {
                if (val) {
                    return regexHelper.twoDigisAfterComma.test(val.toString());
                }
                return true;
            }),
        specialRateForAccommodation: yup
            .number()
            .moreThan(0, 'Min 0.1')
            .lessThan(99.01, 'Max 99')
            .test('is-decimal', i18n.t('form.errors.twoDigitsAfterComma'), (val?: number) => {
                if (val) {
                    return regexHelper.twoDigisAfterComma.test(val.toString());
                }
                return true;
            })
            .required(
                i18n.t('form.errors.required', {
                    name: 'Special Rate',
                })
            ),
    });

    function handleFormChanging(values: TaxResponseModel) {
        setFormChanged(JSON.stringify(values) !== JSON.stringify(initialValues));
    }

    useEffect(() => {
        getTaxes();
    }, []);

    return (
        <>
            <div className={classes.headerContainer}>
                <div className={classes.flexRowAlignCenter}>
                    <Tabs
                        value={1}
                        aria-label="wrapped label tabs"
                        indicatorColor="primary"
                        textColor="primary"
                        className={classes.tab}
                    >
                        <Tab
                            key={'tax'}
                            style={{ textTransform: 'uppercase' }}
                            value={1}
                            label={i18n.t('navigationTabs.tax')}
                        />
                    </Tabs>
                </div>
                <div className={classes.headerButtonContainer}>
                    <Button
                        onClick={() => {
                            if (formRef.current) {
                                formRef.current.handleSubmit();
                            }
                        }}
                        variant="contained"
                        color="primary"
                        disabled={!formChanged}
                        style={{ width: 163 }}
                    >
                        {i18n.t('common.save')}
                    </Button>
                </div>
            </div>

            <Formik
                enableReinitialize={true}
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => updateTaxes(values)}
            >
                {({ setFieldValue, values, errors }) => (
                    <>
                        <Form className={classes.formInnerContainer}>
                            <div className={classes.leftContainer}>
                                <h6 className={classes.sectionTitle}>
                                    {i18n.t('common.taxForRestaurants').toUpperCase()}
                                </h6>
                                <Input
                                    placeholder={i18n.t('form.Vorort')}
                                    name="standardRate"
                                    error={errors.standardRate}
                                    type="number"
                                    label={i18n.t('form.standardRate')}
                                    variant="filled"
                                    className={classes.percentageInput}
                                    maxLength={2}
                                    inputProps={{
                                        min: 0,
                                        max: 99,
                                    }}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">%</InputAdornment>
                                        ),
                                    }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleFormChanging({
                                            ...values,
                                            standardRate: +event.currentTarget.value,
                                        });
                                        setFieldValue('standardRate', event.currentTarget.value);
                                    }}
                                />
                                <Input
                                    placeholder={i18n.t('form.takeAway')}
                                    name="reducedRate"
                                    error={errors.reducedRate}
                                    type="number"
                                    label={i18n.t('form.reducedRate')}
                                    variant="filled"
                                    className={classes.percentageInput}
                                    maxLength={2}
                                    inputProps={{
                                        min: 0,
                                        max: 99,
                                    }}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">%</InputAdornment>
                                        ),
                                    }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleFormChanging({
                                            ...values,
                                            reducedRate: +event.currentTarget.value,
                                        });
                                        setFieldValue('reducedRate', event.currentTarget.value);
                                    }}
                                />
                                <Input
                                    placeholder={i18n.t('form.homeDelivery')}
                                    name="specialRateForAccommodation"
                                    error={errors.specialRateForAccommodation}
                                    type="number"
                                    label={i18n.t('form.specialRateForAccommodation')}
                                    variant="filled"
                                    className={classes.percentageInput}
                                    maxLength={2}
                                    inputProps={{
                                        min: 0,
                                        max: 99,
                                    }}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">%</InputAdornment>
                                        ),
                                    }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleFormChanging({
                                            ...values,
                                            specialRateForAccommodation: +event.currentTarget.value,
                                        });
                                        setFieldValue(
                                            'specialRateForAccommodation',
                                            event.currentTarget.value
                                        );
                                    }}
                                />
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}
