import React, { useState } from 'react';

import i18n from 'i18n-js';
import { InputAdornment, TextField as MuiTextField } from '@material-ui/core';
import { Typography, Dialog, DialogTitle, DialogContent, Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { images } from 'assets';
import { TimePickerModal } from 'components/shared';
import { getDayList, getInitialDates } from '../functions';
import { renderMultipleClassNames } from 'helpers/helperFunctions';
import styles from '../styles';

export const defaultHours = { from: '00.00', to: '00.00' };

export interface HourData {
    from: string;
    to: string;
}

export interface AddWorkingHoursProps {
    isVisible: boolean;
    onClose: () => void;
    onSave: () => void;
    title: string;
    openHours: any;
    onHourAdd: (index: number, hours: HourData) => void;
    onHourRemove: (index: number) => void;
    onCloseChecked: (isChecked: boolean) => void;
    onSelectedDaysChange: (days: string[], checked: boolean, hours: HourData[]) => void;
}

export default function AddWorkingHoursModal(props: AddWorkingHoursProps) {
    const [render, reRender] = useState(false);
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [timePickerModalType, setTimePickerModalType] = useState(0);
    const [clickedElementRect, setClickedElementRect] = useState<any>(null);
    const [hours, setHours] = useState<{ from: string; to: string }[]>([defaultHours]);
    const [checked, setChecked] = useState(false);
    const classes = styles();

    function addMoreHours() {
        const nextIndex = hours.length;
        addWorkingHour(nextIndex, defaultHours);
    }

    function getClickedAndSet(i: number) {
        const id = `Add-Mui${i}`;
        const clickedElement = document.getElementById(id);
        const clickedElementRect = clickedElement?.getBoundingClientRect();

        setClickedElementRect(clickedElementRect);
    }

    function selectDay(dayName: string) {
        let newSelectedDays;

        if (selectedDays.includes(dayName)) {
            newSelectedDays = selectedDays.filter((day) => day !== dayName);
        } else {
            newSelectedDays = [...selectedDays, dayName];
        }
        props.onSelectedDaysChange(newSelectedDays, checked, hours);
        setSelectedDays(newSelectedDays);
    }

    function addWorkingHour(i: number, incomingHours: HourData) {
        const newHours = [...hours];
        newHours[i] = incomingHours;
        setHours(newHours);
        props.onHourAdd(i, incomingHours);
    }

    function removeWorkingHour(i: number) {
        const newHours = hours.filter((item, index) => index !== i);
        setHours(newHours);
    }

    return (
        <Dialog open={props.isVisible} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Typography variant="h6" align="left" className={classes.fontWeight500}>
                    {props.title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className={classes.smallModalContainer}>
                    <div
                        className={renderMultipleClassNames([
                            classes.flexRowSpaceBetween,
                            classes.mBot5P,
                        ])}
                    >
                        {getDayList(props.openHours).map((day) => {
                            return (
                                <div
                                    key={day.key}
                                    onClick={() => selectDay(day.key)}
                                    className={
                                        selectedDays.includes(day.key)
                                            ? classes.selectedDayContainer
                                            : classes.dayContainer
                                    }
                                >
                                    {day.name.toUpperCase()}
                                </div>
                            );
                        })}
                    </div>

                    {hours.map((workingHour: HourData, i: number) => (
                        <div key={i}>
                            {i !== 0 ? (
                                <MuiTextField
                                    className={classes.width100}
                                    id={`Add-Mui${i}`}
                                    value={
                                        workingHour ? workingHour.from + ' - ' + workingHour.to : ''
                                    }
                                    onClick={() => {
                                        setTimePickerModalType(i + 1);
                                        getClickedAndSet(i);
                                    }}
                                    variant="filled"
                                    name={`extraHours[${i}]`}
                                    type="text"
                                    label={i18n.t('common.workingHours') + ` ${i > 0 ? i + 1 : ''}`}
                                    placeholder={i18n.t('common.workingHours')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    props.onHourRemove(i);
                                                    removeWorkingHour(i);
                                                    reRender(!render);
                                                }}
                                            >
                                                <img
                                                    alt="remove-icon"
                                                    src={images.icons.removeCircle}
                                                    className={classes.basicHover}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            ) : (
                                <MuiTextField
                                    className={classes.width100}
                                    id={`Add-Mui${i}`}
                                    value={
                                        workingHour ? workingHour.from + ' - ' + workingHour.to : ''
                                    }
                                    onClick={() => {
                                        setTimePickerModalType(i + 1);
                                        getClickedAndSet(i);
                                    }}
                                    variant="filled"
                                    name={`extraHours[${i}]`}
                                    type="text"
                                    label={i18n.t('common.workingHours') + ` ${i > 0 ? i : ''}`}
                                    placeholder={i18n.t('common.workingHours')}
                                />
                            )}
                        </div>
                    ))}
                    <div className={classes.checkboxContainer}>
                        <Checkbox
                            color="primary"
                            onChange={() => {
                                const newValue = !checked;
                                setChecked(newValue);
                                props.onCloseChecked(newValue);
                            }}
                        />
                        <span className={classes.closedText}>{i18n.t('common.closed')}</span>
                    </div>
                    <div className={classes.flexRowSpaceBetween}>
                        <Button
                            className={classes.mTop10}
                            type="button"
                            color="primary"
                            onClick={addMoreHours}
                        >
                            {i18n.t('common.addMoreHours')}
                        </Button>
                        <div className={classes.smallModalButtonContainer}>
                            <Button
                                type="button"
                                color="primary"
                                className={classes.cancelButton}
                                onClick={() => {
                                    setSelectedDays([]);
                                    props.onClose();
                                }}
                            >
                                {i18n.t('button.cancel')}
                            </Button>
                            <Button
                                disabled={selectedDays.length === 0}
                                onClick={props.onSave}
                                className={classes.smallButton}
                                variant="contained"
                                color="primary"
                            >
                                {i18n.t(`common.save`)}
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <TimePickerModal
                initialFromHour={getInitialDates(hours[timePickerModalType - 1], 'from', 'hour')}
                initialFromMinute={getInitialDates(
                    hours[timePickerModalType - 1],
                    'from',
                    'minute'
                )}
                initialToHour={getInitialDates(hours[timePickerModalType - 1], 'to', 'hour')}
                initialToMinute={getInitialDates(hours[timePickerModalType - 1], 'to', 'minute')}
                clickedElementRect={clickedElementRect}
                isVisible={timePickerModalType > 0}
                onClose={(selectedHours) => {
                    addWorkingHour(timePickerModalType - 1, selectedHours);
                    setTimePickerModalType(0);
                }}
                onDateChange={(selectedHours) => {
                    addWorkingHour(timePickerModalType - 1, selectedHours);
                }}
            />
        </Dialog>
    );
}
