import React from 'react';
import { useDispatch } from 'react-redux';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Formik } from 'formik';
import i18n from 'i18n-js';
import Button from '@material-ui/core/Button';
import * as yup from 'yup';

import { Input } from 'components/shared';
import { default as ingredientsStore } from 'store/ingredientsStore';
import { useStyles } from '../styles';

interface CategoryModalProps {
    isVisible: boolean;
    onClose: () => void;
    defaultValues?: any;
}

export default function CategoryModal(props: CategoryModalProps) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const validationSchema = yup.object().shape({
        name: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Category Name',
            })
        ),
    });

    return (
        <Dialog open={props.isVisible}>
            <DialogTitle>
                {i18n.t(`${props.defaultValues ? 'common.editCategory' : 'common.newCategory'}`)}
            </DialogTitle>
            <DialogContent>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{ name: props.defaultValues ? props.defaultValues.name : '' }}
                    onSubmit={(values) => {
                        props.defaultValues
                            ? dispatch(
                                  ingredientsStore.actionCreators.updateCategory({
                                      name: values.name,
                                      id: props.defaultValues.id,
                                      lang: 'en',
                                  })
                              )
                            : dispatch(ingredientsStore.actionCreators.createCategory(values.name));
                        props.onClose();
                    }}
                >
                    {({ submitForm, values, initialValues, errors, dirty }) => {
                        return (
                            <form>
                                <Input
                                    error={errors.name}
                                    variant="filled"
                                    fullWidth={true}
                                    name={'name'}
                                    type="text"
                                    label={i18n.t('common.categoryName')}
                                    placeholder={i18n.t('common.categoryName')}
                                />
                                <div className={classes.buttonContainer}>
                                    <Button
                                        style={{ marginRight: 15 }}
                                        variant="text"
                                        color="primary"
                                        onClick={props.onClose}
                                    >
                                        {i18n.t('button.close')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={!dirty}
                                    >
                                        {props.defaultValues
                                            ? i18n.t('common.update')
                                            : i18n.t('common.create')}
                                    </Button>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}
