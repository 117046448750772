import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import { Dialog, DialogContent, DialogTitle, Menu, MenuItem, Button } from '@material-ui/core';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

import { default as menusStore } from 'store/menusStore';
import AddMenuForm from './AddMenuForm';
import { MenuType } from 'constants/enums';
import images from 'assets/images';
import { useStyles } from '../styles';
import { MenuCreateModel } from 'api/models';

export default function AddNewMenu() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [menuType, setMenuType] = useState(MenuType.Daily);

    const handleClickOpen = (menuType: MenuType) => {
        setMenuType(menuType);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                            {`+ ${i18n.t('restaurant.newMenu').toUpperCase()}`}
                        </Button>
                        <Menu
                            {...bindMenu(popupState)}
                            className={classes.dropdown}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClickOpen(MenuType.Standard);
                                    popupState.close();
                                }}
                            >
                                {i18n.t('restaurant.standartMenu')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    handleClickOpen(MenuType.Special);
                                    popupState.close();
                                }}
                            >
                                {i18n.t('restaurant.specialMenu')}
                            </MenuItem>
                            {/* <MenuItem
                                onClick={() => {
                                    handleClickOpen(MenuType.Daily);
                                    popupState.close();
                                }}
                            >
                                {i18n.t('restaurant.dailyMenu')}
                            </MenuItem> */}
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>
            <Dialog open={open} aria-labelledby="form-dialog-title" className={classes.dialog}>
                {menuType === MenuType.Standard && (
                    <DialogTitle id="form-dialog-title">
                        {i18n.t('restaurant.createNewStandartMenu')}
                    </DialogTitle>
                )}
                {menuType === MenuType.Daily && (
                    <DialogTitle id="form-dialog-title">
                        <span className={classes.createNewDailyMenu}>
                            {i18n.t('restaurant.createNewDailyMenu')}
                        </span>
                        <img src={images.icons.breakfastLogo} alt="breakfast" />
                    </DialogTitle>
                )}
                {menuType === MenuType.Special && (
                    <DialogTitle id="form-dialog-title">
                        {i18n.t('restaurant.createNewSpecialMenu')}
                    </DialogTitle>
                )}
                <DialogContent>
                    <AddMenuForm
                        menuType={menuType}
                        cancel={handleClose}
                        create={(model: MenuCreateModel) =>
                            dispatch(menusStore.actionCreators.createMenu(model))
                        }
                    />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
