import { AppThunkAction } from 'store';

import i18n from 'i18n-js';

import agent from 'api/agent';
import { UserFilterModel, UsersToRestaurantUserModel } from 'api/models';
import { Positions } from 'constants/enums';
import globalStore from '../globalStore';
import { UsersAction } from './actions';
import {
    AdminCreateModel,
    RestaurantUserCreateModel,
    AdminEditModel,
    RestaurantUserEditModel,
} from './reducer';

export const actionCreators = {
    getUsers:
        (
            restaurantFilterModel: UserFilterModel | null = null,
            page?: number,
            limit?: number,
            asc?: boolean,
            orderBy?: string
        ): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USERS_GET_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Users.GetList(restaurantFilterModel, page, limit, asc, orderBy)
                .then((response) => dispatch({ type: 'USERS_GET_SUCCESS', users: response }))
                .catch((e) => {
                    dispatch({ type: 'USERS_GET_ERROR' });
                    globalStore.actionCreators.showToaster('error', e);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    getRestaurantUsers:
        (
            restaurantFilterModel: UserFilterModel | null = null,
            page?: number,
            limit?: number,
            asc?: boolean,
            orderBy?: string
        ): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USERS_GET_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Users.GetRestaurantUsersList(restaurantFilterModel, page, limit, asc, orderBy)
                .then((response) => dispatch({ type: 'USERS_GET_SUCCESS', users: response }))
                .catch((e) => {
                    dispatch({ type: 'USERS_GET_ERROR' });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    createAdmin:
        (model: AdminCreateModel): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USERS_CREATE_ADMIN_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Users.CreateAdmin(model)
                .then((response) => {
                    dispatch({ type: 'USERS_CREATE_ADMIN_SUCCESS', user: response });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.createdSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'USERS_CREATE_ADMIN_ERROR' });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    createRestaurantUser:
        (model: RestaurantUserCreateModel): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USERS_CREATE_RESTAURANT_USER_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            (model.isOwner
                ? agent.Users.CreateRestaurantOwner(model)
                : agent.Users.CreateRestaurantEmployee(model)
            )
                .then((response) => {
                    dispatch({ type: 'USERS_CREATE_RESTAURANT_USER_SUCCESS', user: response });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.createdSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'USERS_CREATE_RESTAURANT_USER_ERROR' });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    disableUser:
        (ids: string[]): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_DISABLE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Users.DisableUsers(ids)
                .then((response) => {
                    dispatch({ type: 'USER_DISABLE_SUCCESS', users: response });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'USER_DISABLE_ERROR' });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    enableUser:
        (ids: string[]): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_ENABLE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Users.EnableUsers(ids)
                .then((response) => {
                    dispatch({ type: 'USER_ENABLE_SUCCESS', users: response });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'USER_ENABLE_ERROR' });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    editAdmin:
        (model: AdminEditModel): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USERS_EDIT_ADMIN_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Users.EditAdmin(model)
                .then((response) => {
                    dispatch({ type: 'USERS_EDIT_ADMIN_SUCCESS', user: response });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'USERS_EDIT_ADMIN_ERROR' });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    editRestaurantUser:
        (model: RestaurantUserEditModel): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'USERS_EDIT_RESTAURANT_USER_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            model.isOwner = model.positions.includes(Positions.MANAGER);
            agent.Users.EditRestaurantUser(model)
                .then((response) => {
                    dispatch({ type: 'USERS_EDIT_RESTAURANT_USER_SUCCESS', user: response });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({ type: 'USERS_EDIT_RESTAURANT_USER_ERROR' });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    fromUsersToRestaurantOwners:
        (model: UsersToRestaurantUserModel): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'FROM_USERS_TO_RESTAURANT_USERS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Users.FromUsersToRestaurantOwners(model)
                .then((response) => {
                    dispatch({
                        type: 'FROM_USERS_TO_RESTAURANT_USERS_SUCCESS',
                        data: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'FROM_USERS_TO_RESTAURANT_USERS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    fromUsersToRestaurantEmployees:
        (model: UsersToRestaurantUserModel): AppThunkAction<UsersAction> =>
        (dispatch) => {
            dispatch({
                type: 'FROM_USERS_TO_RESTAURANT_USERS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Users.FromUsersToRestaurantEmployees(model)
                .then((response) => {
                    dispatch({
                        type: 'FROM_USERS_TO_RESTAURANT_USERS_SUCCESS',
                        data: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'FROM_USERS_TO_RESTAURANT_USERS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
};
