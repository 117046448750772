import { theme } from './theme';
import { alpha } from '@material-ui/core';

const commonStyles = {
    smallModalContainer: {
        minHeight: '220px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '10px',
    },
    smallModalButtonContainer: {
        display: 'flex',
        alignSelf: 'flex-end',
        width: '45%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    sectionTitle: {
        marginTop: '4%',
        marginBottom: '2%',
        fontSize: '10px',
        fontWeight: 400,
        letterSpacing: '1.5px',
        color: theme.palette.common.black,
        textTransform: 'uppercase',
        textAlign: 'start',
    },
    smallButton: {
        width: '100px',
    },
    fontWeight500: {
        fontWeight: 500,
    },

    width100: {
        width: '100%',
    },
    width50: {
        width: '50%',
    },
    width48: {
        width: '48%',
    },

    flexAlignFlexStart: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
    },
    flexRowOnly: {
        display: 'flex',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    flexColumnCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flexColumnSpaceBetween: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flexColumnSpaceBetweenNoAlign: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    flexRowSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flexRowCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flexRowEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    flexRowStart: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    threeDotsMenuItemContainer: {
        display: 'flex',
        padding: '2em',
        margin: '-2em',
        alignItems: 'center',
        width: '100%',
    },
    threeDotsItemIcon: {
        fill: alpha(theme.palette.common.black, 0.54),
        paddingRight: '20px',
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },

    disabledOpacity: {
        opacity: 0.35,
    },

    mTop0: {
        marginTop: '0',
    },
    mTop10P: {
        marginTop: '10%',
    },
    mTop5P: {
        marginTop: '5%',
    },
    mTop10: {
        marginTop: '10px',
    },
    mTop15: {
        marginTop: '15px',
    },
    mBot10: {
        marginBottom: '10px',
    },
    mBot20: {
        marginBottom: '20px',
    },
    mBot30: {
        marginBottom: '30px',
    },
    pTop5P: {
        paddingTop: '5%',
    },
    pTop10P: {
        paddingTop: '10%',
    },
    mBot10P: {
        marginBottom: '10%',
    },
    mBot2P: {
        marginBottom: '2%',
    },
    mBot5P: {
        marginBottom: '5%',
    },
    mVertical5: {
        margin: '5px 0',
    },
    mVertical10: {
        margin: '10px 0',
    },
    mHorizontal5: {
        margin: '0 5px',
    },
    mHorizontal15: {
        margin: '0 15px',
    },
    pHorizontal1P: {
        padding: '0 1%',
    },
    pVertical5: {
        padding: '5px 0',
    },
    mLeft5: {
        marginLeft: '5px',
    },
    mLeft10: {
        marginLeft: '10px',
    },
    mLeft20: {
        marginLeft: '20px',
    },
    mRight10: {
        marginRight: '10px',
    },
    mRight20: {
        marginRight: '20px',
    },
    mRight50: {
        marginRight: '50px',
    },

    pBottom15: {
        paddingBottom: '15px',
    },

    tabPriceContainer: {
        backgroundColor: alpha(theme.palette.grey['300'], 0.3),
        width: '253px',
        height: '33px',
        borderRadius: '5px',
        padding: '2px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tabPriceAlignRight: {
        position: 'absolute',
        right: 0,
    },
    tabPriceAlignRight10: {
        position: 'absolute',
        right: 10,
    },

    p15: {
        padding: '15px',
    },

    hiddenContainer: {
        display: 'none',
    },

    smallIcon: {
        width: '24px',
        height: '24px',
    },

    mediumIcon: {
        width: '30px',
        height: '30px',
    },

    centeredTypography: {
        flexGrow: 1,
        textAlign: 'center',
    },

    basicHover: {
        '&:hover': {
            cursor: 'pointer',
            boxShadow:
                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',
        },
    },

    cursorHover: {
        '&:hover': {
            cursor: 'pointer',
        },
    },

    accordionBGColor: {
        backgroundColor: alpha(theme.palette.grey['300'], 0.3),
    },

    logoContainer: {
        height: 32,
        width: 32,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        BackgroundPosition: 'center',
        borderRadius: 50,
    },

    imageContainerCenter: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },

    disabled: {
        pointer: 'unset',
        pointerEvents: 'none',
    },
} as any;

export default commonStyles;
