import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import i18n from 'i18n-js';
import { IconButton, ListItemIcon, Paper, Toolbar, Typography, Button } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';

import { MenuEditModal } from '../Modals/MenuEditModal';
import { BasicThreeDotsMenu, Text } from 'components/shared';
import { MenuSectionsTable } from '../MenuSectionManagement';
import logo from 'assets/breakfast-logo.svg';
import { useStyles } from './styles';
import { MenuType, Positions } from 'constants/enums';
import menuDetailsStore from 'store/menuDetailsStore';
import { ApplicationState } from 'store';
import { default as menusStore } from 'store/menusStore';
import { DishData, SectionData } from 'store/menuDetailsStore/reducer';
import {
    AddSectionModal,
    AddDailyMenuSectionModal,
    ChooseExistingModal,
    MenuIngredientManagement,
} from './components';

interface MenuDetailsProps {
    close?: () => void;
    isModal?: boolean;
}

export default function MenuManagementTable(props: MenuDetailsProps) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const dishList = useSelector((state: ApplicationState) => state.menus.dishList);
    const user = useSelector((state: ApplicationState) => state.user);
    const currentRestaurant = user.restaurants?.find((x) => x.id === user.currentRestaurantId);
    const menu = useSelector((state: ApplicationState) => state.menuDetails.menu);
    const [showAddSectionModal, setShowAddSectionModal] = useState(false);
    const [showChooseExistingModal, setShowChooseExistingModal] = useState(false);
    const [showEditMenuModal, setShowEditMenuModal] = useState(false);
    const [selectedSectionId, setSelectedSectionId] = useState('');

    const menuType = menu.type;

    const [renderSections, setRenderSections] = useState([...menu.sections]);

    const draggedSection = useRef<SectionData | null>(null);
    const [draggedSectionId, setDraggedSectionId] = useState('');

    function onDragStart(section: SectionData) {
        setDraggedSectionId(section.id);
        draggedSection.current = section;
    }

    function onDragEnter(section: SectionData) {
        if (section.id !== draggedSection?.current?.id) {
            const tempSections = [...renderSections];
            const removedIndex = tempSections.indexOf(draggedSection?.current!);
            if (removedIndex < 0) {
                return;
            }
            const removedMenu = tempSections.splice(removedIndex, 1);
            const targetMenuIndex = tempSections.indexOf(section);
            if (removedIndex === targetMenuIndex) {
                if (removedIndex === 0 && targetMenuIndex === 0) {
                    tempSections.splice(targetMenuIndex + 1, 0, removedMenu[0]);
                } else if (removedIndex === 1 && targetMenuIndex === 0) {
                    tempSections.unshift(removedMenu[0]);
                } else {
                    tempSections.splice(targetMenuIndex + 1, 0, removedMenu[0]);
                }
            } else if (removedIndex > targetMenuIndex) {
                tempSections.splice(targetMenuIndex, 0, removedMenu[0]);
            } else {
                tempSections.splice(targetMenuIndex + 1, 0, removedMenu[0]);
            }

            setRenderSections(tempSections);
        }
    }

    function onDragEnd() {
        if (
            menu.sections.indexOf(draggedSection.current!) !==
            renderSections.indexOf(draggedSection.current!)
        ) {
            dispatch(
                menuDetailsStore.actionCreators.reorderSections(renderSections.map((x) => x.id))
            );
        }
        setDraggedSectionId('');
        draggedSection.current = null;
    }

    function addSection(sectionName: string) {
        setShowAddSectionModal(false);
        dispatch(
            menuDetailsStore.actionCreators.addSection({
                name: sectionName,
                menuId: menu.id,
            })
        );
    }

    function addExistingDish(sectionId: string, dishId: string, dish: DishData) {
        hideExistingModal();
        dispatch(
            menuDetailsStore.actionCreators.addExistingDish({
                sectionId,
                dishId,
                dish,
            })
        );
    }

    function showExistingModal(sectionId: string) {
        setSelectedSectionId(sectionId);
        setShowChooseExistingModal(true);
    }

    function hideExistingModal() {
        setShowChooseExistingModal(false);
        setSelectedSectionId('');
    }

    function handleBackButtonClick() {
        !!props.close ? props.close() : history.goBack();
    }

    useEffect(() => {
        dispatch(menusStore.actionCreators.getDishList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setRenderSections([...menu.sections]);
    }, [menu.id]);

    function getTotalItemAmount() {
        let total = 0;
        menu.sections.forEach((each) => {
            each.dishes.filter((dish) => !dish.isDisabled).forEach(() => (total += 1));
        });
        return total;
    }

    return (
        <div className={classes.root}>
            {!props.isModal && (
                <Toolbar style={{ paddingBottom: '10px' }}>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        className={classes.iconButton}
                        onClick={handleBackButtonClick}
                    >
                        <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                    <Typography
                        variant="h6"
                        id="tableTitle"
                        component="div"
                        className={classes.toolbarTitle}
                    >
                        <Text text={menu.name} maxLength={40} fontSize={18} />
                        <img src={logo} className={classes.breakfastLogo} alt="logo" />
                        {menuType === MenuType.Daily && (
                            <span className={classes.dailyMenu}>/ Daily menu</span>
                        )}
                    </Typography>
                    {menuType === MenuType.Daily && <></>}
                    {menuType === MenuType.Standard && (
                        <Typography align={'right'} className={classes.itemAmount}>
                            {`${getTotalItemAmount() || 0} ${i18n.t('common.items')}`}
                        </Typography>
                    )}
                    {currentRestaurant?.currentPosition === Positions.MANAGER &&
                        (menuType === MenuType.Daily ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setShowAddSectionModal(true)}
                            >
                                {`+ ${i18n.t('restaurant.newMenu').toUpperCase()}`}
                            </Button>
                        ) : (
                            <BasicThreeDotsMenu
                                items={
                                    menuType === MenuType.Standard
                                        ? [
                                              <div
                                                  className={classes.threeDotsMenuItemContainer}
                                                  onClick={() => setShowAddSectionModal(true)}
                                              >
                                                  <ListItemIcon>
                                                      <AddIcon fontSize="small" color="disabled" />
                                                  </ListItemIcon>
                                                  <Typography variant="inherit">
                                                      {i18n.t('common.addSection')}
                                                  </Typography>
                                              </div>,
                                              <div
                                                  className={classes.threeDotsMenuItemContainer}
                                                  onClick={() => {
                                                      setShowEditMenuModal(true);
                                                  }}
                                              >
                                                  <ListItemIcon>
                                                      <BorderColorIcon
                                                          fontSize="small"
                                                          color="disabled"
                                                      />
                                                  </ListItemIcon>
                                                  <Typography variant="inherit">
                                                      {i18n.t('common.edit')}
                                                  </Typography>
                                              </div>,
                                          ]
                                        : [
                                              <div
                                                  className={classes.threeDotsMenuItemContainer}
                                                  onClick={() => {
                                                      setShowEditMenuModal(true);
                                                  }}
                                              >
                                                  <ListItemIcon>
                                                      <BorderColorIcon
                                                          fontSize="small"
                                                          color="disabled"
                                                      />
                                                  </ListItemIcon>
                                                  <Typography variant="inherit">
                                                      {i18n.t('common.edit')}
                                                  </Typography>
                                              </div>,
                                          ]
                                }
                            />
                        ))}
                </Toolbar>
            )}

            <Paper
                className={clsx({
                    [classes.forModal]: props.isModal,
                    [classes.menuSectionsContainer]: props.isModal,
                })}
            >
                {props.isModal ? (
                    <>
                        <div style={{ width: '67%' }}>
                            {menu.sections.length > 0 &&
                                menu.sections.map((section: any) => (
                                    <MenuSectionsTable
                                        key={section.name}
                                        onChooseExisting={() => showExistingModal(section.id)}
                                        onDishEdit={section.onDishEdit}
                                        sectionId={section.id}
                                        isDisabled={section.isDisabled}
                                        isModal={props.isModal}
                                    />
                                ))}
                        </div>
                        <div style={{ width: '30%' }}>
                            <MenuIngredientManagement forNewOrderItems />
                        </div>
                    </>
                ) : (
                    renderSections.length > 0 &&
                    renderSections.map((section: any) => (
                        <div
                            key={section.id}
                            draggable={currentRestaurant?.currentPosition === Positions.MANAGER}
                            onDragStart={() => {
                                onDragStart(section);
                            }}
                            onDragEnter={() => onDragEnter(section)}
                            onDragEnd={onDragEnd}
                            onDragOver={(e) => e.preventDefault()}
                            style={{
                                opacity: section.id === draggedSectionId ? 0.1 : 1,
                            }}
                        >
                            <MenuSectionsTable
                                key={section.name}
                                onChooseExisting={() => showExistingModal(section.id)}
                                onDishEdit={section.onDishEdit}
                                sectionId={section.id}
                                isDisabled={section.isDisabled}
                                isModal={props.isModal}
                            />
                        </div>
                    ))
                )}
            </Paper>
            <AddSectionModal
                isVisible={showAddSectionModal && menu.type === MenuType.Standard}
                onClose={() => setShowAddSectionModal(false)}
                onAdd={(sectionName) => addSection(sectionName)}
                sections={menu.sections.map((x) => x.name.toUpperCase())}
            />
            <AddDailyMenuSectionModal
                isVisible={showAddSectionModal && menu.type === MenuType.Daily}
                onClose={() => setShowAddSectionModal(false)}
                onAdd={(section) => {
                    dispatch(
                        menuDetailsStore.actionCreators.addSection({
                            ...section,
                            menuId: menu.id,
                        })
                    );
                    setShowAddSectionModal(false);
                }}
                menus={menu.sections.map((x) => x.name.toUpperCase())}
            />
            <ChooseExistingModal
                dishList={dishList}
                sections={menu.sections}
                isVisible={showChooseExistingModal}
                onClose={() => setShowChooseExistingModal(false)}
                onAdd={(sectionId, dishId, dish) => addExistingDish(sectionId, dishId, dish)}
                sectionId={selectedSectionId}
            />
            <MenuEditModal
                menu={{
                    edit: null,
                    id: menu.id,
                    name: menu.name,
                    description: menu.description,
                    availableFrom: menu.availableFrom,
                    availableTo: menu.availableTo,
                    price: menu.price,
                    photo: menu.photo,
                    type: menu.type,
                    sections: [],
                    dishesCount: menu.dishesCount,
                    isEnabled: menu.isEnabled,
                    position: menu.position,
                    discount: menu.discount,
                }}
                isOpen={showEditMenuModal}
                handleClose={() => setShowEditMenuModal(false)}
                isDetails={true}
            />
        </div>
    );
}
