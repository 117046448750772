import React, { useEffect, useState } from 'react';

import i18n from 'i18n-js';
import { Form, Formik, getIn } from 'formik';
import clsx from 'clsx';
import { InputAdornment, TextField, TextField as MuiTextField } from '@material-ui/core';
import { Typography, Dialog, DialogTitle, DialogContent, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import * as yup from 'yup';

import { images } from 'assets';
import { Input, TimePickerModal } from 'components/shared';
import { CategoryDetailsData, CategoryDishData } from 'store/categoryStore/reducer';
import { getFormattedHour, getInitialDates } from '../functions';
import { trimLeftAndRight } from 'helpers/helperFunctions';
import styles from '../styles';
import { PositionsList } from 'constants/lists';
import { HourData, defaultHours } from './AddWorkingHoursModal';

interface CreateOrEditCategoryProps {
    isVisible: boolean;
    onClose: () => void;
    onCreate: (
        categoryName: string,
        dishes: string[],
        openHours: HourData[],
        position: string
    ) => void;
    onEdit: (
        categoryName: string,
        dishes: string[],
        openHours: HourData[],
        position: string,
        categoryId: string
    ) => void;
    categoryDetails: CategoryDetailsData;
    dishes: CategoryDishData[];
    categoryName: string;
    categoryId: string;
    position: string;
    isEdit: boolean;
    categories: string[];
}

export default function CreateOrEditCategoryModal(props: CreateOrEditCategoryProps) {
    const [workingHours, setWorkingHours] = useState<HourData[]>([defaultHours]);
    const [timePickerModalType, setTimePickerModalType] = useState(0);
    const [checkedOptions, setCheckedOptions] = useState<string[]>([]);
    const [clickedElementRect, setClickedElementRect] = useState<any>(null);
    const classes = styles();

    useEffect(() => {
        setCheckedOptions(getCheckedDishes());
        setWorkingHours(getWorkingHours());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dishes]);

    function preventPropagationAndCheck(e: any, id: string) {
        e.stopPropagation();
        e.preventDefault();
        setCheckedOne(id);
    }

    function getWorkingHours() {
        const defaultWorkingHours = [defaultHours];

        if (props.isEdit) {
            const currentCategory = props.categoryDetails.categories.find(
                (category) => category.id === props.categoryId
            );
            if (currentCategory && currentCategory.openHours.length > 0) {
                return currentCategory.openHours;
            }
            return defaultWorkingHours;
        }
        return defaultWorkingHours;
    }

    function addMoreHours() {
        setWorkingHours([...workingHours, defaultHours]);
    }

    function getCheckedDishes(): string[] {
        if (props.dishes.length > 0) {
            const dishIds: string[] = [];
            props.dishes.map((dish) => dishIds.push(dish.id));
            return dishIds;
        }
        return [];
    }

    function setCheckedOne(id: string) {
        let newCheckedOptions = [...checkedOptions];
        if (checkedOptions.includes(id)) {
            newCheckedOptions = newCheckedOptions.filter((currentOption) => currentOption !== id);
        } else {
            newCheckedOptions.push(id);
        }
        setCheckedOptions(newCheckedOptions);
    }

    const validationSchema = yup.object().shape({
        categoryName: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Category Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !props.categories.includes(value?.toUpperCase())
            ),
        position: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Position Name',
            })
        ),
    });

    function getAvailableDishes() {
        const availableDishes = props.categoryDetails.dishes.filter((dish) => !dish.categoryId);
        return [...availableDishes, ...props.dishes];
    }

    function onCloseClicked() {
        setWorkingHours([defaultHours]);
        props.onClose();
    }

    function getClickedAndSet(i: number) {
        const id = `Create-Mui${i}`;
        const clickedElement = document.getElementById(id);
        const clickedElementRect = clickedElement?.getBoundingClientRect();
        if (clickedElementRect) {
            // @ts-ignore
            clickedElementRect.index = i;
        }
        setClickedElementRect(clickedElementRect);
    }

    return (
        <Dialog open={props.isVisible} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Typography variant="h6" align="left" className={classes.fontWeight500}>
                    {i18n.t(`${props.isEdit ? 'common.editCategory' : 'common.createNewCategory'}`)}
                </Typography>
            </DialogTitle>
            <DialogContent style={{ overflowY: 'auto' }}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        categoryName: props.categoryName,
                        dishes: props.dishes,
                        position: props.position,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        if (values.categoryName !== '') {
                            setSubmitting(false);
                            if (props.isEdit) {
                                props.onEdit(
                                    trimLeftAndRight(values.categoryName),
                                    checkedOptions,
                                    workingHours,
                                    values.position,
                                    props.categoryId
                                );
                            } else {
                                props.onCreate(
                                    trimLeftAndRight(values.categoryName),
                                    checkedOptions,
                                    workingHours,
                                    values.position
                                );
                            }
                            props.onClose();
                        }
                    }}
                >
                    {({ errors, submitForm, values, setFieldValue, touched }) => (
                        <Form className={classes.smallModalContainer}>
                            <Input
                                error={errors.categoryName}
                                variant="filled"
                                name={`categoryName`}
                                type="text"
                                label={i18n.t('common.categoryName')}
                                placeholder={i18n.t('common.categoryName')}
                            />
                            <>
                                <Autocomplete
                                    id="dishes"
                                    options={getAvailableDishes()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    renderOption={(option) => (
                                        <div
                                            onClick={(e) =>
                                                preventPropagationAndCheck(e, option.id)
                                            }
                                            className={clsx(classes.width100, classes.flexRowStart)}
                                        >
                                            <Checkbox
                                                checked={checkedOptions.includes(option.id)}
                                            />
                                            <div
                                                className={classes.coffeeCupIcon}
                                                style={{
                                                    backgroundImage: `url(${images.icons.coffee})`,
                                                }}
                                            />
                                            {option.name}
                                        </div>
                                    )}
                                    renderInput={(params) => (
                                        <>
                                            <MuiTextField
                                                {...params}
                                                name="dishes"
                                                className={classes.dishesSelect}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <div
                                                                className={classes.coffeeCupIcon}
                                                                style={{
                                                                    backgroundImage: `url(${images.icons.coffee})`,
                                                                }}
                                                            />
                                                            <span>
                                                                {`${checkedOptions.length} ${i18n.t(
                                                                    'common.items'
                                                                )}`}
                                                            </span>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                label={i18n.t('common.chooseDishes')}
                                                variant="filled"
                                            />
                                        </>
                                    )}
                                />
                                <Autocomplete
                                    options={PositionsList.map((item) => item.name)}
                                    getOptionLabel={(option) => option}
                                    value={values.position}
                                    getOptionSelected={(option, value) => option === value}
                                    onChange={(e: object, value: any | null) => {
                                        setFieldValue('position', value);
                                    }}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                {...params}
                                                name="position"
                                                label={i18n.t('form.responsiblePosition')}
                                                variant="filled"
                                                error={
                                                    values.position === '' &&
                                                    getIn(touched, 'position')
                                                }
                                            />
                                        </>
                                    )}
                                />
                                {workingHours.map((workingHour, i: number) => (
                                    <div key={i}>
                                        <MuiTextField
                                            id={`Create-Mui${i}`}
                                            className={classes.width100}
                                            value={workingHour ? getFormattedHour(workingHour) : ''}
                                            onClick={() => {
                                                setTimePickerModalType(i + 1);
                                                getClickedAndSet(i);
                                            }}
                                            variant="filled"
                                            name={`extraHours[${i}]`}
                                            type="text"
                                            label={
                                                i18n.t('common.openHours') +
                                                ` ${i > 0 ? i + 1 : ''}`
                                            }
                                            placeholder={i18n.t('common.openHours')}
                                            InputProps={
                                                i > 0
                                                    ? {
                                                          endAdornment: (
                                                              <InputAdornment
                                                                  position="end"
                                                                  onClick={(e) => {
                                                                      e.preventDefault();
                                                                      e.stopPropagation();
                                                                      setWorkingHours(
                                                                          (oldWorkingHours) =>
                                                                              oldWorkingHours.filter(
                                                                                  (val, oldI) =>
                                                                                      oldI !== i
                                                                              )
                                                                      );
                                                                  }}
                                                              >
                                                                  <img
                                                                      alt="remove-icon"
                                                                      src={
                                                                          images.icons.removeCircle
                                                                      }
                                                                      className={classes.basicHover}
                                                                  />
                                                              </InputAdornment>
                                                          ),
                                                      }
                                                    : {}
                                            }
                                        />
                                        <br />
                                    </div>
                                ))}
                            </>
                            <div className={classes.flexRowSpaceBetween}>
                                <Button
                                    className={classes.mTop10}
                                    type="button"
                                    color="primary"
                                    onClick={addMoreHours}
                                >
                                    {i18n.t('common.addMoreHours')}
                                </Button>
                                <div className={classes.smallModalButtonContainer}>
                                    <Button
                                        style={{ marginRight: '15px' }}
                                        type="button"
                                        color="primary"
                                        onClick={onCloseClicked}
                                    >
                                        {i18n.t('button.cancel')}
                                    </Button>
                                    <Button
                                        onClick={submitForm}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {i18n.t(
                                            `${props.isEdit ? 'button.save' : 'common.create'}`
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
            <TimePickerModal
                initialFromHour={getInitialDates(workingHours[timePickerModalType], 'from', 'hour')}
                initialFromMinute={getInitialDates(
                    workingHours[timePickerModalType],
                    'from',
                    'minute'
                )}
                initialToHour={getInitialDates(workingHours[timePickerModalType], 'to', 'hour')}
                initialToMinute={getInitialDates(workingHours[timePickerModalType], 'to', 'minute')}
                clickedElementRect={clickedElementRect}
                isVisible={timePickerModalType > 0}
                onClose={(selectedHours) => {
                    let newWorkingHours = [...workingHours];
                    newWorkingHours[timePickerModalType - 1] = selectedHours;
                    setWorkingHours(newWorkingHours);
                    setTimePickerModalType(0);
                }}
                onDateChange={(selectedHours) => {
                    let newWorkingHours = [...workingHours];
                    newWorkingHours[timePickerModalType - 1] = selectedHours;
                    setWorkingHours(newWorkingHours);
                }}
            />
        </Dialog>
    );
}
