import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles, TableCell } from '@material-ui/core';

interface CellWithActionProps {
    id: string;
    content: any;
}

const useStyles = makeStyles({
    cell: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
});

export const CellWithAction = (props: CellWithActionProps) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <TableCell
            className={classes.cell}
            align="left"
            onClick={() => history.push(`/restaurants/${props.id}`)}
        >
            {props.content}
        </TableCell>
    );
};
