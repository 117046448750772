import React, { useRef, useState } from 'react';

import i18n from 'i18n-js';

import { IconButton, Checkbox } from '@material-ui/core';

import { DishData } from 'store/menuDetailsStore/reducer';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';
import { IngredientModel } from 'api/models';
import { truncateString } from 'helpers/helperFunctions';
import { images } from 'assets';

interface DishIngredientItemProps {
    defaultDish: DishData;
    hidable?: boolean;
    open?: boolean;
    onValueChange: (newDish: DishData) => void;
}

export default function DishIngredientItem(props: DishIngredientItemProps) {
    const classes = useStyles();

    const allergens = useAppSelector((state) => state.ingredients.allergies);

    const [dish, setDish] = useState(getDefaultDish());
    const [isOpen, setIsOpen] = useState(props.open);

    const defaultDishValues = useRef({ ...getDefaultDish() }).current;

    function getDefaultDish() {
        const defaultIngredients: any = [];
        if (defaultIngredients && props.defaultDish?.ingredients) {
            props.defaultDish.ingredients.forEach((ingredient: IngredientModel) => {
                defaultIngredients.push({
                    ...ingredient,
                    isAdditionChecked: ingredient.isAdditionChecked ?? ingredient.isAddition,
                    isRemovalChecked: ingredient.isRemovalChecked ?? ingredient.isRemoval,
                });
            });
            props.defaultDish.ingredients = defaultIngredients;
        }

        return {
            ...props.defaultDish,
        };
    }

    function changeValue(key: string, id: string | undefined, value: boolean | undefined) {
        if (id) {
            const newDish = { ...dish };
            const index = newDish.ingredients.findIndex((each) => each.id === id);
            const modifiedIngredient = newDish.modifiedIngredients?.find((each) => each.id === id);
            if (modifiedIngredient) {
                newDish.modifiedIngredients = newDish.modifiedIngredients?.filter(
                    (each) => each.id !== id
                );
            } else {
                if (!newDish.modifiedIngredients) {
                    newDish.modifiedIngredients = [];
                }
                newDish.modifiedIngredients.push({
                    id,
                    [key]: !value,
                });
            }
            if (
                JSON.stringify(newDish.ingredients) !==
                JSON.stringify(defaultDishValues.ingredients)
            ) {
                newDish.modified = true;
                newDish.ingredients[index].modified = true;
            } else {
                delete newDish.modified;
                delete newDish.ingredients[index].modified;
            }
            props.onValueChange({ ...newDish });
            setDish({ ...newDish });
        }
    }

    function getAllergenIcons() {
        return dish.allergens?.map((id: string) => {
            const allergen = allergens.find((each) => each.id === id);
            return (
                <img
                    src={allergen?.icon}
                    alt={allergen?.name}
                    style={{ height: '24px', marginLeft: 7 }}
                />
            );
        });
    }

    function getFixedIngredients(defDish: any = null) {
        const dishToFilter = defDish ? defDish : dish;
        return dishToFilter.ingredients?.filter(
            (each: IngredientModel) => each.state === 0 && !each.isRemoval
        );
    }

    function getChangeOrDeleteIngredients(defDish: any = null) {
        const dishToFilter = defDish ? defDish : dish;
        return dishToFilter.ingredients?.filter(
            (each: IngredientModel) => each.state === 0 && each.isRemoval
        );
    }

    function getAdditionalIngredients(defDish: any = null) {
        const dishToFilter = defDish ? defDish : dish;
        return dishToFilter.ingredients?.filter((each: IngredientModel) => each.state === 1);
    }

    function getValue(id: string | undefined, key: string) {
        const modifiedIngredient = dish.modifiedIngredients?.find((each) => each.id === id);
        if (modifiedIngredient) {
            return !!modifiedIngredient[key as keyof typeof modifiedIngredient];
        }

        return !!dish.ingredients.find((each) => each.id === id)?.[
            key as keyof typeof modifiedIngredient
        ];
    }

    return (
        <div className={classes.dishIngredientItemContainer}>
            <div className={classes.dishIngredientItemHeader}>
                <div
                    onClick={() => setIsOpen(!isOpen)}
                    className={`${classes.flexRowSpaceBetween} ${classes.mBot20}`}
                    style={{ cursor: props.hidable ? 'pointer' : 'auto' }}
                >
                    <div className={classes.flexRow}>
                        <span className={classes.dishTitle}>{truncateString(dish.name, 25)}</span>
                        <div style={{ marginLeft: 3 }}>
                            {dish.isSpicy && (
                                <IconButton
                                    className={classes.mLeft5}
                                    aria-label="add to favorites"
                                >
                                    <img src={images.icons.chili} alt="chili-paper" />
                                </IconButton>
                            )}
                            {dish.isVegetarian && (
                                <IconButton
                                    className={classes.mLeft5}
                                    aria-label="add to favorites"
                                >
                                    <img src={images.icons.leaf} alt="green-leaf" />
                                </IconButton>
                            )}
                            {dish.isVegan && (
                                <IconButton
                                    className={classes.mLeft5}
                                    aria-label="add to favorites"
                                >
                                    <img src={images.icons.doubleLeaf} alt="chili-paper" />
                                </IconButton>
                            )}
                            {dish.isHalal && (
                                <IconButton
                                    className={classes.mLeft5}
                                    aria-label="add to favorites"
                                >
                                    <img src={images.icons.halal} alt="green-leaf" />
                                </IconButton>
                            )}
                        </div>
                    </div>
                    {props.hidable && (
                        <img
                            src={images.icons.arrowDropdown}
                            alt="arrow"
                            style={{
                                marginRight: 20,
                                transform: isOpen ? 'rotate(180deg)' : 'rotate(360deg)',
                            }}
                        />
                    )}
                </div>
                {isOpen && (
                    <>
                        <span className={classes.dishDescription}>
                            {truncateString(dish.shortDescription, 30)}
                        </span>
                        <div style={{ marginTop: 20, marginLeft: -8 }}>{getAllergenIcons()}</div>
                    </>
                )}
            </div>
            <div className={classes.divider} />
            {isOpen && (
                <div style={{ paddingRight: 20 }}>
                    {getFixedIngredients()?.length > 0 && (
                        <>
                            <div className={classes.flexRowAlignCenter}>
                                <span className={`${classes.subTitle} ${classes.mBot10}`}>
                                    {i18n.t('restaurant.fixedIngredient').toUpperCase()}
                                </span>
                                <img
                                    src={images.icons.lock}
                                    alt={'lock'}
                                    style={{ marginLeft: 8, marginBottom: -2 }}
                                />
                            </div>
                            <div className={classes.mBot10}>
                                {getFixedIngredients()?.map((each: IngredientModel) => {
                                    return (
                                        <div
                                            className={`${classes.flexRowSpaceBetween} ${classes.ingredientText} ${classes.mTop10}`}
                                            key={each.id}
                                        >
                                            <span>{each.name ?? 'No Name'}</span>
                                            <span>{each.amount}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {getChangeOrDeleteIngredients()?.length > 0 && (
                        <>
                            <span className={`${classes.subTitle} ${classes.mBot10}`}>
                                {i18n.t('restaurant.changeOrDelete').toUpperCase()}
                            </span>
                            <div>
                                {getChangeOrDeleteIngredients()?.map((each: IngredientModel) => {
                                    return (
                                        <div
                                            className={`${classes.flexRowSpaceBetween} ${classes.ingredientText}`}
                                        >
                                            <div className={classes.flexRowSpaceBetween}>
                                                <Checkbox
                                                    style={{ marginLeft: -10 }}
                                                    checked={getValue(each.id, 'isRemovalChecked')}
                                                    onChange={() => {
                                                        changeValue(
                                                            'isRemovalChecked',
                                                            each.id,
                                                            each.isRemovalChecked
                                                        );
                                                    }}
                                                />
                                                <span>{each.name ?? 'No Name'}</span>
                                            </div>
                                            <span>{each.amount}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {getAdditionalIngredients()?.length > 0 && (
                        <>
                            <span className={`${classes.subTitle} ${classes.mBot10}`}>
                                {i18n.t('restaurant.additionalIngredients').toUpperCase()}
                            </span>
                            <div>
                                {getAdditionalIngredients()?.map((each: IngredientModel) => {
                                    return (
                                        <div
                                            className={`${classes.flexRowSpaceBetween} ${classes.ingredientText}`}
                                        >
                                            <div className={classes.flexRowSpaceBetween}>
                                                <Checkbox
                                                    style={{ marginLeft: -10 }}
                                                    checked={getValue(each.id, 'isAdditionChecked')}
                                                    onChange={() => {
                                                        changeValue(
                                                            'isAdditionChecked',
                                                            each.id,
                                                            each.isAdditionChecked
                                                        );
                                                    }}
                                                />
                                                <span>{each.name ?? 'No Name'}</span>
                                            </div>
                                            <span>{each.amount}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
