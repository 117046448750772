import { Action, Reducer } from 'redux';
import { GlobalAction } from './actions';
import { prepareLanguage } from 'helpers/helperFunctions';

export type ToasterType = 'success' | 'info' | 'warning' | 'error';

export type LangCode = 'en' | 'de';

export interface GlobalState {
    isLoading: boolean;
    showToaster: boolean;
    toasterType: ToasterType;
    toasterMessage: string;
    language: LangCode;
}

//REDUCER
const initialState: GlobalState = {
    isLoading: false,
    showToaster: false,
    toasterType: 'info',
    toasterMessage: '',
    language: 'de',
};

export const reducer: Reducer<GlobalState> = (
    state: GlobalState | undefined,
    incomingAction: Action
): GlobalState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as GlobalAction;
    switch (action.type) {
        case 'GLOBAL_SHOW_SPINER':
            return {
                ...state,
                isLoading: true,
            };
        case 'GLOBAL_HIDE_SPINER':
            return {
                ...state,
                isLoading: false,
            };
        case 'GLOBAL_SHOW_TOASTER':
            return {
                ...state,
                showToaster: true,
                toasterMessage: action.toasterMessage,
                toasterType: action.toasterType,
            };
        case 'GLOBAL_HIDE_TOASTER':
            return {
                ...state,
                showToaster: false,
                toasterMessage: '',
            };
        case 'GLOBAL_CHANGE_LANGUAGE':
            return {
                ...state,
                language: prepareLanguage(action.code),
            };
        default:
            return state;
    }
};
