import { AppThunkAction } from 'store';

import i18n from 'i18n-js';

import agent from 'api/agent';
import {
    AddExistingDishModel,
    AddSectionModel,
    DeleteDishModel,
    DeleteSectionModel,
    DishCreateModel,
    RenameSectionModel,
    AddSectionDailyMenyModel,
    EditSectionDailyMenyModel,
} from 'api/models';
import globalStore from 'store/globalStore';
import { MenuDetailsAction } from './actions';

function instanceOfDailyMenuSection(data: any): data is AddSectionDailyMenyModel {
    return 'availability' in data;
}

export const actionCreators = {
    getDetails:
        (id: string): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_GET_DETAILS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.GetDetails(id)
                .then((response) => {
                    dispatch({
                        type: 'MENU_GET_DETAILS_SUCCESS',
                        menu: response,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_GET_DETAILS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    renameSection:
        (id: string, model: RenameSectionModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_RENAME_SECTION_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.RenameSection(id, model)
                .then((response) => {
                    dispatch({
                        type: 'MENU_RENAME_SECTION_SUCCESS',
                        menu: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_RENAME_SECTION_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    addNewItem:
        (model: DishCreateModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_ADD_NEW_ITEM_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.CreateDish(model)
                .then((response) => {
                    dispatch({
                        type: 'MENU_ADD_NEW_ITEM_SUCCESS',
                        dish: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.createdSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_ADD_NEW_ITEM_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    updateItem:
        (
            model: DishCreateModel,
            id: string,
            sectionId: string
        ): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_ADD_NEW_ITEM_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.EditDish(model, id)
                .then((response) => {
                    response.sectionId = model.sectionId;
                    dispatch({
                        type: 'MENU_UPDATE_ITEM_SUCCESS',
                        dish: response,
                        sectionId,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_ADD_NEW_ITEM_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    addSection:
        (model: AddSectionModel | AddSectionDailyMenyModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            if (instanceOfDailyMenuSection(model)) {
                agent.Menus.AddDailyMenuSection(model as AddSectionDailyMenyModel).then(() => {
                    // @ts-ignore
                    actionCreators.getDetails(model.menuId)(dispatch);
                });
            } else {
                agent.Menus.AddSection(model).then(() => {
                    // @ts-ignore
                    actionCreators.getDetails(model.menuId)(dispatch);
                });
            }
        },
    deleteSection:
        (model: DeleteSectionModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.DeleteSection(model)
                .then(() => {
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.deletedSuccesfully')
                    )(dispatch);
                    // @ts-ignore
                    actionCreators.getDetails(model.menuId)(dispatch);
                })
                .catch((e) => globalStore.actionCreators.showToaster('error', e)(dispatch))
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    deleteDish:
        (model: DeleteDishModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.RemoveDish(model)
                .then(() => {
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.deletedSuccesfully')
                    )(dispatch);
                    // @ts-ignore
                    actionCreators.getDetails(model.menuId)(dispatch);
                })
                .catch((e) => globalStore.actionCreators.showToaster('error', e)(dispatch))
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    disableDish:
        (model: DeleteDishModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'DISABLE_DISH_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            const { dishId, sectionId } = model;
            agent.Menus.DisableDish(dishId)
                .then(() => {
                    dispatch({
                        type: 'DISABLE_DISH_SUCCESS',
                        dishId,
                        sectionId,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'DISABLE_DISH_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    addExistingDish:
        (model: AddExistingDishModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_GET_DETAILS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.AddExistingDish(model)
                .then(() => {
                    dispatch({
                        type: 'ADD_EXISTING_DISH_TO_SECTION',
                        sectionId: model.sectionId,
                        dish: model.dish,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                    // actionCreators.getDetails(model.menuId)(dispatch);
                })
                .catch((e) => globalStore.actionCreators.showToaster('error', e)(dispatch))
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    disableEnableSection:
        (id: string): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'DISABLE_ENABLE_SECTION_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.DisableEnableDailyMenuSection(id)
                .then((res) => {
                    dispatch({
                        type: 'DISABLE_ENABLE_SECTION_SUCCESS',
                        payload: res,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'DISABLE_ENABLE_SECTION_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    editDailyMenuSection:
        (id: string, model: EditSectionDailyMenyModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'EDIT_DAILY_MENU_SECTION_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.EditDailyMenuSection(id, model)
                .then((result) => {
                    dispatch({
                        type: 'EDIT_DAILY_MENU_SECTION_SUCCESS',
                        id: result.id,
                        name: result.name,
                        price: result.price,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'EDIT_DAILY_MENU_SECTION_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    reorderSections:
        (ids: string[], isDaily: boolean = false): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_SECTIONS_REORDER_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.ReorderSections(ids, isDaily)
                .then((response) => {
                    dispatch({
                        type: 'MENU_SECTIONS_REORDER_SUCCESS',
                        sections: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_SECTIONS_REORDER_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    reorderDishes:
        (
            sectionId: string,
            ids: string[],
            isDaily: boolean = false
        ): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_SECTION_DISHES_REORDER_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.ResorderDishes(sectionId, ids, isDaily)
                .then((response) => {
                    dispatch({
                        type: 'MENU_SECTION_DISHES_REORDER_SUCCESS',
                        dishes: response.dishes,
                        sectionId: response.sectionId,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_SECTION_DISHES_REORDER_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    setSectionColor:
        (id: string, isDaily: boolean, color: string): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_SECTION_SET_COLOR_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.SetSectionColor(id, { color, isDaily })
                .then((response) => {
                    dispatch({
                        type: 'MENU_SECTION_SET_COLOR_SUCCESS',
                        sectionId: id,
                        color: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_SECTION_SET_COLOR_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    setSectionDishColor:
        (
            sectionId: string,
            dishId: string,
            isDaily: boolean,
            color: string
        ): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_SECTION_DISH_SET_COLOR_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.SetSectionDishColor(sectionId, dishId, { color, isDaily })
                .then((response) => {
                    dispatch({
                        type: 'MENU_SECTION_DISH_SET_COLOR_SUCCESS',
                        sectionId,
                        dishId,
                        color: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_SECTION_DISH_SET_COLOR_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    copySectionDish:
        (sectionId: string, dishId: string): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_SECTION_DISH_COPY_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.CopySectionDish(sectionId, dishId)
                .then((response) => {
                    dispatch({
                        type: 'MENU_SECTION_DISH_COPY_SUCCESS',
                        sectionId,
                        dish: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_SECTION_DISH_COPY_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
};
