import { useEffect } from 'react';
import userManager from 'managers/userManager';

export function RedirectToLogin() {
    const regex = new RegExp('^oidc.');
    useEffect(() => {
        let isAuth = false;
        for (const i in sessionStorage) {
            if (regex.test(i)) {
                isAuth = true;
                break;
            }
        }

        !isAuth &&
            userManager.signinRedirect({
                data: { path: '/' },
            });
    }, []); // eslint-disable-line

    return null;
}
