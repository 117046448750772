import React, { useEffect, useRef, useState } from 'react';

import DatePicker, { setDefaultLocale, registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';

import Button from '@material-ui/core/Button';
import i18n from 'i18n-js';
import 'react-datepicker/dist/react-datepicker.css';
import { alpha, makeStyles } from '@material-ui/core';
import OutsideClickHandler from 'react-outside-click-handler';

import { theme } from 'config/theme';
import { setHours, setMinutes } from 'date-fns';

registerLocale('de', de);

export interface DateTimePickerProps {
    date: Date;
    onSubmit: (pickedDates: any) => void;
    isOpen: boolean;
    isDateRange?: boolean;
    onClose: () => void;
    showButtons?: boolean;
    futureOnly?: boolean;
    futureTimeOnly?: boolean;
}

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        left: -1,
        top: -1,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        borderRadius: 7,
        border: 'none',
        outline: 'none',
        '&:focus-visible': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&::-webkit-datetime-edit-fields-wrapper': { background: 'silver' },
        '&::-webkit-datetime-edit': {
            display: 'none',
        },
        '&::-webkit-calendar-picker-indicator': {
            position: 'absolute',
            cursor: 'pointer',
            top: 0,
            right: 0,
            padding: 0,
            height: 'inherit',
            width: 'inherit',
            background: 'transparent',
        },
    },
    dt: {
        position: 'absolute',
        top: 'calc(100% + 5px)',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 100,
        '& .react-datepicker': {
            display: 'flex',
            zIndex: 100,
            fontFamily: 'unset',
            padding: '0 0 0 10px',
            boxShadow: '0px 6px 5px rgba(0, 0, 0, 0.24), 0px 9px 18px rgba(0, 0, 0, 0.18)',
            border: 'none',
        },
        '& .react-datepicker__header': {
            textAlign: 'left',
            backgroundColor: 'transparent',
            borderBottom: 'none',
            paddingBottom: 0,
            paddingTop: 15,
        },
        '& .react-datepicker__day-names': {
            textAlign: 'center',
            paddingLeft: 5,
            fontSize: 12,
            marginTop: 10,
            marginBottom: 1,
        },
        '& .react-datepicker__month-container': {
            paddingBottom: 20,
        },
        '& .react-datepicker__week .react-datepicker__day': {
            fontSize: 16,
            color: theme.palette.text.primary,
            width: 32,
            height: 32,
        },
        '& .react-datepicker__today-button': {
            position: 'absolute',
            bottom: 0,
        },
        '& .react-datepicker__time-container--with-today-button': {
            top: -2,
            right: -88,
        },
        '& .react-datepicker__current-month, & .react-datepicker__header__dropdown, & .react-datepicker__year-read-view--selected-year':
            {
                color: theme.palette.text.secondary,
                fontSize: 14,
                fontWeight: 600,
                display: 'inline-block',
            },
        '& .react-datepicker__year-read-view--down-arrow': {
            height: 0,
            width: 0,
            borderRight: '5px solid transparent',
            borderLeft: '5px solid transparent',
            borderTop: `5px solid ${theme.palette.text.secondary}`,
            transform: 'none',
            top: 8,
        },
        '& .react-datepicker__navigation': {
            top: 15,
        },
        '& .react-datepicker__navigation-icon::before': {
            height: 6,
            width: 6,
            borderColor: theme.palette.text.disabled,
            borderWidth: '2px 2px 0 0',
        },
        '& .react-datepicker__navigation--previous': {
            left: 200,
        },
        '& .react-datepicker__current-month': {
            marginLeft: 8,
        },
        '& .react-datepicker__day-name': {
            color: 'transparent',
            width: 32,
        },
        '& .react-datepicker__day-name:first-letter': {
            color: theme.palette.text.secondary,
        },
        '& ul.react-datepicker__time-list': {
            height: '265px !important',
            marginTop: '14px !important',
            color: theme.palette.grey[800],
            fontSize: 14,
            fontWeight: 500,
        },
        '& .react-datepicker__time-container': {
            border: 'none',
        },
        '& .react-datepicker__header--time': {
            display: 'none',
        },
        '& .react-datepicker__day:hover, & .react-datepicker__day--in-range:hover, & .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, & .react-datepicker__quarter-text--in-range, & .react-datepicker__year-text--in-range)':
            {
                borderRadius: 50,
                backgroundColor: alpha(theme.palette.primary.main, 0.5),
            },
        '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover':
            {
                backgroundColor: alpha(theme.palette.primary.main, 0.5),
            },
        '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover':
            {
                backgroundColor: alpha(theme.palette.primary.main, 0.5),
            },
        '& .react-datepicker__day.react-datepicker__day--selected, & .react-datepicker__day--in-range':
            {
                borderRadius: 50,
                backgroundColor: theme.palette.primary.main,
                color: `${theme.palette.common.white} !important`,
            },
        '& .react-datepicker__day--keyboard-selected, & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected':
            {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
            },
        '& .react-datepicker__time-list-item': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& .react-datepicker__year-dropdown': {
            backgroundColor: theme.palette.common.white,
            width: '30%',
            top: 10,
        },
        '& .react-datepicker__year-option': {
            padding: '5px 0',
        },
        '& .react-datepicker__year-dropdown .react-datepicker__year-option:first-child:before': {
            backgroundColor: 'red',
            content: '',
            height: 50,
            width: 50,
        },
        '& .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled':
            {
                color: `${theme.palette.action.disabled} !important`,
            },
    },
    pBottom70: {
        '& .react-datepicker__month-container': {
            paddingBottom: 70,
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        zIndex: 101,
        margin: '-55px 20px 0 20px',
    },
});

export default function DateTimePicker(props: DateTimePickerProps) {
    const classes = useStyles();
    const hasChanged = useRef(false);
    const [date, setDate] = useState(props.date);
    const [dateRange, setDateRange] = useState<[null, null] | [Date, Date]>([null, null]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        setDefaultLocale(i18n.locale);
    }, []);

    function changeDate(date: Date, event: React.SyntheticEvent<any> | undefined) {
        setDate(date);
        hasChanged.current = hasChanged.current || !event;
    }

    function changeDateRange(date: [Date, Date]) {
        setDateRange(date);
        hasChanged.current = true;

        // @ts-ignore
        if (!date.includes(null)) {
            if (date[0].getTime() === date[1].getTime()) {
                props.onClose();
                props.onSubmit([
                    date[0],
                    new Date(date[1].getFullYear(), date[1].getMonth(), date[1].getDate(), 23, 59),
                ]);
            }
        }
    }

    function submit(e: any) {
        e.stopPropagation();
        e.preventDefault();
        // Some issue here with extra 1h time issue?? in API hours look -1, so we had to set here +1
        startDate?.setHours(1);
        endDate?.setHours(24);
        endDate?.setMinutes(59);
        hasChanged.current &&
            (props.isDateRange ? props.onSubmit([startDate, endDate]) : props.onSubmit(date));
        props.onClose();
        hasChanged.current = false;
    }

    function getMinTime() {
        let val = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0);
        if (props.futureTimeOnly) {
            const currentDate = new Date();
            if (
                date &&
                date.getDate() === currentDate.getDate() &&
                date.getMonth() === currentDate.getMonth()
            ) {
                val = currentDate;
            }
        }
        return val;
    }

    function closeModal() {
        setDate(new Date());
        hasChanged.current = false;
        props.onClose();
        setDateRange([null, null]);
    }

    return (
        <OutsideClickHandler
            onOutsideClick={(e) => {
                if ((e.target as Element)?.id !== 'date-time-picker') {
                    closeModal();
                }
            }}
        >
            <div className={`${classes.dt} ${props.showButtons && classes.pBottom70}`}>
                {props.isOpen && (
                    <>
                        {props.isDateRange ? (
                            <DatePicker
                                locale={i18n.locale}
                                inline
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(dates) => {
                                    changeDateRange(dates as [Date, Date]);
                                }}
                                onClickOutside={(e) => {
                                    if (!props.showButtons) {
                                        submit(e);
                                    }
                                }}
                                minDate={props.futureOnly ? new Date() : null}
                                maxDate={
                                    props.futureTimeOnly
                                        ? setHours(setMinutes(new Date(), 59), 23)
                                        : undefined
                                }
                            />
                        ) : (
                            <DatePicker
                                locale={i18n.locale}
                                inline
                                selected={date}
                                onChange={(date, event) => {
                                    changeDate(date as Date, event);
                                }}
                                onClickOutside={(e) => {
                                    if (!props.showButtons) {
                                        submit(e);
                                    }
                                }}
                                showTimeSelect
                                dateFormatCalendar="MMMM"
                                timeFormat="HH:mm"
                                dateFormat="MMMM d, yyyy h:mm"
                                showYearDropdown
                                timeIntervals={15}
                                minDate={props.futureOnly ? new Date() : null}
                                minTime={getMinTime()}
                                maxTime={
                                    props.futureTimeOnly
                                        ? setHours(setMinutes(new Date(), 59), 23)
                                        : undefined
                                }
                                //todayButton="today"
                            />
                        )}
                        {props.showButtons && (
                            <div className={classes.buttonContainer}>
                                <Button type="button" color="primary" onClick={closeModal}>
                                    {i18n.t('button.cancel')}
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={submit}
                                    disabled={!hasChanged.current}
                                >
                                    {i18n.t('common.select')}
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </OutsideClickHandler>
    );
}
