import { AppThunkAction } from 'store';
import { UiActions } from './actions';

export const actionCreators = {
    switchNavBar: (): AppThunkAction<UiActions> => (dispatch) => {
        dispatch({
            type: 'SWITCH_NAV_BAR',
        });
    },
};
