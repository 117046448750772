import i18n from 'i18n-js';
import * as english from './languages/en.json';
import * as german from './languages/de.json';

export const localizationConfig = () => {
    i18n.locale = i18n.currentLocale();
    i18n.defaultLocale = 'de';
    i18n.fallbacks = true;
    i18n.translations = {
        en: english.translations,
        de: german.translations,
    };
};
