import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';
import { Typography, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import NumberFormat from 'react-number-format';

import {
    BasicThreeDotsMenu,
    SectionTab,
    PopupConfirmation,
    UserRoleLabel,
    Text,
} from 'components/shared';
import { default as usersStore } from 'store/usersStore';
import logo from 'assets/user-logo.svg';
import {
    UserData,
    RestaurantUserEditModel,
    RestaurantUserCreateModel,
} from 'store/usersStore/reducer';
import { EditRestaurantUserForm } from 'components/users/components';
import { AddRestaurantUserForm } from 'components/users/components';
import { RestaurantContactPerson } from 'store/restaurantDetailsStore/reducer';
import agent from 'api/agent';
import { default as restaurantStore } from 'store/restaurantDetailsStore';
import { RestaurantData } from 'store/restaurantsStore/reducer';
import styles from '../styles';
import { useAppSelector } from 'index';

export default function ContactPersons() {
    const dispatch = useDispatch();
    const classes = styles();

    const authUserId = useAppSelector((state) => state.user.authUserId);
    const users = useAppSelector((state) => state.users.users);
    const restaurantInformation = useAppSelector(
        (state) => state.restaurantDetails.restaurantInformation
    );

    const [selected] = useState<string[]>([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
    const [userToEdit, setUserToEdit] = useState<UserData>({
        id: '',
        firstName: '',
        lastName: '',
        restaurants: [],
        email: '',
        phoneNumber: '',
        roles: [],
        isEnabled: false,
    });
    const [orderBy, setOrderBy] = useState('name');
    const [asc, setAsc] = useState(true);
    const orderNames: string[] = ['name', 'restaurant', 'email', 'phoneNumber', 'position', 'role'];
    const isGroupAction = useRef(false);

    useEffect(() => {
        if (!users || users.length === 0) {
            dispatch(
                usersStore.actionCreators.getRestaurantUsers(
                    {
                        name: '',
                        restaurant: '',
                        email: '',
                        phoneNumber: '',
                        position: '',
                        roles: [],
                    },
                    0,
                    100,
                    false,
                    ''
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleOrder(id: string) {
        if (id === orderBy) {
            dispatch(
                usersStore.actionCreators.getRestaurantUsers(
                    {
                        name: '',
                        restaurant: '',
                        email: '',
                        phoneNumber: '',
                        position: '',
                        roles: [],
                    },
                    0,
                    100,
                    !asc,
                    orderBy
                )
            );
            setAsc(!asc);
        } else {
            setOrderBy(id);
            dispatch(
                usersStore.actionCreators.getRestaurantUsers(
                    {
                        name: '',
                        restaurant: '',
                        email: '',
                        phoneNumber: '',
                        position: '',
                        roles: [],
                    },
                    0,
                    100,
                    asc,
                    id
                )
            );
        }
    }

    const handleEditModal = () => {
        setOpenEditModal(!openEditModal);
    };
    const handleCreateModal = () => {
        setOpenCreateModal(!openCreateModal);
    };

    const handleConfirmationPopup = (isGroup: boolean = false) => {
        isGroupAction.current = isGroup;
        setOpenConfirmationPopup(!openConfirmationPopup);
    };

    const handleEnableDisableUser = (enable: boolean, params: string[]) => {
        return new Promise((resolve) =>
            resolve(enable ? agent.Users.EnableUsers(params) : agent.Users.DisableUsers(params))
        );
    };

    const enableDisableUser = (enable: boolean) => {
        const params = isGroupAction.current ? selected : [userToEdit.id];
        handleEnableDisableUser(enable, params).then((res) => {
            dispatch(
                restaurantStore.actionCreators.updateContactPersons(
                    res as RestaurantContactPerson[]
                )
            );
        });
        handleConfirmationPopup();
    };

    function getRestaurantUsers() {
        const restaurantId = restaurantInformation.id;
        return users.filter((user) => {
            const isSameRestaurant = user.restaurants.find(
                (eachRestaurant) => eachRestaurant.restaurantId === restaurantId
            );
            if (user.id === authUserId || (isSameRestaurant && isSameRestaurant.isContactPerson)) {
                return true;
            }
            return false;
        });
    }

    function getThreeDotsItems(row: any) {
        const items = [
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => {
                    setUserToEdit(row);
                    handleEditModal();
                }}
            >
                <ListItemIcon>
                    <BorderColorIcon fontSize="small" color="disabled" />
                </ListItemIcon>
                <Typography variant="inherit">{i18n.t('common.edit')}</Typography>
            </div>,
        ];
        if (row.id !== authUserId) {
            items.push(
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => {
                        setUserToEdit(row);
                        handleConfirmationPopup();
                    }}
                >
                    <ListItemIcon>
                        {row.isEnabled ? (
                            <RemoveCircleOutlineIcon fontSize="small" color="disabled" />
                        ) : (
                            <SettingsBackupRestoreIcon fontSize="small" color="disabled" />
                        )}
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {row.isEnabled ? i18n.t('button.disable') : i18n.t('button.restore')}
                    </Typography>
                </div>
            );
        }
        return items;
    }

    return (
        <>
            <TableContainer className={classes.root}>
                <Table className={classes.contactsTable} aria-label="simple table">
                    <TableHead>
                        <TableRow key="table-head">
                            <TableCell style={{ width: '25%' }}>
                                <TableSortLabel
                                    active={orderBy === orderNames[0]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[0])}
                                >
                                    {i18n.t('form.userName')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === orderNames[1]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[1])}
                                >
                                    {i18n.t('form.email')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === orderNames[2]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[2])}
                                >
                                    {i18n.t('form.phoneNumber')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === orderNames[3]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[3])}
                                >
                                    {i18n.t('form.position')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell colSpan={2}>
                                <TableSortLabel
                                    active={orderBy === orderNames[4]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[4])}
                                >
                                    {i18n.t('form.role')}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={6} padding="none">
                                <SectionTab
                                    text={i18n.t('user.newContactPerson')}
                                    onClick={handleCreateModal}
                                />
                            </TableCell>
                        </TableRow>
                        {getRestaurantUsers().map((row) => (
                            <TableRow key={row.id} className={row.id}>
                                <TableCell
                                    scope="row"
                                    onClick={() => {
                                        setUserToEdit(row);
                                        handleEditModal();
                                    }}
                                    style={{ cursor: 'pointer' }}
                                    className={`${!row.isEnabled && classes.disabledRow}`}
                                >
                                    <ListItem alignItems="center">
                                        <ListItemAvatar>
                                            <Avatar alt="Remy Sharp" src={logo} />
                                        </ListItemAvatar>
                                        <Text
                                            text={`${row.firstName} ${row.lastName}`}
                                            maxLength={20}
                                        />
                                    </ListItem>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={`${!row.isEnabled && classes.disabledRow}`}
                                >
                                    <Text text={row.email} fontSize={14} maxLength={30} />
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={`${!row.isEnabled && classes.disabledRow}`}
                                >
                                    {row.phoneNumber ? (
                                        <NumberFormat
                                            format="+## (###) ### ## ##"
                                            displayType={'text'}
                                            value={row.phoneNumber}
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={`${!row.isEnabled && classes.disabledRow}`}
                                >
                                    {row.restaurants
                                        .filter(
                                            (x) => x.restaurantId === restaurantInformation.id
                                        )[0]
                                        ?.positions.join(', ') ?? '-'}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    className={`${!row.isEnabled && classes.disabledRow}`}
                                >
                                    <UserRoleLabel role={row.roles[0]?.name} />
                                </TableCell>
                                <TableCell align="right">
                                    <BasicThreeDotsMenu items={getThreeDotsItems(row)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={openEditModal}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
            >
                <DialogTitle id="form-dialog-title">{i18n.t('user.editUser')}</DialogTitle>
                <DialogContent>
                    <EditRestaurantUserForm
                        user={userToEdit}
                        restaurants={[restaurantInformation as unknown as RestaurantData]}
                        editRestaurantUser={(model: RestaurantUserEditModel) =>
                            dispatch(usersStore.actionCreators.editRestaurantUser(model))
                        }
                        cancel={handleEditModal}
                        close={handleEditModal}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openCreateModal}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
            >
                <DialogTitle id="form-dialog-title">{i18n.t('user.createNewUser')}</DialogTitle>
                <DialogContent>
                    <AddRestaurantUserForm
                        createUser={(owner: RestaurantUserCreateModel) =>
                            dispatch(usersStore.actionCreators.createRestaurantUser(owner))
                        }
                        restaurants={[restaurantInformation as unknown as RestaurantData]}
                        currentRestaurant={restaurantInformation as unknown as RestaurantData}
                        cancel={handleCreateModal}
                        isContactPerson
                        forContactPersons
                    />
                </DialogContent>
            </Dialog>
            <PopupConfirmation
                open={openConfirmationPopup}
                close={handleConfirmationPopup}
                title={
                    userToEdit.isEnabled
                        ? i18n.t('common.disableUser')
                        : i18n.t('common.restoreUser')
                }
                description={
                    userToEdit.isEnabled
                        ? i18n.t('confirmation.disableUser')
                        : i18n.t('confirmation.restoreUser')
                }
                activeBtn={
                    userToEdit.isEnabled ? i18n.t('common.disable') : i18n.t('common.enable')
                }
                action={() => enableDisableUser(!userToEdit.isEnabled)}
            />
        </>
    );
}
