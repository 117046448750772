import React from 'react';
import { useSelector } from 'react-redux';

import PhoneInput from 'react-phone-number-input';
import { alpha, createStyles, makeStyles } from '@material-ui/core';

import { ApplicationState } from 'store';

const styles = makeStyles((theme) =>
    createStyles({
        inputContainer: {
            backgroundColor: theme.palette.secondary.light,
            padding: '10px 12px 10px',
            margin: '0 0 28px 0',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
            '&:hover': {
                backgroundColor: alpha(theme.palette.secondary.main, 0.4),
            },
            '& .PhoneInputInput': {
                height: 30,
                border: '2px solid transparent',
                backgroundColor: 'rgba(232, 232, 232, 1)',
                padding: '0 12px',
                marginLeft: 10,
                fontSize: 16,
                lineHeight: 24,
            },
            '& .PhoneInputInput:hover': {
                borderColor: 'rgba(233, 233, 233, 1)',
            },
            '& .PhoneInputCountry': {
                borderRight: '1px solid rgba(214, 214, 214, 1)',
                paddingRight: 30,
            },
            '& .PhoneInputCountryIconImg': {
                borderRadius: 25,
            },
            '& input[type="tel"]:focus': {
                outline: 'none',
            },
        },
        invalidInput: {
            borderBottom: `2px solid ${theme.palette.error.main}`,
        },
        validInput: {},
    })
);

interface PhoneInputProps {
    placeholder: string;
    value: string;
    onChange: (val: string) => void;
    invalid?: boolean | string;
    width?: string;
    containerClass?: any;
    containerStyle?: any;
}

export default function CustomPhoneInput({
    placeholder,
    value,
    onChange,
    invalid,
    containerStyle,
    containerClass,
    width,
}: PhoneInputProps) {
    const browserData = useSelector((state: ApplicationState) => state.user.browserData);
    const classes = styles();

    return (
        <div
            style={{ width, ...containerStyle }}
            className={`${classes.inputContainer} ${containerClass} ${
                invalid && classes.invalidInput
            } `}
        >
            <PhoneInput
                //useNationalFormatForDefaultCountryValue={true}
                focusInputOnCountrySelection={true}
                defaultCountry={browserData?.country_code}
                international={true}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                limitMaxLength={true}
            />
        </div>
    );
}
