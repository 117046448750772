import React from 'react';

import * as yup from 'yup';
import i18n from 'i18n-js';
import { Button, TextField, DialogActions } from '@material-ui/core';
import { Formik, getIn } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import * as FormikFields from 'formik-material-ui';
import { parsePhoneNumber } from 'react-phone-number-input';

import { RestaurantData } from 'store/restaurantsStore/reducer';
import { RestaurantUserCreateModel } from 'store/usersStore/reducer';
import { regexHelper } from 'helpers/regexHelper';
import { trimLeftAndRight } from 'helpers/helperFunctions';
import PositionSelect from './PositionSelect';
import { CustomPhoneInput, Input } from 'components/shared';
import { Positions } from 'constants/enums';
import { images } from 'assets';
import { useAppSelector } from 'index';

interface AddOwnerProps {
    createUser: any;
    cancel: any;
    restaurants: RestaurantData[];
    currentRestaurant?: RestaurantData;
    isContactPerson?: boolean;
    isOwner?: boolean;
    forContactPersons?: boolean;
}

interface RestaurantUserModel {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    restaurant: RestaurantData;
    positions: string[];
    isContactPerson: boolean;
    isOwner: boolean;
}

export default function AddRestaurantUserForm(props: AddOwnerProps) {
    const browserData = useAppSelector((state) => state.user.browserData);

    const initalFormValues: RestaurantUserModel = {
        restaurant:
            props.currentRestaurant && props.restaurants.length === 1
                ? props.currentRestaurant
                : {
                      id: '',
                      name: '',
                      addressLine: '',
                      zip: '',
                      city: '',
                      logoUrl: '',
                  },
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        positions: [],
        isContactPerson: !!props.isContactPerson,
        isOwner: false,
    };

    const validationSchema = yup.object().shape({
        restaurant: yup.object().required('Required'),
        firstName: yup
            .string()
            .matches(
                regexHelper.onlyLetters,
                i18n.t('form.errors.lettersOnly', {
                    name: 'First Name',
                })
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'First Name',
                })
            ),
        lastName: yup
            .string()
            .matches(
                regexHelper.onlyLetters,
                i18n.t('form.errors.lettersOnly', {
                    name: 'Last Name',
                })
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'Last Name',
                })
            ),
        email: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Email',
                })
            )
            .email(i18n.t('form.errors.email'))
            .matches(regexHelper.email, i18n.t('form.errors.email')),
        phoneNumber: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Phone Number',
                })
            )
            .matches(regexHelper.phoneNumber, i18n.t('form.errors.phoneNumber')),
        positions: yup.array().of(
            yup.string().min(
                1,
                i18n.t('form.errors.required', {
                    name: 'Position',
                })
            )
        ),
    });

    const submitPromise = async (model: RestaurantUserModel) => {
        const owner: RestaurantUserCreateModel = {
            restaurantName: model.restaurant.name,
            restaurantId: model.restaurant.id,
            firstName: model.firstName,
            lastName: model.lastName,
            email: model.email,
            phoneNumber: model.phoneNumber,
            isContactPerson: model.isContactPerson,
            positions: model.positions,
            isOwner: model.positions.includes(Positions.MANAGER),
        };
        props.createUser(owner);
    };

    return (
        <Formik
            initialValues={initalFormValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async (values, { resetForm, setFieldValue }) => {
                if (values.restaurant?.id.length > 0) {
                    const parsedPhoneNumber = parsePhoneNumber(values.phoneNumber);
                    if (parsedPhoneNumber) {
                        if (!parsedPhoneNumber.country) {
                            const fixedPhoneNumber = values.phoneNumber.replace(
                                '+' + parsedPhoneNumber.countryCallingCode.toString(),
                                browserData?.country_calling_code
                            );
                            setFieldValue('phoneNumber', fixedPhoneNumber);
                            values.phoneNumber = fixedPhoneNumber;
                        }
                    }
                    submitPromise({
                        ...values,
                        firstName: trimLeftAndRight(values.firstName),
                        lastName: trimLeftAndRight(values.lastName),
                        email: trimLeftAndRight(values.email),
                    }).then(() => {
                        resetForm();
                        props.cancel();
                    });
                }
            }}
        >
            {({ submitForm, values, setFieldValue, errors, touched }) => {
                return (
                    <form style={{ overflow: 'auto' }}>
                        <Autocomplete
                            style={{ marginTop: -28 }}
                            id="restaurant"
                            options={props.restaurants}
                            getOptionLabel={(option) => option.name}
                            value={values.restaurant}
                            disabled={props.restaurants.length === 1}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e: object, value: any | null) => {
                                value && setFieldValue('restaurant', value);
                            }}
                            renderInput={(params) => (
                                <>
                                    <img
                                        style={{
                                            width: 23,
                                            height: 23,
                                            borderRadius: '50%',
                                            position: 'relative',
                                            left: 10,
                                            bottom: '-54px',
                                        }}
                                        src={
                                            values.restaurant.logoUrl
                                                ? values.restaurant.logoUrl
                                                : images.icons.logoNoText
                                        }
                                        alt="restaurant-logo"
                                    />
                                    <TextField
                                        autoFocus={true}
                                        {...params}
                                        name="restaurant"
                                        label={
                                            values?.restaurant?.addressLine
                                                ? values.restaurant.addressLine
                                                : 'Restaurant'
                                        }
                                        inputProps={{
                                            style: {
                                                padding: 10,
                                                marginLeft: 25,
                                            },
                                            ...params.inputProps,
                                        }}
                                        InputLabelProps={{
                                            shrink: !!values.restaurant,
                                        }}
                                        variant="filled"
                                        fullWidth
                                        error={
                                            (!values.restaurant ||
                                                values.restaurant?.name.length === 0) &&
                                            getIn(touched, 'restaurant')
                                        }
                                        helperText={
                                            values.restaurant?.name.length === 0 &&
                                            getIn(touched, 'restaurant')
                                                ? i18n.t('form.errors.required', {
                                                      name: 'Restaurant',
                                                  })
                                                : null
                                        }
                                    />
                                </>
                            )}
                        />
                        <Input
                            error={errors.firstName}
                            variant="filled"
                            fullWidth={true}
                            name={'firstName'}
                            type="text"
                            label={i18n.t('form.firstName')}
                            placeholder={i18n.t('form.firstName')}
                        />
                        <Input
                            error={errors.lastName}
                            variant="filled"
                            fullWidth={true}
                            name={'lastName'}
                            type="text"
                            label={i18n.t('form.lastName')}
                            placeholder={i18n.t('form.lastName')}
                        />
                        <Input
                            error={errors.email}
                            variant="filled"
                            fullWidth={true}
                            name={'email'}
                            type="text"
                            label={i18n.t('form.email')}
                            placeholder={i18n.t('form.email')}
                        />
                        <CustomPhoneInput
                            invalid={errors.phoneNumber}
                            placeholder={i18n.t('form.phoneNumber')}
                            value={values.phoneNumber}
                            onChange={(val) => setFieldValue('phoneNumber', val)}
                        />
                        <PositionSelect
                            values={values.positions}
                            error={!touched && !values.positions.length}
                            onChange={(val) => setFieldValue('positions', val)}
                        />
                        <div style={{ textAlign: 'start' }}>
                            <Input
                                component={FormikFields.CheckboxWithLabel}
                                disabled={props.forContactPersons}
                                type="checkbox"
                                variant="filled"
                                name={'isContactPerson'}
                                Label={{ label: i18n.t('form.contactPerson') }}
                                placeholder={i18n.t('form.contactPerson')}
                            />
                        </div>
                        <DialogActions>
                            <Button type="button" color="primary" onClick={props.cancel}>
                                {i18n.t('button.cancel')}
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={submitForm}
                            >
                                {i18n.t('button.create')}
                            </Button>
                        </DialogActions>
                    </form>
                );
            }}
        </Formik>
    );
}
