import React from 'react';

const DataDeletionInstructions = () => {
    return (
        <>
            <div className="post-content entry-content">
                <p>
                    James does not store your personal data; it is used only for login. According to
                    the Facebook&nbsp;Platform rules, we have to provide&nbsp;
                    <strong>User Data Deletion Callback URL</strong>&nbsp;or&nbsp;
                    <strong>Data Deletion Instructions URL</strong>. If you want to delete your
                    activities for the James, following these instructions:{' '}
                </p>

                <ol>
                    <li>Go to Your Facebook Account’s Setting &amp; Privacy. Click ” Setting “.</li>
                    <li>
                        Then, go to ” Apps and Websites” and you will see all of your Apps
                        activities.
                    </li>
                    <li>Select the option box for James</li>
                    <li>Click ” Remove” button.</li>
                </ol>
            </div>
        </>
    );
};

export default DataDeletionInstructions;
