import { Action, Reducer } from 'redux';

import { RestaurantData } from '../restaurantsStore/reducer';
import { RoleData } from '../rolesStore/reducer';
import { UsersAction } from './actions';

export interface UsersState {
    users: UserData[];
    count: number;
}

export interface UserData {
    id: string;
    firstName: string;
    lastName: string;
    restaurants: RestaurantUserData[];
    email: string;
    phoneNumber: string;
    roles: RoleData[];
    isEnabled: boolean;
}

export interface UserPagedModel {
    list: UserData[];
    count: number;
}

export interface AdminCreateModel {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

export interface RestaurantUserCreateModel {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    restaurantId: string;
    restaurantName: string;
    isContactPerson: boolean;
    positions: string[];
    isOwner: boolean;
}

export interface AdminEditModel {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

export interface RestaurantUserEditModel {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    restaurant: RestaurantData;
    positions: string[];
    isContactPerson: boolean;
    isOwner: boolean;
}

export interface RestaurantUserData {
    restaurantId: string;
    restaurantName: string;
    positions: string[];
    isContactPerson: boolean;
}

const initialState: UsersState = {
    users: [],
    count: 0,
};

export const reducer: Reducer<UsersState> = (
    state: UsersState | undefined,
    incomingAction: Action
): UsersState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as UsersAction;

    switch (action.type) {
        case 'USERS_GET_SUCCESS':
            return {
                ...state,
                users: action.users.list,
                count: action.users.count,
            };
        case 'USERS_CREATE_ADMIN_SUCCESS':
            return {
                ...state,
                users: state.users.concat(action.user),
            };
        case 'USERS_CREATE_RESTAURANT_USER_SUCCESS':
            const newUsers = [action.user, ...state.users];
            return {
                ...state,
                users: newUsers,
            };
        case 'USER_ENABLE_SUCCESS':
            return {
                ...state,
                users: state.users
                    .map((user) =>
                        action.users.some((x) => x.id === user.id)
                            ? action.users.find((x) => x.id === user.id)!
                            : user
                    )
                    .sort((a, b) => Number(b?.isEnabled) - Number(a?.isEnabled)),
            };
        case 'USER_DISABLE_SUCCESS':
            return {
                ...state,
                users: state.users
                    .map((user) =>
                        action.users.some((x) => x.id === user.id)
                            ? action.users.find((x) => x.id === user.id)!
                            : user
                    )
                    .sort((a, b) => Number(b?.isEnabled) - Number(a?.isEnabled)),
            };
        case 'USERS_EDIT_ADMIN_SUCCESS':
            return {
                ...state,
                users: state.users.map((user) => (user.id === action.user.id ? action.user : user)),
            };
        case 'USERS_EDIT_RESTAURANT_USER_SUCCESS':
            return {
                ...state,
                users: state.users.map((user) => (user.id === action.user.id ? action.user : user)),
            };
        case 'FROM_USERS_TO_RESTAURANT_USERS_SUCCESS':
            return {
                ...state,
                users: [...state.users, ...action.data],
            };
        default:
            return state;
    }
};
