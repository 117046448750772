import React from 'react';
import { useHistory } from 'react-router-dom';

import i18n from 'i18n-js';

import logo from 'assets/Img-Welcome.svg';
import { Roles } from 'constants/enums';
import { useAppSelector } from 'index';

export default function Home() {
    const user = useAppSelector((state) => state.user);
    const history = useHistory();
    if (!!user?.role) {
        switch (user.role) {
            case Roles.ADMIN:
                history.push('/restaurants');
                break;
            case Roles.OWNER:
                history.push('/orders');
                break;
            case Roles.EMPLOYEE:
                history.push('/orders');
                break;
        }
    }

    return (
        <div className="App">
            <header className="App-header">
                <h1>{i18n.t('main.welcomeText')}</h1>
                <img src={logo} className="App-logo" alt="logo" />
            </header>
        </div>
    );
}
