import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import i18n from 'i18n-js';

import { Input } from 'components/shared';
import { ApplicationState } from 'store';
import styles from './styles';
import { default as restaurantStore } from 'store/restaurantDetailsStore';
import { images } from '../../../assets';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { regexHelper } from '../../../helpers/regexHelper';
import { trimLeftAndRight } from '../../../helpers/helperFunctions';

const iban = require('iban');

interface PaymentDetailsProps {
    paymentDetailsRef: React.Ref<FormikProps<any>>;
    onFormChange: (changed: boolean) => void;
}

export default function PaymentDetails({ paymentDetailsRef, onFormChange }: PaymentDetailsProps) {
    const classes = styles();

    const restaurantInformation = useSelector(
        (state: ApplicationState) => state.restaurantDetails.restaurantInformation
    );
    const paymentDetails = useSelector(
        (state: ApplicationState) => state.restaurantDetails.restaurantInformation.paymentDetails
    );

    const currencies = ['CHF', 'USD', 'EUR'];

    const dispatch = useDispatch();
    const setInitialValues = () =>
        !!paymentDetails.accountNumber
            ? { ...paymentDetails }
            : {
                  firmName: '',
                  firstName: '',
                  lastName: '',
                  address: '',
                  zip: '',
                  city: '',
                  accountNumber: '',
                  iban: '',
                  vat: '',
                  tax: 0,
                  currency: '',
                  thanksMessage: '',
              };

    const validationSchema = yup.object().shape({
        firmName: yup.string().required(
            i18n.t('form.errors.required', {
                name: i18n.t('paymentDetails.firmName'),
            })
        ),
        firstName: yup.string().required(
            i18n.t('form.errors.required', {
                name: i18n.t('paymentDetails.firstName'),
            })
        ),
        lastName: yup.string().required(
            i18n.t('form.errors.required', {
                name: i18n.t('paymentDetails.lastName'),
            })
        ),
        address: yup.string().required(
            i18n.t('form.errors.required', {
                name: i18n.t('paymentDetails.address'),
            })
        ),
        zip: yup
            .string()
            .max(10)
            .required(
                i18n.t('form.errors.required', {
                    name: i18n.t('paymentDetails.zip'),
                })
            ),
        city: yup.string().required(
            i18n.t('form.errors.required', {
                name: i18n.t('paymentDetails.city'),
            })
        ),
        accountNumber: yup
            .string()
            .matches(
                regexHelper.atLeastOneNumberAndOtherCharacters,
                i18n.t('form.errors.lettersAndNumbersOnly')
            )
            .required(
                i18n.t('form.errors.required', {
                    name: i18n.t('paymentDetails.accountNumber'),
                })
            ),
        iban: yup
            .string()
            .matches(
                regexHelper.atLeastOneNumberAndOtherCharacters,
                i18n.t('form.errors.lettersAndNumbersOnly')
            )
            .required(
                i18n.t('form.errors.required', {
                    name: i18n.t('paymentDetails.iban'),
                })
            ),
        vat: yup
            .string()
            .matches(
                regexHelper.atLeastOneNumberAndOtherCharacters,
                i18n.t('form.errors.lettersAndNumbersOnly')
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'VAT',
                })
            ),
        tax: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .required(
                i18n.t('form.errors.required', {
                    name: 'TAX',
                })
            )
            .min(0)
            .test('is-decimal', i18n.t('form.errors.twoDigitsAfterComma'), (val?: number) => {
                if (val) {
                    return regexHelper.twoDigisAfterComma.test(val.toString());
                }
                return true;
            }),
        currency: yup.string().required(
            i18n.t('form.errors.required', {
                name: i18n.t('paymentDetails.currency'),
            })
        ),
        thanksMessage: yup.string().required(
            i18n.t('form.errors.required', {
                name: i18n.t('paymentDetails.thanksMessage'),
            })
        ),
    });

    useEffect(() => {
        !paymentDetails.accountNumber &&
            dispatch(restaurantStore.actionCreators.getRestaurantInformation());
    }, []); // eslint-disable-line

    function getPercentageList() {
        const { hasTakeAway, hasHomeDelivery, hasOnSite } = restaurantInformation;
        const list = [];
        for (let i = 0; i < 3; i++) {
            let name,
                image,
                percentage,
                marginTop = 48,
                active = true;
            switch (i) {
                case 0:
                    name = i18n.t('form.Vorort');
                    percentage = restaurantInformation.percentageForVorort;
                    image = images.icons.chartVorort;
                    marginTop = 30;
                    active = hasOnSite;
                    break;
                case 1:
                    name = i18n.t('form.takeAway');
                    percentage = restaurantInformation.percentageForTakeAway;
                    image = images.icons.chartTakeAway;
                    active = hasTakeAway;
                    break;
                case 2:
                    name = i18n.t('form.homeDelivery');
                    percentage = restaurantInformation.percentageForHomeDelivery;
                    image = images.icons.chartHomeDelivery;
                    active = hasHomeDelivery;
                    break;
            }
            if (!active) {
                continue;
            }
            list.push(
                <div className={`${classes.flexRow}`} style={{ marginTop }}>
                    <img src={image} className={classes.percentageIcon} alt="percentage" />
                    <div className={`${classes.flexColumn} ${classes.mLeft20}`}>
                        <span className={classes.percentageText}>{name}</span>
                        <div>
                            <span className={classes.percentageSymbol}>{percentage} %</span>
                            <span className={classes.cantEdit}>(cant edit)</span>
                        </div>
                    </div>
                    <img src={images.icons.warning} className={classes.warningIcon} alt="warning" />
                </div>
            );
        }
        return list;
    }

    return (
        <Formik
            innerRef={paymentDetailsRef}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={setInitialValues()}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm, setFieldError }) => {
                const isIbanIsValid = iban.isValid(values.iban);
                if (!isIbanIsValid) {
                    return setFieldError('iban', i18n.t('warnings.invalidInformation'));
                }
                dispatch(
                    restaurantStore.actionCreators.updatePaymentDetails({
                        ...values,
                        firmName: trimLeftAndRight(values.firmName),
                        firstName: trimLeftAndRight(values.firstName),
                        lastName: trimLeftAndRight(values.lastName),
                        address: trimLeftAndRight(values.address),
                        accountNumber: trimLeftAndRight(values.accountNumber),
                        iban: trimLeftAndRight(values.iban),
                        vat: trimLeftAndRight(values.vat),
                        thanksMessage: trimLeftAndRight(values.thanksMessage),
                    })
                );
            }}
        >
            {({ values, setFieldValue, errors, initialValues }) => (
                <form
                    onChange={() => {
                        setTimeout(() => {
                            onFormChange(
                                // @ts-ignore
                                JSON.stringify(paymentDetailsRef.current?.values) !==
                                    // @ts-ignore
                                    JSON.stringify(paymentDetailsRef.current?.initialValues)
                            );
                        }, 250);
                    }}
                >
                    <div className={classes.paymentDetailsFormContainer}>
                        <div className={classes.flexRowOnly}>
                            <div className={classes.formInputColumn}>
                                <h6 className={`${classes.sectionTitle} ${classes.mBot20}`}>
                                    {i18n.t('paymentDetails.personalInformation').toUpperCase()}
                                </h6>
                                <Input
                                    error={errors.firmName}
                                    className={classes.formInput}
                                    placeholder={i18n.t('paymentDetails.firmName')}
                                    name="firmName"
                                    type="text"
                                    label={i18n.t('paymentDetails.firmName')}
                                    variant="filled"
                                />
                                <Input
                                    error={errors.firstName}
                                    className={classes.formInput}
                                    placeholder={i18n.t('paymentDetails.firstName')}
                                    name="firstName"
                                    type="text"
                                    label={i18n.t('paymentDetails.firstName')}
                                    variant="filled"
                                />
                                <Input
                                    error={errors.lastName}
                                    className={classes.formInput}
                                    placeholder={i18n.t('paymentDetails.lastName')}
                                    name="lastName"
                                    type="text"
                                    label={i18n.t('paymentDetails.lastName')}
                                    variant="filled"
                                />
                                <Input
                                    error={errors.address}
                                    className={classes.formInput}
                                    placeholder={i18n.t('paymentDetails.address')}
                                    name="address"
                                    type="text"
                                    label={i18n.t('paymentDetails.address')}
                                    variant="filled"
                                />
                                <div className={classes.flexRowSpaceBetween}>
                                    <Input
                                        error={errors.zip}
                                        className={classes.halfInput}
                                        placeholder={i18n.t('paymentDetails.zip')}
                                        name="zip"
                                        type="text"
                                        label={i18n.t('paymentDetails.zip')}
                                        variant="filled"
                                    />
                                    <Input
                                        error={errors.city}
                                        className={classes.halfInput}
                                        placeholder={i18n.t('paymentDetails.city')}
                                        name="city"
                                        type="text"
                                        label={i18n.t('paymentDetails.city')}
                                        variant="filled"
                                    />
                                </div>
                            </div>
                            <div className={`${classes.formInputColumn} ${classes.mLeft20}`}>
                                <h6 className={`${classes.sectionTitle} ${classes.mBot20}`}>
                                    {i18n.t('paymentDetails.paymentInformation').toUpperCase()}
                                </h6>
                                <Input
                                    error={errors.accountNumber}
                                    className={classes.formInput}
                                    placeholder={i18n.t('paymentDetails.accountNumber')}
                                    name="accountNumber"
                                    type="text"
                                    label={i18n.t('paymentDetails.accountNumber')}
                                    variant="filled"
                                />
                                <Input
                                    error={errors.iban}
                                    className={classes.formInput}
                                    placeholder={i18n.t('paymentDetails.iban')}
                                    name="iban"
                                    type="text"
                                    label={i18n.t('paymentDetails.iban')}
                                    variant="filled"
                                />
                                <h6
                                    className={`${classes.sectionTitle} ${classes.mBot20} ${classes.mTop0}`}
                                >
                                    {i18n.t('paymentDetails.recieptDetails').toUpperCase()}
                                </h6>
                                <Input
                                    error={errors.vat}
                                    className={classes.formInput}
                                    placeholder={i18n.t('paymentDetails.vat')}
                                    name="vat"
                                    type="text"
                                    label={i18n.t('paymentDetails.vat')}
                                    variant="filled"
                                />
                                <div className={classes.flexRowSpaceBetween}>
                                    <Input
                                        error={errors.tax}
                                        className={classes.halfInput}
                                        placeholder={i18n.t('paymentDetails.taxInPercents')}
                                        name="tax"
                                        type="text"
                                        label={i18n.t('paymentDetails.taxInPercents')}
                                        variant="filled"
                                    />
                                    <Autocomplete
                                        options={currencies}
                                        className={classes.halfInput}
                                        getOptionLabel={(option) => option}
                                        value={values.currency}
                                        getOptionSelected={(option, value) => option === value}
                                        onChange={(e: object, value: any | null) => {
                                            setFieldValue('currency', value);
                                        }}
                                        renderInput={(params) => (
                                            <>
                                                <TextField
                                                    {...params}
                                                    name="currency"
                                                    label={i18n.t('paymentDetails.currency')}
                                                    variant="filled"
                                                    fullWidth
                                                    error={!!errors.currency}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <Input
                                    error={errors.thanksMessage}
                                    className={classes.formInput}
                                    placeholder={i18n.t('paymentDetails.thanksMessage')}
                                    name="thanksMessage"
                                    type="text"
                                    label={i18n.t('paymentDetails.thanksMessage')}
                                    variant="filled"
                                />
                            </div>
                            <div className={`${classes.formInputColumn} ${classes.mLeft20}`}>
                                <h6 className={`${classes.sectionTitle} ${classes.mBot20} `}>
                                    {i18n.t('restaurant.percentageForJames').toUpperCase()}
                                </h6>
                                <div>
                                    <div className={classes.mBot20}>{getPercentageList()}</div>
                                    <span className={classes.percentageText}>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua.
                                        <br />
                                        <br /> Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
}
