import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core';
import commonStyles from '../../../config/commonStyles';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        formInput: {
            marginBottom: '28px',
            width: '100%',
        },
        halfInput: {
            marginBottom: '28px',
            width: '47%',
        },
        formInputColumn: {
            width: 348,
        },
        paymentDetailsFormContainer: {
            padding: '20px 30px',
        },
        percentageIcon: {
            width: '32px',
            height: '32px',
        },
        percentageText: {
            fontSize: '12px',
            fontWeight: '400',
            color: alpha(theme.palette.common.black, 0.54),
        },
        percentageSymbol: {
            fontSize: '16px',
            color: theme.palette.common.black,
        },
        cantEdit: {
            fontSize: '12px',
            color: alpha(theme.palette.common.black, 0.4),
            fontWeight: '600',
            marginLeft: '5px',
        },
        warningIcon: {
            position: 'absolute',
            right: '40px',
            width: '20px',
            height: '20px',
        },
    })
);

export default styles;
