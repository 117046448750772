import { UserFilterModel, UsersToRestaurantUserModel } from 'api/models';
import agent, { prepareHeaders, identityUrl, requests } from 'api/agent';
import {
    AdminCreateModel,
    RestaurantUserCreateModel,
    AdminEditModel,
    RestaurantUserEditModel,
} from 'store/usersStore/reducer';

export const Users = {
    GetList: (
        filterModel: UserFilterModel | null = null,
        page?: number,
        limit?: number,
        asc?: boolean,
        orderBy?: string
    ) =>
        agent.requests.post(
            `/User/list?page=${page}&limit=${limit}&asc=${asc}&orderby=${orderBy}`,
            filterModel ?? undefined
        ),
    GetRestaurantUsersList: (
        filterModel: UserFilterModel | null = null,
        page?: number,
        limit?: number,
        asc?: boolean,
        orderBy?: string
    ) =>
        agent.requests.post(
            `/User/restaurantusers?page=${page}&limit=${limit}&asc=${asc}&orderby=${orderBy}`,
            filterModel ?? undefined
        ),
    CreateAdmin: (model: AdminCreateModel) =>
        agent.requests.put(`${identityUrl}/Account/RegisterAdmin`, model, prepareHeaders()),
    CreateRestaurantOwner: (model: RestaurantUserCreateModel) =>
        agent.requests.put(
            `${identityUrl}/Account/RegisterRestaurantOwner`,
            model,
            prepareHeaders()
        ),
    CreateRestaurantOwners: (model: RestaurantUserCreateModel[]) =>
        agent.requests.put(
            `${identityUrl}/Account/RegisterRestaurantOwner`,
            model,
            prepareHeaders()
        ),
    CreateRestaurantEmployee: (model: RestaurantUserCreateModel) =>
        agent.requests.put(
            `${identityUrl}/Account/RegisterRestaurantEmployee`,
            model,
            prepareHeaders()
        ),
    CreateRestaurantEmployees: (model: RestaurantUserCreateModel[]) =>
        agent.requests.put(
            `${identityUrl}/Account/RegisterRestaurantEmployee`,
            model,
            prepareHeaders()
        ),
    EditAdmin: (model: AdminEditModel) =>
        agent.requests.post(`${identityUrl}/Account/EditAdmin`, model),
    EditRestaurantUser: (model: RestaurantUserEditModel) =>
        agent.requests.post(`${identityUrl}/Account/EditRestaurantUser`, model),
    DisableUsers: (ids: string[]) =>
        agent.requests.post(`${identityUrl}/Account/DisableUsers`, ids),
    EnableUsers: (ids: string[]) => agent.requests.post(`${identityUrl}/Account/EnableUsers`, ids),
    FromUsersToRestaurantOwners: (model: UsersToRestaurantUserModel) =>
        agent.requests.post(`${identityUrl}/Account/RegisterRestaurantOwnersFromUsers`, model),
    FromUsersToRestaurantEmployees: (model: UsersToRestaurantUserModel) =>
        agent.requests.post(`${identityUrl}/Account/RegisterRestaurantEmployeesFromUsers`, model),
    GetResponsibleUsers: () => requests.get('/User/responsibleusers'),
};
