import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import NumberFormat from 'react-number-format';
import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import clsx from 'clsx';

import { default as usersStore } from 'store/usersStore';
import { default as rolesStore } from 'store/rolesStore';
import logo from 'assets/user-logo.svg';
import { UserFilterModel } from 'api/models';
import EditAdminForm from './EditAdminForm';
import { UserData, AdminEditModel } from 'store/usersStore/reducer';
import {
    RolesFilter,
    SearchInput,
    BasicThreeDotsMenu,
    PopupConfirmation,
    NothingFoundMessage,
    TableToolbar,
    UserRoleLabel,
    Text,
} from 'components/shared';
import AddUserComponent from './AddUserComponent';
import { pagingOptions } from 'constants/arrays';
import { images } from 'assets';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';

export default function UsersTable() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const users = useAppSelector((state) => state.users);
    const roles = useAppSelector((state) => state.roles.roles);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pagingOptions[0]);
    const [orderBy, setOrderBy] = useState('name');
    const [asc, setAsc] = useState(true);
    const [showDisablePopup, setShowDisablePopup] = useState(false);
    const defaultFilter: UserFilterModel = {
        name: '',
        restaurant: '',
        email: '',
        phoneNumber: '',
        position: '',
        roles: [],
    };
    const [filter, setFilter] = useState(defaultFilter);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const tableRef = useRef<HTMLTableElement | null>(null);
    const [userToEdit, setUserToEdit] = useState<UserData>({
        id: '',
        firstName: '',
        lastName: '',
        restaurants: [],
        email: '',
        phoneNumber: '',
        roles: [],
        isEnabled: false,
    });
    const orderNames: string[] = [
        'name',
        'restaurant',
        'email',
        'phoneNumber',
        'position',
        'roles',
    ];

    function handleChangePage(event: unknown, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        let newRowsPerPage = +event.target.value;
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    }

    function handleChange(event: React.ChangeEvent<{ value: string[] }>) {
        if (event.target.value.includes('selectAll')) {
            if (filter.roles.length !== roles.length) {
                setFilter({ ...filter, roles: roles.map((x) => x.id) });
            } else {
                setFilter({ ...filter, roles: [] });
            }
        } else {
            event.target.value && setFilter({ ...filter, roles: event.target.value as string[] });
        }
    }

    function handleOrder(id: string) {
        if (orderBy === id) {
            setAsc(!asc);
        } else {
            setOrderBy(id);
        }
    }

    function handleFilterChange() {
        setPage(0);
    }

    function enableDisableUser(enable: boolean) {
        enable
            ? dispatch(usersStore.actionCreators.disableUser([userToEdit.id]))
            : dispatch(usersStore.actionCreators.enableUser([userToEdit.id]));
        setShowDisablePopup(false);
    }

    function clearFilterField(value: keyof UserFilterModel) {
        if (filter.hasOwnProperty(value)) {
            const newFilter = { ...filter, [value]: '' };
            setFilter(newFilter);
        }
    }

    function resetFilter() {
        setPage(0);
        setFilter(defaultFilter);
    }

    useEffect(() => {
        dispatch(usersStore.actionCreators.getUsers(filter, page, rowsPerPage, asc, orderBy));
        roles.length === 0 && dispatch(rolesStore.actionCreators.getRoles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, orderBy, asc, filter]);

    function scrollTableToTop() {
        if (tableRef?.current?.scrollIntoView) {
            tableRef.current.scrollIntoView();

            setTimeout(() => {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 250);
        }
    }

    return (
        <>
            <TableToolbar
                rightActions={[
                    <Button variant="outlined" color="primary" onClick={resetFilter}>
                        {i18n.t('form.resetSearch')}
                    </Button>,
                    <AddUserComponent />,
                ]}
            />
            <Paper className={classes.tableContainer}>
                <TableContainer id="container">
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-label="simple table"
                        ref={tableRef}
                    >
                        <TableHead>
                            <TableRow key="table-head">
                                <TableCell sortDirection={asc ? 'asc' : 'desc'}>
                                    <TableSortLabel
                                        active={orderBy === orderNames[0]}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(orderNames[0])}
                                    >
                                        <SearchInput
                                            value={filter.name}
                                            name={i18n.t('form.userName')}
                                            onChange={(value: string) =>
                                                setFilter({ ...filter, name: value })
                                            }
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('name')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={asc ? 'asc' : 'desc'}>
                                    <TableSortLabel
                                        active={orderBy === orderNames[1]}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(orderNames[1])}
                                    >
                                        <SearchInput
                                            value={filter.restaurant}
                                            name={i18n.t('form.restaurant')}
                                            onChange={(value: string) =>
                                                setFilter({ ...filter, restaurant: value })
                                            }
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('restaurant')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={asc ? 'asc' : 'desc'}>
                                    <TableSortLabel
                                        active={orderBy === orderNames[2]}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(orderNames[2])}
                                    >
                                        <SearchInput
                                            value={filter.email}
                                            name={i18n.t('form.email')}
                                            onChange={(value: string) =>
                                                setFilter({ ...filter, email: value })
                                            }
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('email')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={asc ? 'asc' : 'desc'}
                                    style={{
                                        paddingLeft: 8,
                                    }}
                                >
                                    <TableSortLabel
                                        active={orderBy === orderNames[3]}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(orderNames[3])}
                                    >
                                        <SearchInput
                                            value={filter.phoneNumber}
                                            name={i18n.t('form.phone')}
                                            onChange={(value: string) =>
                                                setFilter({ ...filter, phoneNumber: value })
                                            }
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('phoneNumber')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={asc ? 'asc' : 'desc'}>
                                    <TableSortLabel
                                        active={orderBy === orderNames[4]}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(orderNames[4])}
                                    >
                                        <SearchInput
                                            value={filter.position}
                                            name={i18n.t('form.position')}
                                            onChange={(value: string) =>
                                                setFilter({ ...filter, position: value })
                                            }
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('position')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    style={{ width: '145px' }}
                                    sortDirection={asc ? 'asc' : 'desc'}
                                >
                                    <TableSortLabel
                                        active={orderBy === orderNames[5]}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => {
                                            handleOrder(orderNames[5]);
                                        }}
                                    >
                                        <RolesFilter
                                            onChange={handleChange}
                                            onSubmit={handleFilterChange}
                                            selectedRoles={filter.roles}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.users.length > 0 ? (
                                users?.users.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        className={clsx(`${row.id} ${classes.cursorHover}`, {
                                            [classes.disabledRow]: !row.isEnabled,
                                        })}
                                    >
                                        <TableCell
                                            scope="row"
                                            style={{ width: 220, maxWidth: 220 }}
                                        >
                                            <ListItem alignItems="center">
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp" src={logo} />
                                                </ListItemAvatar>
                                                <Text
                                                    text={`${row.firstName} ${row.lastName}`}
                                                    maxLength={15}
                                                    useClassic
                                                />
                                            </ListItem>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ width: 140, maxWidth: 140 }}
                                        >
                                            {row.restaurants?.length === 0
                                                ? '-'
                                                : row.restaurants?.map((rest, index) => (
                                                      <div key={`${rest.restaurantName}-${index}`}>
                                                          <Text
                                                              text={rest.restaurantName}
                                                              fontSize={14}
                                                              useClassic
                                                          />
                                                      </div>
                                                  ))}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ width: 160, maxWidth: 160 }}
                                        >
                                            <Text
                                                text={row.email}
                                                fontSize={14}
                                                maxLength={20}
                                                useClassic
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.phoneNumber ? (
                                                <NumberFormat
                                                    format="+## (###) ### ## ##"
                                                    displayType={'text'}
                                                    value={row.phoneNumber}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.restaurants?.filter((x) => !!x.positions?.length)
                                                .length > 0
                                                ? row.restaurants?.map(
                                                      (rest) =>
                                                          !!rest.positions?.length && (
                                                              <div key={rest.restaurantId}>
                                                                  {rest.positions.join(', ')}
                                                              </div>
                                                          )
                                                  )
                                                : '-'}
                                        </TableCell>
                                        <TableCell align="left">
                                            <UserRoleLabel role={row.roles[0]?.name} />
                                        </TableCell>
                                        <TableCell align="left">
                                            <BasicThreeDotsMenu
                                                items={[
                                                    <div
                                                        className={
                                                            classes.threeDotsMenuItemContainer
                                                        }
                                                        onClick={() => {
                                                            setUserToEdit(row);
                                                            setShowEditDialog(true);
                                                        }}
                                                    >
                                                        <EditIcon
                                                            className={classes.threeDotsItemIcon}
                                                        />
                                                        <ListItemText
                                                            primary={i18n.t('common.edit')}
                                                        />
                                                    </div>,
                                                    row.isEnabled ? (
                                                        <div
                                                            className={
                                                                classes.threeDotsMenuItemContainer
                                                            }
                                                            onClick={() => {
                                                                setUserToEdit(row);
                                                                setShowDisablePopup(true);
                                                            }}
                                                        >
                                                            <RemoveCircleOutlineIcon
                                                                className={
                                                                    classes.threeDotsItemIcon
                                                                }
                                                            />
                                                            <ListItemText
                                                                primary={i18n.t('button.disable')}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                classes.threeDotsMenuItemContainer
                                                            }
                                                            onClick={() => {
                                                                setUserToEdit(row);
                                                                setShowDisablePopup(true);
                                                            }}
                                                        >
                                                            <SettingsBackupRestoreIcon
                                                                className={
                                                                    classes.threeDotsItemIcon
                                                                }
                                                            />
                                                            <ListItemText
                                                                primary={i18n.t('button.restore')}
                                                            />
                                                        </div>
                                                    ),
                                                ]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} style={{ height: 550 }}>
                                        <NothingFoundMessage />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pagingOptions}
                    component="div"
                    count={users.count}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={i18n.t('common.rowsPerPage')}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Dialog
                    open={showEditDialog}
                    aria-labelledby="form-dialog-title"
                    className={classes.dialog}
                >
                    <DialogTitle id="form-dialog-title">
                        {i18n.t('user.editUser')} <UserRoleLabel role={userToEdit.roles[0]?.name} />
                    </DialogTitle>
                    <DialogContent>
                        <EditAdminForm
                            user={userToEdit}
                            editAdmin={(model: AdminEditModel) =>
                                dispatch(usersStore.actionCreators.editAdmin(model))
                            }
                            cancel={() => setShowEditDialog(false)}
                            close={() => setShowEditDialog(false)}
                        />
                    </DialogContent>
                </Dialog>
                <PopupConfirmation
                    open={showDisablePopup}
                    close={() => setShowDisablePopup(false)}
                    action={() => enableDisableUser(userToEdit.isEnabled)}
                    title={userToEdit.isEnabled ? 'Disable' : 'Restore'}
                    description={
                        userToEdit.isEnabled
                            ? i18n.t('confirmation.disableUser')
                            : i18n.t('confirmation.restoreUser')
                    }
                    activeBtn={
                        userToEdit.isEnabled ? i18n.t('button.disable') : i18n.t('button.restore')
                    }
                />
            </Paper>
            <IconButton size="small" onClick={scrollTableToTop} className={classes.scrollArrow}>
                <img src={images.icons.topArrow} alt="top-arrow" />
            </IconButton>
        </>
    );
}
