export const pagingOptions = [13, 25, 50, 100];

export const statusColor = [
    '#5FCF55',
    '#E1BA2E',
    '#0073DE',
    '#B0B0B0',
    '#D55336',
    '#B0B0B0',
    '#592A46',
    '#5FCF55',
    '#E1BA2E',
];

export const statusText = [
    'New',
    'In Progress',
    'Ready',
    'Closed',
    'Unpaid',
    'Non',
    'Cancel',
    'OnDelivery',
    'Delivered',
];
