import { Action, Reducer } from 'redux';
import { UiActions } from './actions';

export interface UiState {
    navBarOpen: boolean;
}
const initialState: UiState = {
    navBarOpen: true,
};

export const reducer: Reducer<UiState> = (
    state: UiState | undefined,
    incomingAction: Action
): UiState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as UiActions;
    switch (action.type) {
        case 'SWITCH_NAV_BAR':
            return {
                ...state,
                navBarOpen: !state.navBarOpen,
            };
        default:
            return state;
    }
};
