import { makeStyles, alpha } from '@material-ui/core';
import commonStyles from 'config/commonStyles';
import { theme } from 'config/theme';

export const useStyles = makeStyles({
    ...commonStyles,
    root: {
        minHeight: '100%',
        width: '100%',
        '& .MuiTableCell-body.MuiTableCell-root:first-child': {
            paddingLeft: 25,
        },
        /*  '& .MuiTableContainer-root': {
            flexGrow: 0,
            maxHeight: 550,
        }, */
        '& .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded': {
            height: '93%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
        },
        '& th.MuiTableCell-root:not(:first-child)': {
            height: '10px',
            '& .MuiToolbar-root.MuiToolbar-regular.search-input.MuiToolbar-gutters': {
                justifyContent: 'start',
            },
        },
    },
    dots: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    toolbarTitle: {
        flex: '1 1 80%',
    },
    iconButton: {
        color: theme.palette.common.black,
        marginRight: '10px',
    },
    tableCellHeader: {
        fontSize: '14px',
        fontWeight: '400',
        color: alpha(theme.palette.common.black, 0.6),
    },
    tableCellHeaderContainer: {
        marginBottom: -30,
        marginTop: 3,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingBottom: 15,
    },
    listBox: {
        marginTop: 0,
        padding: 0,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 4,
    },
    paper: {
        marginTop: 0,
    },
    dropdownItem: {
        padding: 0,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
    },
    selectedDropdownItem: {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
    },
    divider: {
        marginRight: 20,
        backgroundColor: theme.palette.grey['200'],
        height: '30px',
        width: '1px',
    },
});
