import * as React from 'react';

import i18n from 'i18n-js';

import {
    Input,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    InputLabel,
    FormControl,
} from '@material-ui/core';

import { PositionsList } from 'constants/lists';
import { useStyles } from '../styles';
interface PositionSelectProps {
    error: boolean;
    values: string[];
    onChange: (data: string[]) => void;
}

export default function PositionSelect(props: PositionSelectProps) {
    const styles = useStyles();

    const MenuProps = {
        PaperProps: {
            style: {
                width: 250,
            },
        },
        MenuListProps: {
            style: {
                width: '100%',
            },
        },
    };

    return (
        <FormControl className={styles.formControl}>
            <InputLabel id="positions-label">{i18n.t('form.position')}</InputLabel>
            <Select
                labelId="positions-label"
                id="positions-label"
                className={styles.selectRoot}
                multiple
                placeholder={i18n.t('form.position')}
                value={props.values}
                onChange={(event) => {
                    props.onChange(event.target.value as string[]);
                }}
                input={<Input fullWidth error={props.error} />}
                renderValue={() => props.values.join(' / ')}
                MenuProps={MenuProps}
            >
                {PositionsList.map((item) => (
                    <MenuItem key={item.name} value={item.name}>
                        <Checkbox checked={props.values.indexOf(item.name) > -1} />
                        <ListItemText primary={item.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
