import { createStyles, makeStyles } from '@material-ui/core';

import commonStyles from 'config/commonStyles';

export const useStyles = makeStyles(() =>
    createStyles({
        ...commonStyles,
        dialog: {
            '& .MuiTextField-root': {
                marginBottom: 28,
            },
            '& .MuiDialogTitle-root > h2': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        },
        dropdown: {
            '& .MuiPopover-paper ': {
                top: '56px !important',
            },
        },
        menusModalRoot: {
            '& .MuiDialog-paperWidthLg': {
                width: 1115,
            },
            '& .MuiPaper-root.MuiDialog-paper': {
                height: '90%',
                maxHeight: 776,
                overflow: 'hidden',
            },
        },
    })
);
