import React from 'react';

import i18n from 'i18n-js';

import { images } from 'assets';
import NewOrdersCounter from './NewOrdersCounter';
import { NavbarList } from './NavbarList';
import NavbarListItem from './NavbarListItem';

interface RestaurantEmployeeNavbarProps {
    isOpen: boolean;
}

export default function RestaurantEmployeeNavbar(props: RestaurantEmployeeNavbarProps) {
    return (
        <NavbarList isOpen={props.isOpen}>
            <NavbarListItem
                isOpen={props.isOpen}
                path="/orders"
                icon={images.icons.penNavbar}
                title={i18n.t('navigationTabs.orders')}
            />
            <NewOrdersCounter isOpen={props.isOpen} />
            <NavbarListItem
                isOpen={props.isOpen}
                path="/menu"
                icon={images.icons.menu}
                title={i18n.t('navigationTabs.menu')}
            />
        </NavbarList>
    );
}
