import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Card,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Tooltip,
    Checkbox,
} from '@material-ui/core';
import i18n from 'i18n-js';
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';

import { images } from 'assets';
import { default as ordersStore } from 'store/ordersStore';
import { dateTimeIgnoreTimezone, formatDate, formatTime } from 'helpers/datetimeHelper';
import { OrderDishStatus, OrderUpdateStatus, Positions } from 'constants/enums';
import { NothingFoundMessage, DateTimePicker } from 'components/shared';
import { ApplicationState } from 'store';
import { pagingOptions } from 'constants/arrays';
import AddNewOrderModal from './AddNewOrderModal';
import agent from 'api/agent';
import OrdersListStatus from './OrdersListStatus';
import { theme } from 'config/theme';
import { helperFunctions } from 'helpers';
import { removeWhiteSpace, truncateString } from 'helpers/helperFunctions';
import { services } from 'constants/maps';
import { useStyles } from './styles';

interface PageModel {
    page: number;
    rowsPerPage: number;
}

interface OrdersListProps {
    onOrderSelected?: (orderIds: string[]) => void;
    selectedOrders?: string[];
}

export default function OrdersListTable(props: OrdersListProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const orders = useSelector((state: ApplicationState) => state.orders);
    const user = useSelector((state: ApplicationState) => state.user);
    const navBarOpen = useSelector((state: ApplicationState) => state.ui.navBarOpen);
    const currentPosition = useSelector(
        (state: ApplicationState) =>
            state.user.restaurants.find((rest) => rest.id === state.user.currentRestaurantId)
                ?.currentPosition || Positions.WAITER
    );
    const [paging, setPaging] = useState<PageModel>({ page: 0, rowsPerPage: pagingOptions[0] });
    const [showNewOrderModal, setShowNewOrderModal] = useState(false);
    const [readyDishes, setReadyDishes] = useState<{ [key: string]: string[] }>({});
    const [orderBy, setOrderBy] = useState('time');
    const [asc, setAsc] = useState(false);
    const [connection, setConnection] = useState<any>(null);
    const [rowHeight, setRowHeight] = useState(0);
    const [canCreateOrder, setCanCreateOrder] = useState(false);

    //const [dateRange, setDateRange] = useState<[null, null] | [Date, Date]>([null, null]);
    const [showPicker, setShowPicker] = useState(false);
    const dateRange = useRef<[string | undefined, string | undefined]>([undefined, undefined]);
    const userRestaurantId = useRef('');
    const tableRef = useRef<HTMLDivElement>(null);

    function selectNewOrder(id: string | undefined) {
        if (id && props.onOrderSelected && props.selectedOrders) {
            if (props.selectedOrders.includes(id)) {
                const newSelectedOrders = props?.selectedOrders?.filter((eachId) => eachId !== id);
                return props.onOrderSelected(newSelectedOrders);
            }
            const newSelectedOrders = [...props.selectedOrders, id];
            return props.onOrderSelected(newSelectedOrders);
        }
    }

    function getOrdersList(
        sortByAsc: boolean = asc,
        orderByValue: string = orderBy,
        searchString: string = orders.searchString,
        pageNumber: number = paging.page,
        limit: number = paging.rowsPerPage
    ) {
        dispatch(
            ordersStore.actionCreators.getOrdersList(
                searchString,
                dateRange.current[0],
                dateRange.current[1],
                pageNumber,
                limit,
                sortByAsc,
                orderByValue
            )
        );
    }

    function handleSearch() {
        getOrdersList(asc, orderBy, orders.searchString, 0);
        setPaging({ ...paging, page: 0 });
    }

    function handleOrder(id: string) {
        if (orderBy === id) {
            setAsc(!asc);
        } else {
            setOrderBy(id);
        }
    }

    function formatedDate(date: number) {
        const newDate = dateTimeIgnoreTimezone(date);
        return `${formatTime(newDate)} ${formatDate(newDate)}`;
    }

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) {
        event?.stopPropagation();
        setPaging({ ...paging, page: newPage });
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setPaging({ ...paging, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
    }

    function handleResetSearch() {
        dispatch(ordersStore.actionCreators.setShowSearch(false));
        dispatch(ordersStore.actionCreators.setSearchString(''));
        getOrdersList(asc, orderBy, '');
    }

    function submitDateRange(range: [Date, Date] | [null, null]) {
        const from = range[0]?.toISOString();
        const to = range[1]?.toISOString();
        dateRange.current = [from, to];
    }

    function prepareEmptyRows() {
        if (orders.orders.length && orders.orders.length < 13) {
            const rows = [];
            const neededRows = 13 - (orders.orders.length + 1);
            for (let i = 0; i < neededRows; i++) {
                rows.push(<TableRow style={{ height: rowHeight }} key={`empty-row-${i}`} />);
            }
            return rows;
        }
    }

    function handleMessage(message: { actionType: number; restaurantId: string }) {
        const { actionType, restaurantId } = message;
        if (restaurantId === userRestaurantId.current) {
            switch (actionType) {
                case OrderUpdateStatus.UpdateReadyDishes:
                    agent.Order.GetReadyDishes(
                        orders.searchString,
                        paging.page,
                        paging.rowsPerPage
                    ).then((result) => {
                        dispatch(ordersStore.actionCreators.updateOrdersReadyDishes(result));
                        setReadyDishes(result);
                    });
                    break;
                case OrderUpdateStatus.UpdateOrdersCount:
                    getOrdersList();
                    break;
            }
        }
    }

    function prepareTableBody() {
        let res = [];
        if (!!props.onOrderSelected) {
            res = orders.orders.map((order) => (
                <TableRow
                    key={order.id}
                    onClick={() => {
                        dispatch(
                            ordersStore.actionCreators.setOrderDetailsId(
                                orders.orderDetailsId !== order.id ? order.id : order.id + ' '
                            )
                        );
                    }}
                >
                    <TableCell
                        style={{
                            padding: '9px 9px 9px 16px',
                        }}
                        align={'left'}
                    >
                        <Checkbox
                            color={'primary'}
                            style={{ padding: 4, marginBottom: 2 }}
                            checked={props?.selectedOrders?.includes(order.id)}
                            onChange={() => selectNewOrder(order.id)}
                        />
                        {`№ ${order.orderNumber}`}
                    </TableCell>
                    <TableCell className={classes.noPadding} align={'left'}>
                        {formatedDate(order.createDate)}
                    </TableCell>
                    <TableCell className={classes.noPadding} align={'left'}>
                        <Tooltip
                            title={removeWhiteSpace(order.qrCodeName)}
                            placement="bottom-start"
                            disableHoverListener={removeWhiteSpace(order.qrCodeName).length < 20}
                        >
                            <span>
                                {truncateString(
                                    helperFunctions.removeWhiteSpace(order.qrCodeName),
                                    20
                                )}
                            </span>
                        </Tooltip>
                    </TableCell>
                    {!navBarOpen && (
                        <TableCell className={classes.noPadding} align={'center'}>
                            <img src={services.get(order.service)?.icon} alt="icon" height={22} />
                        </TableCell>
                    )}
                    <TableCell
                        style={{
                            padding: 9,
                        }}
                        align={'right'}
                    >
                        <OrdersListStatus
                            status={order.status}
                            showReady={
                                readyDishes[order.id]?.length >
                                order.orderItems.filter((x) => x.status === OrderDishStatus.Ready)
                                    .length
                            }
                        />
                    </TableCell>
                </TableRow>
            ));
        } else {
            res = orders.orders.map((order) => {
                const selected = order.id === orders.orderDetailsId;
                let selectedStyle, leftStyle, rightStyle;
                if (selected) {
                    selectedStyle = {
                        borderTop: `1px solid ${theme.palette.primary.main}`,
                        borderBottom: `1px solid ${theme.palette.primary.main}`,
                    };
                    leftStyle = {
                        borderLeft: `1px solid ${theme.palette.primary.main}`,
                    };
                    rightStyle = {
                        borderRight: `1px solid ${theme.palette.primary.main}`,
                    };
                }
                return (
                    <TableRow
                        style={{ cursor: 'pointer' }}
                        key={order.id}
                        onClick={() => {
                            dispatch(
                                ordersStore.actionCreators.setOrderDetailsId(
                                    orders.orderDetailsId !== order.id ? order.id : order.id + ' '
                                )
                            );
                        }}
                        className={selected ? classes.activeColor : ''}
                    >
                        <TableCell
                            style={{
                                padding: '9px 9px 9px 16px',
                                ...selectedStyle,
                                ...leftStyle,
                            }}
                            align={'left'}
                        >
                            {`№ ${order.orderNumber}`}
                        </TableCell>
                        <TableCell
                            style={selectedStyle}
                            className={classes.noPadding}
                            align={'left'}
                        >
                            {formatedDate(order.createDate)}
                        </TableCell>
                        <TableCell
                            style={selectedStyle}
                            className={classes.noPadding}
                            align={'left'}
                        >
                            <Tooltip
                                title={removeWhiteSpace(order.qrCodeName)}
                                placement="bottom-start"
                                disableHoverListener={
                                    removeWhiteSpace(order.qrCodeName).length < 20
                                }
                            >
                                <span>
                                    {truncateString(
                                        helperFunctions.removeWhiteSpace(order.qrCodeName),
                                        20
                                    )}
                                </span>
                            </Tooltip>
                        </TableCell>
                        {!navBarOpen && (
                            <TableCell
                                style={selectedStyle}
                                className={classes.noPadding}
                                align={'center'}
                            >
                                <img
                                    src={services.get(order.service)?.icon}
                                    alt="icon"
                                    height={22}
                                />
                            </TableCell>
                        )}
                        <TableCell
                            style={{
                                padding: 9,
                                ...selectedStyle,
                                ...rightStyle,
                            }}
                            align={'right'}
                        >
                            <OrdersListStatus
                                status={order.status}
                                showReady={
                                    readyDishes[order.id]?.length >
                                    order.orderItems.filter(
                                        (x) => x.status === OrderDishStatus.Ready
                                    ).length
                                }
                            />
                        </TableCell>
                    </TableRow>
                );
            });
        }

        return !!res.length ? (
            res
        ) : (
            <TableRow>
                <TableCell colSpan={4}>
                    <NothingFoundMessage
                        text={
                            orders.searchString
                                ? i18n.t('messages.weDidNotFindOrder', {
                                      order: orders.searchString,
                                  })
                                : undefined
                        }
                    />
                </TableCell>
            </TableRow>
        );
    }

    useEffect(() => {
        if (!user.currentRestaurantId) {
            agent.User.GetUserRestaurants().then((response) => {
                userRestaurantId.current = response.currentRestaurantId;
                dispatch({
                    type: 'USER_GET_RESTAURANTS_SUCCESS',
                    restaurants: response,
                });
            });
        } else {
            userRestaurantId.current = user.currentRestaurantId;
        }

        if (orders.searchString && orders.showSearch && orders.overdudedOrders.length > 0) {
            dispatch(ordersStore.actionCreators.getDetailedOverdudedOrders(orders.overdudedOrders));
        } else {
            getOrdersList();
        }
        /*
        setTimeout(() => (newOrders.current = orders.newOrders), TIMER_DURATION);
        //TRY TO DISABLE GET READY DISHES LOOP
        /* const timer = setInterval(() => {
            agent.Order.GetReadyDishes(orders.searchString, paging.page, paging.rowsPerPage).then(
                (result) => {
                    dispatch(orderActions.updateOrdersReadyDishes(result));
                    setReadyDishes(result);
                }
            );
            if (orders.newOrders !== newOrders.current) {
                newOrders.current = orders.newOrders;
                if (paging.page === 0) {
                    getOrdersList();
                }
            }
        }, TIMER_DURATION);
        return () => clearInterval(timer); */
    }, [orders.newOrders, dateRange.current]); // eslint-disable-line

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_ORDERS_HUB!, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (connection) {
            startConnection();
        }
        if (tableRef?.current?.offsetHeight) {
            setRowHeight(tableRef?.current?.offsetHeight / 13);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection, tableRef?.current?.offsetHeight]);

    useEffect(() => {
        setCanCreateOrder(
            currentPosition !== Positions.COOK && currentPosition !== Positions.MANAGER
        );
    }, [currentPosition]);

    function startConnection() {
        connection
            .start()
            .then(() => {
                console.log('SignalR Connected.');
                connection.on('UpdateOrders', handleMessage);
            })
            .catch((e: any) => {
                console.log('Connection failed: ', e);
                setTimeout(() => {
                    connection.state === 'Disconnected' && startConnection();
                }, 5000);
            });
    }

    useEffect(() => {
        if (orders.searchString && orders.showSearch && orders.overdudedOrders.length > 0) {
            dispatch(ordersStore.actionCreators.getDetailedOverdudedOrders(orders.overdudedOrders));
        } else {
            getOrdersList();
        }
    }, [paging.page, paging.rowsPerPage, orderBy, asc]); // eslint-disable-line

    return (
        <Card className={classes.ordersTable}>
            <Toolbar className={classes.searchBar}>
                {orders.showSearch ? (
                    <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="filled-adornment-password">
                            {helperFunctions.truncateLangString(i18n.t('form.searchWord'), 22)}
                        </InputLabel>
                        <Input
                            id="filled-adornment-password"
                            type="text"
                            onChange={(e: any) =>
                                dispatch(
                                    ordersStore.actionCreators.setSearchString(
                                        e.target.value.trim()
                                    )
                                )
                            }
                            onKeyDown={(e: any) => e.key === 'Enter' && handleSearch()}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleResetSearch}
                                        edge="end"
                                    >
                                        <img
                                            src={images.icons.removeCircle}
                                            alt="remove-circle"
                                            className={classes.resetSearchIcon}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                ) : (
                    <div className={classes.flexRowSpaceBetween}>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="h6" id="tableTitle" component="div">
                                {i18n.t('navigationTabs.orders')}
                            </Typography>
                            {!props.selectedOrders && (
                                <img
                                    src={images.icons.lens}
                                    className={classes.lensIcon}
                                    alt="lens-icon"
                                    onClick={() =>
                                        dispatch(ordersStore.actionCreators.setShowSearch(true))
                                    }
                                />
                            )}
                        </div>
                    </div>
                )}
                <div
                    className={classes.actionsContainer}
                    style={{
                        justifyContent: canCreateOrder ? 'space-between' : 'flex-end',
                    }}
                >
                    {canCreateOrder && !props.selectedOrders && (
                        <img
                            src={images.icons.circlePlusColored}
                            className={classes.addNewOrderIcon}
                            alt="add-icon"
                            onClick={() => setShowNewOrderModal(true)}
                        />
                    )}
                    {!props.selectedOrders && (
                        <img
                            id="date-time-picker"
                            src={images.icons.calendar}
                            alt="calendar-icon"
                            onClick={() => setShowPicker(!showPicker)}
                        />
                    )}
                    <DateTimePicker
                        futureTimeOnly
                        showButtons
                        isDateRange={true}
                        date={new Date(new Date().setUTCHours(0, 0, 0, 0))}
                        onSubmit={submitDateRange}
                        isOpen={showPicker}
                        onClose={() => setShowPicker(false)}
                    />
                </div>
            </Toolbar>

            <div className={classes.listContainer} ref={tableRef}>
                <TableContainer>
                    <Table stickyHeader aria-labelledby="tableTitle" aria-label="enhanced table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    key={'number'}
                                    align={'left'}
                                    className={classes.ordersTableHeader}
                                    sortDirection="asc"
                                    style={{ flexDirection: 'row' }}
                                >
                                    <div style={{ display: 'flex' }}>
                                        {!!props.onOrderSelected && (
                                            <Checkbox
                                                color={'primary'}
                                                style={{
                                                    padding: '4px 4px 4px 10px',
                                                    marginBottom: 2,
                                                }}
                                                checked={
                                                    orders.orders.length ===
                                                    props?.selectedOrders?.length
                                                }
                                                onChange={() => {
                                                    if (props?.onOrderSelected) {
                                                        if (
                                                            orders.orders.length ===
                                                            props?.selectedOrders?.length
                                                        ) {
                                                            return props.onOrderSelected([]);
                                                        }
                                                        props.onOrderSelected([
                                                            ...orders.orders.map(
                                                                (order) => order.id
                                                            ),
                                                        ]);
                                                    }
                                                }}
                                            />
                                        )}
                                        <TableSortLabel
                                            active={
                                                orders.orders.length > 0 && orderBy === 'number'
                                            }
                                            direction={asc ? 'asc' : 'desc'}
                                            onClick={() => handleOrder('number')}
                                        >
                                            {'№'}
                                        </TableSortLabel>
                                    </div>
                                </TableCell>
                                <TableCell
                                    key={'time'}
                                    align={'left'}
                                    className={classes.ordersTableHeader}
                                    sortDirection="asc"
                                >
                                    <TableSortLabel
                                        active={orders.orders.length > 0 && orderBy === 'time'}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder('time')}
                                    >
                                        {i18n.t('common.time')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    key={'qr'}
                                    align={'left'}
                                    className={classes.ordersTableHeader}
                                    sortDirection="asc"
                                >
                                    <TableSortLabel
                                        active={orders.orders.length > 0 && orderBy === 'qr'}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder('qr')}
                                    >
                                        {i18n.t('common.qr')}
                                    </TableSortLabel>
                                </TableCell>
                                {!navBarOpen && (
                                    <TableCell
                                        key={'service'}
                                        align={'center'}
                                        className={classes.ordersTableHeader}
                                        sortDirection="asc"
                                    >
                                        <TableSortLabel
                                            active={
                                                orders.orders.length > 0 && orderBy === 'service'
                                            }
                                            direction={asc ? 'asc' : 'desc'}
                                            onClick={() => handleOrder('service')}
                                        >
                                            {i18n.t('common.service')}
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                <TableCell
                                    key={'status'}
                                    align={'left'}
                                    className={classes.ordersTableHeader}
                                    sortDirection="asc"
                                    style={{
                                        paddingRight: 10,
                                    }}
                                >
                                    <TableSortLabel
                                        active={orders.orders.length > 0 && orderBy === 'status'}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder('status')}
                                    >
                                        {i18n.t('common.status')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ maxHeight: '50%', overflow: 'auto' }}>
                            {prepareTableBody()}
                            {prepareEmptyRows()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <TablePagination
                className={classes.paging}
                rowsPerPageOptions={pagingOptions}
                component="div"
                count={orders.count}
                rowsPerPage={paging.rowsPerPage}
                labelRowsPerPage={''}
                page={paging.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <AddNewOrderModal
                isOpen={showNewOrderModal}
                onClose={() => setShowNewOrderModal(false)}
            />
        </Card>
    );
}
