import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import i18n from 'i18n-js';

import images from 'assets/images';
import Text from './Text';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nothingFoundContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            '& h2': {
                color: theme.palette.primary.main,
            },
            '& div': {
                fontWeight: 700,
                fontSize: 16,
            },
        },
    })
);

export default function NothingFoundMessage(props: { text?: string | undefined }) {
    const classes = useStyles();
    return (
        <div className={classes.nothingFoundContainer}>
            <img src={images.brokenCup} alt="broken-cup.svg" />
            <h2>{i18n.t('messages.oops')}</h2>
            <Text text={props.text ? props.text : i18n.t('main.nothingToSee')} maxLength={30} />
        </div>
    );
}
