import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import clsx from 'clsx';
import i18n from 'i18n-js';

import { images } from 'assets';
import { useStyles } from './styles';
import { priceStyleHelper } from 'helpers/helperFunctions';

interface OrderPaymentProps {
    isPaid: boolean;
    totalAmount: number;
}

export default function OrderPayment(props: OrderPaymentProps) {
    const classes = useStyles();
    const text = props.isPaid ? i18n.t('orders.paid') : i18n.t('orders.unpaidBill');

    return (
        <div className={clsx(classes.orderPaymentRoot)}>
            <Accordion square expanded>
                <AccordionSummary
                    expandIcon={
                        <img
                            className={classes.paymentIcon}
                            src={images.creditCard}
                            alt="credit card"
                        />
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    <div>
                        <div>Preis ink MwSt:</div>
                        <div className={classes.amount}>
                            CHF {priceStyleHelper(props.totalAmount, true)}
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.paymentStatusContainer}>
                        <div
                            className={clsx(classes.paymentStatus, {
                                [classes.paymentStatusPaid]: props.isPaid,
                            })}
                        >
                            <span>{text}</span>
                            {props.isPaid && <img src={images.icons.download} alt="download" />}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
