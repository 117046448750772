import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core';
import i18n from 'i18n-js';

export interface PopupConfirmationProps {
    open: boolean;
    close: any;
    title: string;
    description: string;
    activeBtn: string;
    action: any;
    basicButton?: boolean;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& div.MuiDialog-paper': {
                padding: '18px 24px 0 24px',
                width: 468,
            },
            '& div': {
                margin: 0,
                padding: 0,
            },
        },
        dialogTitle: {
            '& h2': {
                fontWeight: 600,
            },
            display: 'flex',
            justifyContent: 'flex-start',
        },
        dialogContentText: {
            color: theme.palette.common.black,
            margin: '20px 0',
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 400,
            whiteSpace: 'pre-line',
        },
        dialogActions: {
            marginTop: '32px !important',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-start',
        },
        actionButton: {
            background: theme.palette.error.light,
            color: theme.palette.common.white,
            letterSpacing: 1.25,
            padding: '7px 16px',
            '&:hover': {
                backgroundColor: theme.palette.error.dark,
            },
        },
        cancelButton: {
            color: theme.palette.primary.main,
            letterSpacing: 1.25,
        },
    })
);

export default function PopupConfirmation(props: PopupConfirmationProps) {
    const classes = useStyles();

    function handleClose() {
        props.close();
    }

    function handleAction() {
        props.action();
        !props.basicButton && props.close();
    }

    function preventProp(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
    }

    return (
        <Dialog
            open={props.open}
            onClick={preventProp}
            className={classes.root}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                style={{ display: 'flex', justifyContent: 'flex-start' }}
                id="alert-dialog-title"
            >
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    className={classes.dialogContentText}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                    id="alert-dialog-description"
                >
                    {props.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ flexDirection: 'row-reverse', justifyContent: 'flex-start' }}>
                {props.basicButton ? (
                    <Button variant={'contained'} color={'primary'} onClick={handleAction}>
                        {props.activeBtn}
                    </Button>
                ) : (
                    <Button className={classes.actionButton} onClick={handleAction}>
                        {props.activeBtn}
                    </Button>
                )}
                <Button className={classes.cancelButton} onClick={handleClose}>
                    {i18n.t('common.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
