import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    TextField,
} from '@material-ui/core';
import i18n from 'i18n-js';
import * as yup from 'yup';

import { default as menusStore } from 'store/menusStore';
import { MenuType } from 'constants/enums';
import { images } from 'assets';
import { useStyles } from './styles';
import { Form, Formik } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Input, TimePickerModal, MultiSelectInputDropdown } from 'components/shared';
import { formatTime, dateTimeIgnoreTimezone } from 'helpers/datetimeHelper';
import { InitialTime } from '../MenusPage/components/AddMenuForm';
import { MenuDetailsData } from 'store/menuDetailsStore/reducer';
import { CopyMenuModel } from 'api/models';

interface CopyMenuProps {
    menu: MenuDetailsData;
    isOpen: boolean;
    onClose: () => void;
}

export default function MenuCopyModal(props: CopyMenuProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorElement, setAnchorElement] = useState<any>(null);
    const [showTimePicker, setShowTimePicker] = useState(false);
    const selectedDishesIds = useRef<string[]>([]);

    const initalFormValues = {
        name: 'copy of ' + props.menu.name,
        description: '',
        availableFrom: 0,
        availableTo: 0,
        type: MenuType.Special,
        discount: 0,
    };
    const initialTimeRange = useRef(
        getInitialTime(initalFormValues.availableFrom, initalFormValues.availableTo)
    );

    const validationSchema = yup.object().shape({
        name: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Name',
            })
        ),
        discount: yup.number().typeError(i18n.t('form.errors.onlyDigits')),
        availableFrom: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .required(
                i18n.t('form.errors.required', {
                    name: 'Discount',
                })
            )
            .test('not zero', i18n.t('form.errors.alreadyTaken'), (value: any) => value !== 0),
        availableTo: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .required(
                i18n.t('form.errors.required', {
                    name: 'Discount',
                })
            )
            .test('not zero', i18n.t('form.errors.alreadyTaken'), (value: any) => value !== 0),
    });

    const openHoursView = (from: number, to: number) =>
        from === 0 && to === 0
            ? `${formatTime(dateTimeIgnoreTimezone(from))} - ${formatTime(
                  dateTimeIgnoreTimezone(to)
              )}`
            : `${formatTime(new Date(from))} - ${formatTime(new Date(to))}`;

    function getInitialTime(timeStampFrom: number, timeStampTo: number) {
        const dateTimeFrom = new Date(timeStampFrom);
        const dateTimeTo = new Date(timeStampTo);
        return {
            fromHours: handleNumberStartZero(dateTimeFrom.getHours().toString()),
            fromMinutes: handleNumberStartZero(dateTimeFrom.getMinutes().toString()),
            toHours: handleNumberStartZero(dateTimeTo.getHours().toString()),
            toMinutes: handleNumberStartZero(dateTimeTo.getMinutes().toString()),
        } as InitialTime;
    }

    function handleNumberStartZero(number: string) {
        return number.length > 1 ? number : `0${number}`;
    }

    function handleTimeClick(e: any) {
        setAnchorElement(e.target.getBoundingClientRect());
        setShowTimePicker(!showTimePicker);
    }

    function getNewTime(newTime: string) {
        const [newHours, newMinutes] = newTime.split('.');
        return new Date(0).setHours(+newHours, +newMinutes);
    }

    function prepareOptions() {
        const options: { name: string; id: string }[] = [];
        props.menu.sections.forEach((section) => {
            section.dishes.forEach((dish) => options.push({ name: dish.name, id: dish.id }));
        });

        return options;
    }

    return (
        <Dialog open={props.isOpen} aria-labelledby="form-dialog-title" className={classes.dialog}>
            <DialogTitle id="form-dialog-title">
                {i18n.t('restaurant.copyAndMakeSpecialMenu')}
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initalFormValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        const model: CopyMenuModel = {
                            name: values.name,
                            description: values.description,
                            availableFrom: values.availableFrom,
                            availableTo: values.availableTo,
                            menuType: props.menu.type,
                            discount: values.discount,
                            sections: [],
                        };

                        selectedDishesIds.current.forEach((id) => {
                            const section = props.menu.sections.find((sec) =>
                                sec.dishes.some((dish) => dish.id === id)
                            )!;
                            const targetSection = model.sections.find(
                                (sec) => sec.id === section.id
                            );
                            if (targetSection) {
                                targetSection.dishes.push(id);
                            } else {
                                model.sections.push({
                                    id: section.id,
                                    dishes: [id],
                                });
                            }
                        });

                        dispatch(
                            menusStore.actionCreators.copyMenu(props.menu.id, model, () =>
                                props.onClose()
                            )
                        );
                    }}
                >
                    {({ errors, values, setFieldValue, submitForm }) => (
                        <Form className={classes.addMenuRoot}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <>
                                    <Input
                                        error={errors.name}
                                        variant="filled"
                                        fullWidth={true}
                                        name={`name`}
                                        type="text"
                                        label={i18n.t('form.name')}
                                        placeholder={i18n.t('form.name')}
                                    />
                                    <br />
                                    <div className={classes.flexRowSpaceBetween}>
                                        <TextField
                                            variant="filled"
                                            value={openHoursView(
                                                values.availableFrom,
                                                values.availableTo
                                            )}
                                            label={i18n.t('common.setTheTime')}
                                            placeholder={openHoursView(
                                                values.availableFrom,
                                                values.availableTo
                                            )}
                                            onClick={(e: any) => handleTimeClick(e)}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            className={classes.width48}
                                            error={!!errors.availableFrom || !!errors.availableTo}
                                        />
                                        <TimePickerModal
                                            initialFromHour={initialTimeRange.current.fromHours}
                                            initialFromMinute={initialTimeRange.current.fromMinutes}
                                            initialToHour={initialTimeRange.current.toHours}
                                            initialToMinute={initialTimeRange.current.toMinutes}
                                            isVisible={showTimePicker}
                                            onClose={(selectedHours) => {
                                                setFieldValue(
                                                    'availableFrom',
                                                    getNewTime(selectedHours.from)
                                                );
                                                setFieldValue(
                                                    'availableTo',
                                                    getNewTime(selectedHours.to)
                                                );
                                                setShowTimePicker(false);
                                            }}
                                            clickedElementRect={anchorElement}
                                        />
                                        <Input
                                            error={errors.discount}
                                            variant="filled"
                                            name="discount"
                                            type="text"
                                            label={i18n.t('form.discount') + ' %'}
                                            placeholder={i18n.t('form.specifyDiscount')}
                                            className={classes.width48}
                                        />
                                    </div>
                                    <MultiSelectInputDropdown
                                        options={prepareOptions()}
                                        inputLabel={i18n.t('common.items')}
                                        onChange={(values) =>
                                            (selectedDishesIds.current = values.map((x) => x.id))
                                        }
                                        trimLength={55}
                                        popupIcon={images.icons.triangleDown}
                                    />
                                    <Input
                                        error={errors.description}
                                        variant="filled"
                                        fullWidth={true}
                                        name={`description`}
                                        type="text"
                                        label={i18n.t('form.description')}
                                        placeholder={i18n.t('form.description')}
                                    />
                                </>
                            </MuiPickersUtilsProvider>
                            <DialogActions>
                                <Button type="button" color="primary" onClick={props.onClose}>
                                    {i18n.t('button.cancel')}
                                </Button>
                                <Button onClick={submitForm} variant="contained" color="primary">
                                    {i18n.t('button.create')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}
