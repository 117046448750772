import { createUserManager } from 'redux-oidc';
import { UserManagerSettings } from 'oidc-client';

const authUrl = process.env.REACT_APP_IDENTITY;
const client = process.env.NODE_ENV === 'development' ? 'jamesWebLocalhost' : 'jamesWeb';
const logoutUri = process.env.REACT_APP_LOGOUT_URL;

const userManagerConfig: UserManagerSettings = {
    client_id: client,
    client_secret: client,
    redirect_uri: window.location.origin + '/callback',
    response_type: 'token id_token',
    scope: 'openid profile jamesApi',
    post_logout_redirect_uri: logoutUri,
    authority: authUrl,
    automaticSilentRenew: true,
    loadUserInfo: true,
    silent_redirect_uri: window.location.origin + '/silentRenew',
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
