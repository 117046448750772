import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import clsx from 'clsx';

import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import { CardActions, ListItemIcon } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { RemoveCircleOutline } from '@material-ui/icons';
import PaletteIcon from '@material-ui/icons/Palette';

import coffee from 'assets/coffee-cup.svg';
import { images } from 'assets';
import { BasicThreeDotsMenu, Text, PopupConfirmation, ColorSelector } from 'components/shared';
import { MenuType, OrderDishStatus, Positions } from 'constants/enums';
import DishManagementDialog from './DishManagementDialog';
import { default as ordersStore } from 'store/ordersStore';
import { priceStyleHelper } from 'helpers/helperFunctions';
import { DishCreateModel } from 'api/models';
import menuDetailsStore from 'store/menuDetailsStore';
import { useAppSelector } from 'index';
import { theme } from 'config/theme';
import { useStyles } from '../styles';
import { DishData } from 'store/menuDetailsStore/reducer';
import { OrderDetailsItem } from 'store/ordersStore/reducer';

interface DishPreviewItemProps {
    id: string;
    dish: DishData;
    isModal?: boolean;
    isSelected?: boolean;
    isDailyMenuDish?: boolean;
    isDisabled?: boolean;
    setSelected?: (id: string) => void;
    dailyMenuId?: string;
    sectionId: string;
    color?: string;
}

enum DishManagementDialogState {
    Hide,
    Copy,
    Edit,
}

export default function DishPreviewItem(props: DishPreviewItemProps) {
    const { dish, dailyMenuId } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useAppSelector((state) => state.user);
    const currentRestaurant = user.restaurants?.find((x) => x.id === user.currentRestaurantId);
    const menu = useAppSelector((state) => state.menuDetails.menu);
    const orders = useAppSelector((state) => state.orders);
    //const initialOrderItems = useAppSelector((state) => state.orders.initialOrderItems);
    const allergens = useAppSelector((state) => state.ingredients.allergies);

    const [showConfirmation, setShowConfirmation] = useState(0);
    const [showEditDialog, setShowEditDialog] = useState<DishManagementDialogState>(
        DishManagementDialogState.Hide
    );
    const [showColorSelector, setShowColorSelector] = useState(false);
    const [color, setColor] = useState(props.color || dish.color || theme.palette.secondary.light);

    const targetItem = orders.itemsToAdd.find((x) => x.id === dish.id);
    /* const initialItem = dish.uniqueId
        ? initialOrderItems.find((x) => x.uniqueId === dish.uniqueId)
        : initialOrderItems.find((x) => x.id === dish.id); */
    function handleClick() {
        if (props.isModal && !!props.setSelected) {
            if (!props.isDisabled) {
                props.setSelected(dish.id);
                dispatch(ordersStore.actionCreators.setSelectedDish(dish.id, dailyMenuId));
            }
        } else {
            setShowEditDialog(DishManagementDialogState.Edit);
        }
    }

    const handleClose = (event?: React.MouseEvent<HTMLElement>) => {
        setShowEditDialog(DishManagementDialogState.Hide);
        event?.stopPropagation();
    };

    function itemAmount() {
        return targetItem?.amount || 0;
        /* console.log(targetItem);
        console.log(initialItem);
        if (targetItem && initialItem) {
            console.log(targetItem?.amount);
            console.log(initialItem?.amount);
            console.log(targetItem?.amount - initialItem?.amount);
            return targetItem?.amount - initialItem?.amount;
        } else {
            return 0;
        } */
    }

    function handleAmount(decrease: boolean = false) {
        const item: OrderDetailsItem = {
            price: props.dish.price,
            name: props.dish.name,
            id: props.dish.id,
            amount: 1,
            shortDescription: props.dish.shortDescription,
            orderId: orders.orderDetails.id,
            isReady: targetItem?.isReady ?? false,
            orderNumber: orders.orderDetails.orderNumber,
            comment: orders.orderDetails.comment,
            service: orders.orderDetails.service,
            allergens: props.dish.allergens,
            ingredients: props.dish.ingredients,
            status: targetItem?.status ?? OrderDishStatus.None,
            categoryId: '',
            categoryName: '',
            dailyMenuId: '',
            dishes: [],
        };
        //return dispatch(actionCreators.addItemToOrder(item, decrease ? -1 : 1));
        dispatch(ordersStore.actionCreators.addItemToEditedOrder(item, decrease));
    }

    function deleteDish() {
        dispatch(
            menuDetailsStore.actionCreators.deleteDish({
                dishId: props.id,
                sectionId: props.sectionId,
                menuId: menu.id,
            })
        );
    }

    function disableDish() {
        dispatch(
            menuDetailsStore.actionCreators.disableDish({
                dishId: props.id,
                sectionId: props.sectionId,
                menuId: menu.id,
            })
        );
    }

    function updateDish(model: DishCreateModel) {
        dispatch(menuDetailsStore.actionCreators.updateItem(model, props.dish.id, props.sectionId));
    }

    function copyDish(model: DishCreateModel) {
        dispatch(menuDetailsStore.actionCreators.addNewItem(model));
    }

    const getThreeDotItems = () => {
        const items = [
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => setShowEditDialog(DishManagementDialogState.Edit)}
            >
                <ListItemIcon>
                    <EditIcon fontSize="small" color="disabled" />
                </ListItemIcon>
                <Typography variant="inherit">{i18n.t('common.editItem')}</Typography>
            </div>,
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => setShowColorSelector(true)}
            >
                <ListItemIcon>
                    <PaletteIcon fontSize="small" color="disabled" />
                </ListItemIcon>
                <Typography variant="inherit">{i18n.t('common.changeColor')}</Typography>
            </div>,
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => setShowEditDialog(DishManagementDialogState.Copy)}
            >
                <ListItemIcon>
                    <img src={images.icons.copy} alt="copy" height={18} style={{ opacity: 0.4 }} />
                </ListItemIcon>
                <Typography variant="inherit">{i18n.t('common.copyItem')}</Typography>
            </div>,
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={(e) => {
                    setShowConfirmation(1);
                }}
            >
                <ListItemIcon>
                    <RemoveCircleOutline fontSize="small" color="disabled" />
                </ListItemIcon>
                <Typography variant="inherit">
                    {!dish.isDisabled ? i18n.t('common.disable') : i18n.t('common.enable')}
                </Typography>
            </div>,
        ];
        if (dish.isDisabled) {
            items.push(
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => setShowConfirmation(2)}
                >
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="disabled" />
                    </ListItemIcon>
                    <Typography variant="inherit">{i18n.t('common.deleteItem')}</Typography>
                </div>
            );
        }
        return items;
    };

    function prepareAmountCounter() {
        return !props.isDailyMenuDish && (itemAmount() > 0 || props.isSelected) ? (
            <div className={classes.itemAmountContainer}>
                <div className={classes.itemAmountComponent}>
                    <div className={classes.itemAmountAction} onClick={() => handleAmount(true)}>
                        -
                    </div>
                    <div className={classes.itemAmountCount}>{itemAmount()}</div>
                    <div className={classes.itemAmountAction} onClick={() => handleAmount(false)}>
                        +
                    </div>
                </div>
            </div>
        ) : null;
    }

    function getAllergenIcons(dishAllergens: string[]) {
        return dishAllergens.map((id: string) => {
            const allergen = allergens.find((each) => each.id === id);
            return (
                <img
                    key={allergen?.id || Math.random()}
                    src={allergen?.icon}
                    alt={allergen?.name}
                    style={{ maxHeight: '100%', marginLeft: 7 }}
                />
            );
        });
    }

    function changeColor(newColor: string) {
        setColor(newColor);
    }

    function saveColor() {
        dispatch(
            menuDetailsStore.actionCreators.setSectionDishColor(
                props.sectionId,
                props.dish.id,
                !!props.dailyMenuId,
                color
            )
        );
    }

    function resetColor() {
        setColor(props.dish.color || theme.palette.secondary.light);
        setShowColorSelector(false);
    }

    useEffect(
        () => setColor(props.color || dish.color || theme.palette.secondary.light),
        [props.color]
    );

    return (
        <div style={{ position: 'relative' }}>
            <Card
                elevation={0}
                className={clsx(classes.dishPreviewItemRoot, {
                    [classes.selected]: props.isSelected,
                })}
                /*  className={clsx(classes.root, {
                    [classes.selected]: props.isSelected,
                    [classes.forModal]: props.isModal,
                })} */
                style={{ opacity: dish.isDisabled ? 0.5 : 1 }}
                onClick={handleClick}
            >
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe">
                            <img
                                src={dish.photo ? dish.photo : coffee}
                                className={dish.photo ? classes.coverImg : ''}
                                alt="section_logo"
                            />
                        </Avatar>
                    }
                    action={
                        <>
                            {currentRestaurant?.currentPosition === Positions.MANAGER && (
                                <BasicThreeDotsMenu items={getThreeDotItems()} />
                            )}
                        </>
                    }
                    title={
                        <div className={classes.cardTitleContainer}>
                            <Text
                                text={dish.name}
                                className={classes.cardTitle}
                                fontSize={18}
                                maxLength={15}
                            />
                            <div className={classes.spicesContainer}>
                                {dish.isSpicy && (
                                    <IconButton aria-label="add to favorites">
                                        <img src={images.icons.chili} alt="chili-paper" />
                                    </IconButton>
                                )}
                                {dish.isVegetarian && (
                                    <IconButton aria-label="add to favorites">
                                        <img src={images.icons.leaf} alt="green-leaf" />
                                    </IconButton>
                                )}
                                {dish.isVegan && (
                                    <IconButton aria-label="add to favorites">
                                        <img src={images.icons.doubleLeaf} alt="chili-paper" />
                                    </IconButton>
                                )}
                                {dish.isHalal && (
                                    <IconButton aria-label="add to favorites">
                                        <img src={images.icons.halal} alt="green-leaf" />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    }
                />
                <PopupConfirmation
                    open={showConfirmation > 0}
                    close={() => setShowConfirmation(0)}
                    action={showConfirmation === 1 ? disableDish : deleteDish}
                    title={
                        showConfirmation === 1
                            ? !dish.isDisabled
                                ? i18n.t('common.disable')
                                : i18n.t('common.enable')
                            : i18n.t('common.delete')
                    }
                    description={
                        showConfirmation === 1
                            ? !dish.isDisabled
                                ? i18n.t('confirmation.disableItem')
                                : i18n.t('confirmation.enableItem')
                            : i18n.t('confirmation.deleteItem')
                    }
                    activeBtn={
                        showConfirmation === 1
                            ? !dish.isDisabled
                                ? i18n.t('common.disable')
                                : i18n.t('common.enable')
                            : i18n.t('common.delete')
                    }
                />
                <CardContent className={classes.cardContent}>
                    <Text text={dish.shortDescription} maxLength={150} />
                </CardContent>
                <CardActions disableSpacing className={classes.cardBottom}>
                    <div className={classes.spicesContainer}>
                        {getAllergenIcons(dish.allergens)}
                    </div>
                    <IconButton>
                        <div
                            className={classes.cardPrice}
                            style={{
                                visibility: menu.type !== MenuType.Daily ? 'visible' : 'hidden',
                            }}
                        >
                            CHF{' '}
                            <label
                                className={clsx(classes.labelPrice, {
                                    [classes.smallText]: dish.price.toString().length > 10,
                                })}
                            >
                                {priceStyleHelper(dish.price, true)}
                            </label>
                        </div>
                    </IconButton>
                </CardActions>
                <DishManagementDialog
                    dish={dish}
                    sections={menu.sections}
                    action={
                        showEditDialog === DishManagementDialogState.Edit ? updateDish : copyDish
                    }
                    isOpen={showEditDialog !== DishManagementDialogState.Hide}
                    onClose={handleClose}
                    disabled={currentRestaurant?.currentPosition !== Positions.MANAGER}
                    forCopy={showEditDialog === DishManagementDialogState.Copy}
                />
                <ColorSelector
                    isOpen={showColorSelector}
                    onClose={() => setShowColorSelector(false)}
                    value={color}
                    onChange={changeColor}
                    onSave={saveColor}
                    onCancel={resetColor}
                />
                <div style={{ height: 9, backgroundColor: color }}></div>
            </Card>
            {props.isModal && prepareAmountCounter()}
        </div>
    );
}
