import { connectRouter, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { reducer as oidcReducer } from 'redux-oidc';
import { User } from 'oidc-client';

import categoryStore from './categoryStore';
import { CategoryState } from './categoryStore/reducer';
import financeStore from './financeStore';
import { FinanceState } from './financeStore/reducer';
import globalStore from './globalStore';
import { GlobalState } from './globalStore/reducer';
import menuDetailsStore from './menuDetailsStore';
import { MenuDetailsState } from './menuDetailsStore/reducer';
import ingredientsStore from './ingredientsStore';
import { IngredientsState } from './ingredientsStore/reducer';
import menusStore from './menusStore';
import { MenusState } from './menusStore/reducer';
import ordersStore from './ordersStore';
import { OrdersState } from './ordersStore/reducer';
import restaurantDetailsStore from './restaurantDetailsStore';
import { RestaurantDetailsState } from './restaurantDetailsStore/reducer';
import restaurantsStore from './restaurantsStore';
import { RestaurantsState } from './restaurantsStore/reducer';
import rolesStore from './rolesStore';
import { RolesState } from './rolesStore/reducer';
import uiStore from './uiStore';
import { UiState } from './uiStore/reducer';
import usersStore from './usersStore';
import { UsersState } from './usersStore/reducer';
import userStore from './userStore';
import { UserState } from './userStore/reducer';

export const history = createBrowserHistory();

export interface OidcState {
    isLoadingUser: boolean;
    user: User;
}

// The top-level state object
export interface ApplicationState {
    oidic: OidcState;
    router: RouterState;
    user: UserState;
    restaurants: RestaurantsState;
    restaurantDetails: RestaurantDetailsState;
    users: UsersState;
    roles: RolesState;
    menus: MenusState;
    menuDetails: MenuDetailsState;
    category: CategoryState;
    orders: OrdersState;
    finance: FinanceState;
    global: GlobalState;
    ingredients: IngredientsState;
    ui: UiState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    router: connectRouter(history),
    oidc: oidcReducer,
    user: userStore.reducer,
    restaurants: restaurantsStore.reducer,
    restaurantDetails: restaurantDetailsStore.reducer,
    users: usersStore.reducer,
    roles: rolesStore.reducer,
    menus: menusStore.reducer,
    menuDetails: menuDetailsStore.reducer,
    category: categoryStore.reducer,
    orders: ordersStore.reducer,
    finance: financeStore.reducer,
    global: globalStore.reducer,
    ingredients: ingredientsStore.reducer,
    ui: uiStore.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState?: () => ApplicationState): void;
}
