import { HourData } from './components/AddWorkingHoursModal';
import i18n from 'i18n-js';

export function getInitialDates(
    date: HourData,
    getType: 'from' | 'to',
    dateType: 'hour' | 'minute'
) {
    if (date) {
        const { from, to } = date;
        let renderedDate = '';
        if (getType === 'from') {
            renderedDate = from;
        }
        if (getType === 'to') {
            renderedDate = to;
        }

        if (renderedDate.length > 0) {
            renderedDate =
                dateType === 'hour' ? renderedDate.substr(0, 2) : renderedDate.substr(3, 2);
            return renderedDate;
        }

        return '00';
    }

    return '00';
}

export function getFormattedHour(date: { from: string; to: string }, timeDividerString = '.') {
    let { from, to } = date;
    // from.toString().replace('.', ':');
    // to.toString().replace('.', ':');

    if (from === '0') {
        from = `00${timeDividerString}00`;
    } else {
        const fromSplitted = from.toString().split(timeDividerString);
        let hours = fromSplitted[0];
        hours = hours.length === 1 ? '0' + hours : hours;
        let minutes = fromSplitted.length > 1 ? fromSplitted[1] : '0';
        minutes = minutes.length === 1 ? minutes + '0' : minutes;
        from = `${hours}${timeDividerString}${minutes}`;
    }

    if (to === '0') {
        to =
            from === `00${timeDividerString}00`
                ? `23${timeDividerString}59`
                : `00${timeDividerString}00`;
    } else {
        const toSplitted = to.toString().split(timeDividerString);
        let hours = toSplitted[0];
        hours = hours.length === 1 ? '0' + hours : hours;
        let minutes = toSplitted.length > 1 ? toSplitted[1] : '0';
        minutes = minutes.length === 1 ? minutes + '0' : minutes;
        to = `${hours}${timeDividerString}${minutes}`;
    }

    return `${from} - ${to}`;
}

export function getDayList(openHours: any) {
    if (openHours) {
        const dayList = [];
        for (let i = 1; i <= Object.keys(openHours).length; i++) {
            let key = '0',
                name = '';
            switch (i) {
                case 1:
                    key = 'monday';
                    name = i18n.t('common.mon');
                    break;
                case 2:
                    key = 'tuesday';
                    name = i18n.t('common.tue');
                    break;
                case 3:
                    key = 'wednesday';
                    name = i18n.t('common.wed');
                    break;
                case 4:
                    key = 'thursday';
                    name = i18n.t('common.thu');
                    break;
                case 5:
                    key = 'friday';
                    name = i18n.t('common.fri');
                    break;
                case 6:
                    key = 'saturday';
                    name = i18n.t('common.sat');
                    break;
                case 7:
                    key = 'sunday';
                    name = i18n.t('common.sun');
                    break;
            }
            dayList.push({
                name,
                key,
                isClosed: openHours[key].isClosed,
                workingHours: openHours[key].workingHours,
            });
        }
        return dayList;
    }
    return [];
}

export function getOrderPreparationDurations() {
    const durations = [];
    for (let i = 1; i <= 10; i++) {
        durations.push({
            id: i,
            name: i * 30 + ` ${i18n.t('restaurant.minutes')}`,
            duration: i * 30,
        });
    }
    return durations;
}
