import agent, { prepareHeaders } from 'api/agent';
import { OrderItemStatusChangeModel, OrderUpdateModel, EmptyOrderModel } from 'api/models';

export const Order = {
    GetOrdersList: (
        searchString: string = '',
        dateFrom: string | undefined = undefined,
        dateTo: string | undefined = undefined,
        page: number,
        limit: number,
        asc: boolean,
        orderBy: string
    ) =>
        agent.requests.get(
            `/Order/list?searchString=${searchString}&dateFrom=${dateFrom ?? ''}&dateTo=${
                dateTo ?? ''
            }&page=${page}&limit=${limit}&asc=${asc}&orderBy=${orderBy}`
        ),
    ChangeOrderItemStatus: (model: OrderItemStatusChangeModel) =>
        agent.requests.post(`/Order/changeitemstatus`, model),
    UpdateOrder: (id: string, model: OrderUpdateModel) =>
        agent.requests.post(`/Order/${id}`, model),
    GetOrder: (id: string) => agent.requests.get(`/Order/${id}`),
    GetNewOrdersCount: (position: string) =>
        agent.requests.get(`/Order/getneworderscount?position=${position}`),
    GetPositionOrders: (position: string) =>
        agent.requests.get(`/Order/getpositionorders?position=${position}`),
    CreateEmptyOrder: (model: EmptyOrderModel) =>
        agent.requests.put('/Order/createempty', model, prepareHeaders()),
    GetReadyDishes: (searchString: string = '', page: number, limit: number) =>
        agent.requests.get(
            `/Order/getreadydishes?searchString=${searchString}&page=${page}&limit=${limit}`
        ),
    GetOverdudedOrders: () => agent.requests.get(`Order/getoverdudedorders`),
    GetOverdudedOrdersDetails: (ordersNumbers: number[]) =>
        agent.requests.get(
            `Order/getoverdudedordersdetails?${ordersNumbers
                .map((x) => 'orderNumber=' + x)
                .join('&')}`
        ),
    AssignOrder: (orderId: string, restaurantId: string, userId: string) =>
        agent.requests.post(`/Order/${orderId}/assign`, {
            restaurantId: restaurantId,
            userId: userId,
        }),
};
