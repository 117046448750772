import { Action, Reducer } from 'redux';

import { MenuType } from 'constants/enums';
import { DishData } from '../menuDetailsStore/reducer';
import { MenusActions } from './actions';

export interface MenusState {
    menus: MenuData[];
    dishList: DishData[];
}

export interface MenuData {
    edit: any;
    id: string;
    name: string;
    description: string;
    availableFrom: number;
    availableTo: number;
    price: number;
    photo: string;
    type: MenuType;
    sections: string[];
    dishesCount: number;
    isEnabled: boolean;
    position: number;
    discount: number;
}

const initialState: MenusState = {
    menus: [],
    dishList: [],
};

export const reducer: Reducer<MenusState> = (
    state: MenusState | undefined,
    incomingAction: Action
): MenusState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as MenusActions;
    switch (action.type) {
        case 'MENU_CREATE_SUCCESS':
            let targetIndex = state.menus.findIndex((x) => x.position > action.menu.position);
            if (targetIndex === -1) targetIndex = state.menus.length;
            let updatedMenusState = [...state.menus];
            updatedMenusState.splice(targetIndex, 0, action.menu);

            return {
                ...state,
                menus: updatedMenusState,
            };
        case 'MENU_EDIT_SUCCESS':
            let previousMenusState = [...state.menus];
            const orderBeforeUpdate = previousMenusState.find((x) => x.id === action.menu.id);
            let newMenusState;
            if (orderBeforeUpdate?.position !== action.menu.position) {
                newMenusState = previousMenusState.filter((x) => x.id !== action.menu.id);
                const nextPositionIndex = newMenusState.findIndex(
                    (x) => x.position > action.menu.position
                );
                newMenusState.splice(
                    nextPositionIndex !== -1 ? nextPositionIndex : newMenusState.length,
                    0,
                    action.menu
                );
            } else {
                newMenusState = previousMenusState.map((x) =>
                    x.id === action.menu.id ? { ...action.menu, dishesCount: x.dishesCount } : x
                );
            }

            return {
                ...state,
                menus: newMenusState,
            };
        case 'MENUS_GET_LIST_SUCCESS':
            return {
                ...state,
                menus: action.menus ? action.menus : [],
            };
        case 'MENU_DISABLE_SUCCESS':
            return {
                ...state,
                menus: state.menus.map((x) =>
                    x.id === action.menu.id ? { ...x, isEnabled: action.menu.isEnabled } : x
                ),
            };
        case 'MENU_GET_DISH_LIST_SUCCESS': {
            return {
                ...state,
                dishList: action.dishList,
            };
        }
        case 'MENU_DELETE_SUCCESS': {
            const { menuId } = action;
            const newMenus = state.menus.filter((eachMenu) => eachMenu.id !== menuId);
            return {
                ...state,
                menus: newMenus,
            };
        }
        case 'MENUS_REORDER_SUCCESS': {
            const menusCopy = [...state.menus];
            menusCopy.sort((a, b) => action.menus.indexOf(a.id) - action.menus.indexOf(b.id));
            return {
                ...state,
                menus: menusCopy,
            };
        }

        case 'MENU_COPY_SUCCESS': {
            return {
                ...state,
                menus: [...state.menus, action.payload],
            };
        }

        default:
            return state;
    }
};
