import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { HubConnectionBuilder, HttpTransportType, HubConnection } from '@microsoft/signalr';

import { default as ordersStore } from 'store/ordersStore';
import { OrderUpdateStatus, Positions } from 'constants/enums';
import agent from 'api/agent';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';

export default function NewOrdersCounter(props: { isOpen: boolean }) {
    const classes = useStyles();
    const newOrders = useAppSelector((state) => state.orders.newOrders);
    const user = useAppSelector((state) => state.user);
    const currentPosition =
        useAppSelector((state) => state.user).restaurants.find(
            (res) => res.id === user.currentRestaurantId
        )?.currentPosition || Positions.WAITER;
    const language = useAppSelector((state) => state.global.language);
    const dispatch = useDispatch();
    const [connection, setConnection] = useState<HubConnection | null>(null);
    const userRestaurantId = useRef('');

    useEffect(() => {
        if (user.currentRestaurantId) {
            userRestaurantId.current = user.currentRestaurantId;
        } else {
            agent.User.GetUserRestaurants().then((response) => {
                userRestaurantId.current = response.currentRestaurantId;
                dispatch({
                    type: 'USER_GET_RESTAURANTS_SUCCESS',
                    restaurants: response,
                });
            });
        }
    }, [user.currentRestaurantId]); // eslint-disable-line

    useEffect(() => {
        dispatch(ordersStore.actionCreators.getNewOrders(currentPosition));
        //TRY TO DISABLE GET NEW ORDERS LOOP
        /* const timer = setInterval(() => {
            dispatch(OrdersStore.actionCreators.getNewOrders());
        }, 60000);
        return () => clearInterval(timer); */
    }, []); // eslint-disable-line

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_ORDERS_HUB!, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (connection) {
            startConnection();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection]);

    function startConnection() {
        connection
            ?.start()
            .then(() => {
                console.log('SignalR Connected.');
                connection.on('UpdateOrders', handleMessage);
            })
            .catch((e: any) => {
                console.log('Connection failed: ', e);
                setTimeout(() => {
                    connection.state === 'Disconnected' && startConnection();
                }, 5000);
            });
    }

    function handleMessage(message: { actionType: number; restaurantId: string }) {
        const { actionType, restaurantId } = message;
        if (restaurantId === userRestaurantId.current) {
            switch (actionType) {
                case OrderUpdateStatus.UpdateOrdersCount:
                    dispatch(ordersStore.actionCreators.getNewOrders(currentPosition));
                    break;
            }
        }
    }

    function calculateLeftSpace() {
        if (!props.isOpen) {
            return 35;
        } else {
            return language === 'en' ? 120 : 160;
        }
    }

    function getWidth() {
        if (newOrders > 99) {
            return 22;
        }
        if (newOrders > 9) {
            return 16;
        }
        return 12;
    }

    return (
        <div style={{ position: 'relative' }} key="newOrders">
            <div
                className={classes.newOrdersCount}
                style={{ left: calculateLeftSpace(), width: getWidth() }}
            >
                {newOrders}
            </div>
        </div>
    );
}
