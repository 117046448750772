import React, { useEffect, useState } from 'react';

import { Card } from '@material-ui/core';
import i18n from 'i18n-js';

import { OrderUpdateModel } from 'api/models';
import { OrderStatus, PaymentMethod, OrderServices } from 'constants/enums';
import { OrderDetails } from 'store/ordersStore/reducer';
import { useStyles } from './styles';
import { statusColor, statusText } from 'constants/arrays';

export default function OrderStatusItem(props: {
    status: OrderStatus;
    update: any;
    order: OrderDetails;
    isDisabled: boolean;
}) {
    const classes = useStyles();
    const [showSelect, setShowSelect] = useState(false);

    function changeStatus(status: OrderStatus) {
        let model: OrderUpdateModel = {
            orderItems: props.order.orderItems,
            deliveryTime: props.order.deliveryTime,
            status: status === OrderStatus.Cancel ? OrderStatus.Cancel : OrderStatus.Non,
            paymentMethod: props.order.paymentMethod,
            qrCodeName: props.order.qrCodeName,
            service: props.order.service,
        };
        if (status === OrderStatus.InProgress) {
            model.orderItems = model.orderItems.map((x) => ({ ...x, isReady: false }));
        } else if (status === OrderStatus.Ready) {
            model.orderItems = model.orderItems.map((x) => ({ ...x, isReady: true }));
        } else if (status === OrderStatus.Closed) {
            model.orderItems = model.orderItems.map((x) => ({ ...x, isReady: true }));
            model.status = OrderStatus.Closed;
        } else if (
            (status === OrderStatus.Delivered || status === OrderStatus.OnDelivery) &&
            props.order.service === OrderServices.HomeDelivery
        ) {
            model.status = status;
        }

        setShowSelect(false);
        props.update(model);
    }
    // should be refactored by using OutsideClickHandler for example
    const isOpen = () => document.getElementById('status-change-select')?.style.display !== 'none';

    function handleClick(e: any) {
        /* e.stopPropagation();
        e.preventDefault(); */
        e.target.slot !== 'status-container' && isOpen() && setShowSelect(false);
    }

    function prepareStatusStyle() {
        return props.status === OrderStatus.Cancel
            ? { border: `1px solid ${statusColor[props.status]}`, color: statusColor[props.status] }
            : { backgroundColor: statusColor[props.status] };
    }

    function prepareStatusesList() {
        let list = [];
        /* switch (props.status) {
            case OrderStatus.New:
                list.push(
                    <div
                        className={classes.orderDetailsHeadingBackground}
                        style={{
                            backgroundColor: statusColor[1],
                        }}
                        onClick={() => changeStatus(OrderStatus.InProgress)}
                        key={statusColor[1]}
                    >
                        {statusText[1]}
                    </div>
                );
                break;
            case OrderStatus.InProgress:
                list.push(
                    <div
                        className={classes.orderDetailsHeadingBackground}
                        style={{
                            backgroundColor: statusColor[2],
                        }}
                        onClick={() => changeStatus(OrderStatus.Ready)}
                        key={statusColor[2]}
                    >
                        {statusText[2]}
                    </div>
                );
                break;
            case OrderStatus.Ready:
                list.push(
                    <div
                        className={classes.orderDetailsHeadingBackground}
                        style={{
                            backgroundColor: statusColor[3],
                        }}
                        onClick={() =>
                            //temporary disable payment checking, need to clarify flow for this
                            //props.order.paymentMethod !== PaymentMethod.None &&
                            //props.order.isPaid &&
                            props.order.paymentMethod !== PaymentMethod.None &&
                            changeStatus(OrderStatus.Closed)
                        }
                        key={statusColor[3]}
                    >
                        {statusText[3]}
                    </div>
                );
                break;
        } */
        if (props.status === OrderStatus.Ready) {
            if (props.order.service === OrderServices.HomeDelivery) {
                list.push(
                    <div
                        className={classes.orderDetailsHeadingBackground}
                        style={{
                            backgroundColor: statusColor[3],
                        }}
                        onClick={() => changeStatus(OrderStatus.OnDelivery)}
                        key={statusColor[7]}
                    >
                        {statusText[7]}
                    </div>
                );
            } else {
                list.push(
                    <div
                        className={classes.orderDetailsHeadingBackground}
                        style={{
                            backgroundColor: statusColor[3],
                        }}
                        onClick={() =>
                            //temporary disable payment checking, need to clarify flow for this
                            /* props.order.paymentMethod !== PaymentMethod.None &&
                        props.order.isPaid && */
                            props.order.paymentMethod !== PaymentMethod.None &&
                            changeStatus(OrderStatus.Closed)
                        }
                        key={statusColor[3]}
                    >
                        {statusText[3]}
                    </div>
                );
            }
        }
        if (props.status === OrderStatus.OnDelivery) {
            list.push(
                <div
                    className={classes.orderDetailsHeadingBackground}
                    style={{
                        backgroundColor: statusColor[1],
                    }}
                    onClick={() => changeStatus(OrderStatus.Delivered)}
                    key={statusColor[8]}
                >
                    {statusText[OrderStatus.Delivered]}
                </div>
            );
        }
        if (props.status === OrderStatus.Delivered) {
            list.push(
                <div
                    className={classes.orderDetailsHeadingBackground}
                    style={{
                        backgroundColor: statusColor[OrderStatus.Closed],
                    }}
                    onClick={() => changeStatus(OrderStatus.Closed)}
                    key={statusColor[8]}
                >
                    {statusText[OrderStatus.Closed]}
                </div>
            );
        }
        if (props.status !== OrderStatus.Cancel && !props.order.isPaid) {
            list.push(
                <div
                    className={classes.orderDetailsHeadingBackground}
                    style={{
                        color: statusColor[6],
                        border: `1px solid ${statusColor[6]}`,
                        marginTop: list.length > 0 ? 10 : 0,
                    }}
                    onClick={() => changeStatus(OrderStatus.Cancel)}
                    key={statusColor[6]}
                >
                    {i18n.t(`common.statusText.${OrderStatus.Cancel}`)}
                </div>
            );
        }

        return list;
    }

    useEffect(() => {
        document.body.addEventListener('click', (e) => handleClick(e));
        return () => {
            document.body.addEventListener('click', (e) => handleClick(e));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.status]);
    return (
        <div
            className={`${classes.orderHeaderItem} ${classes.flexColumnSpaceBetween} ${
                classes.orderStatus
            } ${showSelect && classes.orderHeaderItemActive}`}
            onClick={() => {
                if (!props.isDisabled && prepareStatusesList().length > 0) {
                    setShowSelect(!showSelect);
                }
            }}
            style={{ cursor: !props.isDisabled ? 'pointer' : 'auto' }}
            slot="status-container"
        >
            <div className={classes.orderDetailsHeadingText} slot="status-container">
                {i18n.t('common.status')}
            </div>
            <div
                className={classes.orderDetailsHeadingBackground}
                style={prepareStatusStyle()}
                slot="status-container"
            >
                {i18n.t(`common.statusText.${props.status}`)}
            </div>
            <Card
                slot="status-container"
                className={classes.changeStatusDropdown}
                id="status-change-select"
                style={{
                    display: !props.isDisabled && showSelect ? 'flex' : 'none',
                }}
            >
                {prepareStatusesList()}
            </Card>
        </div>
    );
}
