import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
} from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import clsx from 'clsx';

import {
    PopupConfirmation,
    SearchInput,
    RolesFilter,
    UserRoleLabel,
    Text,
} from 'components/shared';
import { BasicThreeDotsMenu } from 'components/shared';
import { ApplicationState } from 'store';
import { default as usersStore } from 'store/usersStore';
import { default as rolesStore } from 'store/rolesStore';
import logo from 'assets/user-logo.svg';
import NumberFormat from 'react-number-format';
import { theme } from 'config/theme';
import { UserFilterModel } from 'api/models';
import { UserData } from 'store/usersStore/reducer';
import commonStyles from 'config/commonStyles';
import { EditRestaurantUserForm } from 'components/users/components';
import { Roles } from 'constants/enums';
import AddUserComponent from 'components/users/components/AddUserComponent';
import { pagingOptions } from 'constants/arrays';
import { helperFunctions } from 'helpers';

const useStyles = makeStyles({
    ...commonStyles,
    paper: {
        filter: 'none',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowY: 'hidden',
    },
    root: {
        //...commonStyles.flexColumnSpaceBetweenNoAlign,
        //height: 640,
        /* '& .MuiPaper-root': {
            width: 1115,
        }, */
    },
    table: {
        minWidth: 650,
        '& .MuiTableCell-body': {
            paddingTop: 5,
            paddingBottom: 5,
        },
    },
    fixedWidth: {
        width: 240,
        maxWidth: 240,
    },
    dots: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    toolbar: {
        height: 60,
        paddingLeft: 26,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    toolbarTitle: {
        flex: '1 1 100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    dialog: {
        '& .MuiDialogTitle-root > h2': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
    disabledRow: {
        '& td:not(:last-child)': {
            opacity: 0.35,
        },
        '& td:last-child': {
            borderBottomColor: 'rgba(224, 224, 224, 0.35)',
        },
    },
});

export default function RestaurantUsersTable() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.user);
    const users = useSelector((state: ApplicationState) => state.users);
    const restaurants = useSelector((state: ApplicationState) => state.restaurants);
    const roles = useSelector((state: ApplicationState) => state.roles);
    const [page, setPage] = useState(0);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        dispatch(
            usersStore.actionCreators.getRestaurantUsers(filter, newPage, rowsPerPage, asc, orderBy)
        );
    };
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
    const [userToEdit, setUserToEdit] = useState<UserData>({
        id: '',
        firstName: '',
        lastName: '',
        restaurants: [],
        email: '',
        phoneNumber: '',
        roles: [],
        isEnabled: false,
    });

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newRowsPerPage = +event.target.value;
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        dispatch(
            usersStore.actionCreators.getRestaurantUsers(filter, 0, newRowsPerPage, asc, orderBy)
        );
    };

    const [rowsPerPage, setRowsPerPage] = React.useState(pagingOptions[0]);
    const defaultFilter: UserFilterModel = {
        name: '',
        restaurant: '',
        email: '',
        phoneNumber: '',
        position: '',
        roles: [],
    };
    const [orderBy, setOrderBy] = useState('name');
    const [asc, setAsc] = useState(true);
    const [filter, setFilter] = useState(defaultFilter);
    const isGroupAction = useRef(false);

    const handleFilterChange = () => {
        setPage(0);
        dispatch(
            usersStore.actionCreators.getRestaurantUsers(filter, 0, rowsPerPage, asc, orderBy)
        );
    };
    function handleSelect(event: React.ChangeEvent<{ value: string[] }>) {
        if (event.target.value.includes('selectAll')) {
            if (filter.roles.length !== 2) {
                setFilter({
                    ...filter,
                    roles: roles.roles
                        .filter((x) => x.name === Roles.OWNER || x.name === Roles.EMPLOYEE)
                        .map((y) => y.id),
                });
            } else {
                setFilter({ ...filter, roles: [] });
            }
        } else {
            event.target.value && setFilter({ ...filter, roles: event.target.value as string[] });
        }
    }

    const orderNames: string[] = ['name', 'email', 'phoneNumber', 'position', 'role'];
    function handleOrder(id: string) {
        if (orderBy === id) {
            setAsc(!asc);
            dispatch(
                usersStore.actionCreators.getRestaurantUsers(
                    filter,
                    page,
                    rowsPerPage,
                    !asc,
                    orderBy
                )
            );
        } else {
            dispatch(
                usersStore.actionCreators.getRestaurantUsers(filter, page, rowsPerPage, !asc, id)
            );
            setOrderBy(id);
        }
    }

    function handleEditModal() {
        setOpenEditModal(!openEditModal);
    }

    function handleConfirmationPopup(isGroup: boolean = false) {
        isGroupAction.current = isGroup;
        setOpenConfirmationPopup(!openConfirmationPopup);
    }

    function enableDisableUser(enable: boolean) {
        const params = isGroupAction.current ? [] : [userToEdit.id];
        enable
            ? dispatch(usersStore.actionCreators.enableUser(params))
            : dispatch(usersStore.actionCreators.disableUser(params));
        handleConfirmationPopup();
    }

    function getThreeDotsItems(row: any) {
        const items = [
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => {
                    setUserToEdit(row);
                    handleEditModal();
                }}
            >
                <ListItemIcon>
                    <BorderColorIcon fontSize="small" color="disabled" />
                </ListItemIcon>
                <Typography variant="inherit">{i18n.t('common.edit')}</Typography>
            </div>,
        ];
        if (row.id !== user.authUserId) {
            items.push(
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => {
                        setUserToEdit(row);
                        handleConfirmationPopup();
                    }}
                >
                    <ListItemIcon>
                        {row.isEnabled ? (
                            <RemoveCircleOutlineIcon fontSize="small" color="disabled" />
                        ) : (
                            <SettingsBackupRestoreIcon fontSize="small" color="disabled" />
                        )}
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {row.isEnabled ? i18n.t('button.disable') : i18n.t('button.restore')}
                    </Typography>
                </div>
            );
        }
        return items;
    }

    function clearFilterField(value: keyof UserFilterModel) {
        if (filter.hasOwnProperty(value)) {
            const newFilter = { ...filter, [value]: '' };
            setFilter(newFilter);
            dispatch(
                usersStore.actionCreators.getRestaurantUsers(
                    newFilter,
                    page,
                    rowsPerPage,
                    asc,
                    orderBy
                )
            );
        }
    }

    function resetFilter() {
        setPage(0);
        setFilter(defaultFilter);
        dispatch(
            usersStore.actionCreators.getRestaurantUsers(
                defaultFilter,
                0,
                rowsPerPage,
                asc,
                orderBy
            )
        );
    }

    useEffect(() => {
        users?.users?.length === 0 &&
            dispatch(
                usersStore.actionCreators.getRestaurantUsers(
                    defaultFilter,
                    page,
                    rowsPerPage,
                    asc,
                    orderBy
                )
            );
        roles.roles.length === 0 && dispatch(rolesStore.actionCreators.getRoles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Toolbar className={classes.toolbar}>
                <div className={classes.toolbarActions}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={resetFilter}
                        style={{ marginRight: 20 }}
                    >
                        {i18n.t('form.resetSearch')}
                    </Button>
                    <AddUserComponent />
                </div>
            </Toolbar>
            <Paper className={classes.paper}>
                <TableContainer className={classes.root}>
                    <Table stickyHeader className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow key="table-head">
                                <TableCell sortDirection={asc ? 'asc' : 'desc'}>
                                    <TableSortLabel
                                        active={orderBy === orderNames[0]}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(orderNames[0])}
                                    >
                                        <SearchInput
                                            value={filter.name}
                                            name={i18n.t('form.userName')}
                                            trimValue={false}
                                            onChange={(value: string) => {
                                                setFilter({ ...filter, name: value });
                                            }}
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('name')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={asc ? 'asc' : 'desc'}>
                                    <TableSortLabel
                                        active={orderBy === orderNames[1]}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(orderNames[1])}
                                    >
                                        <SearchInput
                                            value={filter.email}
                                            name={i18n.t('form.email')}
                                            onChange={(value: string) =>
                                                setFilter({ ...filter, email: value })
                                            }
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('email')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={asc ? 'asc' : 'desc'}
                                    style={{
                                        paddingLeft: rowsPerPage === pagingOptions[0] ? 16 : 8,
                                    }}
                                >
                                    <TableSortLabel
                                        active={orderBy === orderNames[2]}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(orderNames[2])}
                                    >
                                        <SearchInput
                                            value={filter.phoneNumber}
                                            name={helperFunctions.truncateString(
                                                i18n.t('form.phone'),
                                                11
                                            )}
                                            onChange={(value: string) =>
                                                setFilter({ ...filter, phoneNumber: value })
                                            }
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('phoneNumber')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={asc ? 'asc' : 'desc'}>
                                    <TableSortLabel
                                        active={orderBy === orderNames[3]}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(orderNames[3])}
                                    >
                                        <SearchInput
                                            value={filter.position}
                                            name={i18n.t('form.position')}
                                            onChange={(value: string) =>
                                                setFilter({ ...filter, position: value })
                                            }
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('position')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    style={{ width: '145px' }}
                                    sortDirection={asc ? 'asc' : 'desc'}
                                >
                                    <TableSortLabel
                                        active={orderBy === orderNames[4]}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(orderNames[4])}
                                    >
                                        <RolesFilter
                                            onChange={handleSelect}
                                            onSubmit={handleFilterChange}
                                            selectedRoles={filter.roles}
                                            onlyRestaurantUsers={true}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.users.map((row) => (
                                <TableRow
                                    key={row.id}
                                    className={clsx(row.id, {
                                        [classes.disabledRow]: !row.isEnabled,
                                    })}
                                >
                                    <TableCell
                                        scope="row"
                                        onClick={() => {
                                            setUserToEdit(row);
                                            handleEditModal();
                                        }}
                                        className={classes.fixedWidth}
                                    >
                                        <ListItem alignItems="center" key={row.id}>
                                            <ListItemAvatar>
                                                <Avatar alt="Remy Sharp" src={logo} />
                                            </ListItemAvatar>
                                            <Text
                                                text={`${row.firstName} ${row.lastName}`}
                                                maxLength={20}
                                                useClassic
                                            />
                                        </ListItem>
                                    </TableCell>
                                    <TableCell align="left" className={classes.fixedWidth}>
                                        <Text
                                            text={row.email}
                                            fontSize={14}
                                            maxLength={25}
                                            useClassic
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.phoneNumber ? (
                                            <NumberFormat
                                                format="+## (###) ### ## ##"
                                                displayType={'text'}
                                                value={row.phoneNumber}
                                            />
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.restaurants.map(
                                            (rest, i) =>
                                                rest.restaurantId === user.currentRestaurantId && (
                                                    <div key={i}>{rest?.positions?.join(', ')}</div>
                                                )
                                        )}
                                    </TableCell>
                                    <TableCell align="left">
                                        <UserRoleLabel role={row.roles[0]?.name} />
                                    </TableCell>
                                    <TableCell align="right">
                                        <BasicThreeDotsMenu items={getThreeDotsItems(row)} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pagingOptions}
                    component="div"
                    count={users?.count}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={i18n.t('common.rowsPerPage')}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Dialog
                    open={openEditModal}
                    aria-labelledby="form-dialog-title"
                    className={classes.dialog}
                >
                    <DialogTitle id="form-dialog-title">{i18n.t('user.editUser')}</DialogTitle>
                    <DialogContent>
                        <EditRestaurantUserForm
                            user={userToEdit}
                            restaurants={restaurants.restaurants}
                            editRestaurantUser={(model: any) =>
                                dispatch(usersStore.actionCreators.editRestaurantUser(model))
                            }
                            cancel={handleEditModal}
                            close={handleEditModal}
                        />
                    </DialogContent>
                </Dialog>
                <PopupConfirmation
                    open={openConfirmationPopup}
                    close={handleConfirmationPopup}
                    title={
                        userToEdit.isEnabled
                            ? i18n.t('common.disableUser')
                            : i18n.t('common.restoreUser')
                    }
                    description={
                        userToEdit.isEnabled
                            ? i18n.t('confirmation.disableUser')
                            : i18n.t('confirmation.restoreUser')
                    }
                    activeBtn={
                        userToEdit.isEnabled ? i18n.t('common.disable') : i18n.t('common.enable')
                    }
                    action={() => enableDisableUser(!userToEdit.isEnabled)}
                />
            </Paper>
            <span></span>
        </>
    );
}
