import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, makeStyles, Theme, createStyles } from '@material-ui/core';

import commonStyles from 'config/commonStyles';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        timePickerModal: {
            scrollbarWidth: 'none !important',
            msOverflowStyle: 'none !important',
            '&::-webkit-scrollbar': {
                display: 'none !important',
            },
        },
        topTimeContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        modalContainer: {
            height: '250px',
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
        },
        timeMainContainer: {
            display: 'flex',
        },
        timeContainer: {
            maxHeight: '240px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            scrollbarWidth: 'none !important',
            msOverflowStyle: 'none !important',
            '&::-webkit-scrollbar': {
                display: 'none !important',
            },
        },
        listTimeItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '3px',
            width: '40px',
            padding: '9px',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.primary.main,
            },
            margin: '2px',
        },
        timeItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '3px',
            width: '40px',
            padding: '9px',
            margin: '2px 4px',
        },
        selectedTimeItem: {
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        },
        lastSelectedTimeItem: {
            backgroundColor: theme.palette.primary.main,
        },
        timeItemHover: {
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.primary.main,
            },
        },
        divider: {
            height: '90%',
            width: '1px',
            backgroundColor: 'lightgray',
            margin: '0 5px',
        },
        smallDividerLine: {
            height: '2px',
            width: '6px',
            backgroundColor: theme.palette.grey['800'],
            margin: '0 5px',
        },
    })
);

interface Props {
    isVisible: boolean;
    onClose: (selectedHours: { from: string; to: string }) => void;
    onDateChange?: (selectedHours: { from: string; to: string }) => void;
    initialFromHour: string;
    initialFromMinute: string;
    initialToHour: string;
    initialToMinute: string;
    clickedElementRect?: any;
}

const TimePickerModal = ({
    isVisible,
    onClose,
    onDateChange,
    initialFromHour,
    initialFromMinute,
    initialToHour,
    initialToMinute,
    clickedElementRect,
}: Props) => {
    const fromHoursRef = React.useRef(null);
    const fromMinutesRef = React.useRef(null);

    const toHoursRef = React.useRef(null);
    const toMinutesRef = React.useRef(null);

    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [fromHour, setFromHour] = useState(initialFromHour);
    const [fromMinute, setFromMinute] = useState(initialFromMinute);
    const [toHour, setToHour] = useState(initialToHour);
    const [toMinute, setToMinute] = useState(initialToMinute);

    const classes = styles();

    const MODAL_HEIGHT = 250;

    useEffect(() => {
        onDateSet();
    }, [fromHour, fromMinute, toHour, toMinute]); // eslint-disable-line react-hooks/exhaustive-deps

    function getHoursList() {
        const hours = [];

        for (let k = 0; k <= 23; k++) {
            let hour = k < 10 ? '0' + k : k;
            hours.push(hour.toString());
        }

        return hours;
    }

    function getMinutesList() {
        const minutes = [];
        for (let i = 0; i <= 59; i++) {
            let minute = i < 10 ? '0' + i : i;
            minutes.push(minute.toString());
        }
        return minutes;
    }

    function scrollToItem(index: number, ref: any, type: string) {
        const refHeight = ref.current.scrollHeight;
        const listLength = type === 'hour' ? getHoursList().length : getMinutesList().length;
        const eachHeight = refHeight / listLength;
        const extra = -120;

        ref.current.scrollTo({
            top: eachHeight * index + extra,
            behavior: 'smooth',
        });
    }

    function onCloseTriggered() {
        const from = `${fromHour}.${fromMinute}`;
        const to = `${toHour}.${toMinute}`;
        onClose({
            from,
            to,
        });
    }

    function onDateSet() {
        const from = `${fromHour}.${fromMinute}`;
        const to = `${toHour}.${toMinute}`;
        onDateChange &&
            onDateChange({
                from,
                to,
            });
    }

    function getDynamicStyle() {
        if (clickedElementRect) {
            const { top, left, bottom } = clickedElementRect;
            const fromTop = window.innerHeight > bottom + 65 + MODAL_HEIGHT;
            return {
                position: 'fixed',
                top: fromTop ? top + 65 : top - MODAL_HEIGHT - 75,
                left,
                backgroundColor: 'rgba(251, 251, 251, 1)',
            };
        }
        return {};
    }

    return (
        <Dialog hideBackdrop={true} open={isVisible} onClose={onCloseTriggered}>
            {/*<DialogTitle id="form-dialog-title">
                <Typography variant="h6" align="left" className={classes.fontWeight500}>
                    {i18n.t(`restaurant.addWorkingHours`)}
                </Typography>
            </DialogTitle>
            */}
            <DialogContent
                // @ts-ignore
                style={getDynamicStyle()}
                className={classes.timePickerModal}
            >
                <div className={classes.flexRowSpaceBetween}>
                    <div className={classes.topTimeContainer}>
                        <div
                            className={`${classes.timeItem} ${classes.selectedTimeItem} ${
                                focusedIndex === 0 && classes.lastSelectedTimeItem
                            }`}
                        >
                            {fromHour}
                        </div>

                        <div
                            className={`${classes.timeItem} ${classes.selectedTimeItem} ${
                                focusedIndex === 1 && classes.lastSelectedTimeItem
                            }`}
                        >
                            {fromMinute}
                        </div>
                    </div>
                    <div className={classes.smallDividerLine} />
                    <div className={classes.topTimeContainer}>
                        <div className={classes.topTimeContainer}>
                            <div
                                className={`${classes.timeItem} ${classes.selectedTimeItem} ${
                                    focusedIndex === 2 && classes.lastSelectedTimeItem
                                }`}
                            >
                                {toHour}
                            </div>

                            <div
                                className={`${classes.timeItem} ${classes.selectedTimeItem} ${
                                    focusedIndex === 3 && classes.lastSelectedTimeItem
                                }`}
                            >
                                {toMinute}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.modalContainer}>
                    <div className={classes.timeMainContainer}>
                        <div className={classes.timeContainer} ref={fromHoursRef}>
                            {getHoursList().map((hour, i) => (
                                <div
                                    key={i}
                                    className={`${classes.timeItem} ${classes.timeItemHover}`}
                                    onClick={() => {
                                        setFromHour(hour);
                                        scrollToItem(i, fromHoursRef, 'hour');
                                        setFocusedIndex(0);
                                    }}
                                >
                                    {hour}
                                </div>
                            ))}
                        </div>
                        <div className={classes.timeContainer} ref={fromMinutesRef}>
                            {getMinutesList().map((minute, i) => (
                                <div
                                    key={i}
                                    className={`${classes.timeItem} ${classes.timeItemHover}`}
                                    onClick={() => {
                                        setFromMinute(minute);
                                        scrollToItem(i, fromMinutesRef, 'minute');
                                        setFocusedIndex(1);
                                    }}
                                >
                                    {minute}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={classes.divider} />
                    <div className={classes.timeMainContainer}>
                        <div className={classes.timeContainer} ref={toHoursRef}>
                            {getHoursList().map((hour, i) => (
                                <div
                                    key={i}
                                    className={`${classes.timeItem} ${classes.timeItemHover}`}
                                    onClick={() => {
                                        setToHour(hour);
                                        scrollToItem(i, toHoursRef, 'hour');
                                        setFocusedIndex(2);
                                    }}
                                >
                                    {hour}
                                </div>
                            ))}
                        </div>
                        <div className={classes.timeContainer} ref={toMinutesRef}>
                            {getMinutesList().map((minute, i) => (
                                <div
                                    key={i}
                                    className={`${classes.timeItem} ${classes.timeItemHover}`}
                                    onClick={() => {
                                        setToMinute(minute);
                                        scrollToItem(i, toMinutesRef, 'minute');
                                        setFocusedIndex(3);
                                    }}
                                >
                                    {minute}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default TimePickerModal;
