import { createStyles, makeStyles, Theme } from '@material-ui/core';
import commonStyles from '../../../config/commonStyles';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        tableContainer: {
            maxHeight: 'calc(100% - 120px)',
            overflow: 'auto',
        },
        eachTabFor4: {
            width: '25%',
            paddingBottom: '16px !important',
        },
        eachTabFor5: {
            width: '20%',
            paddingBottom: '16px !important',
            whiteSpace: 'nowrap',
        },
        accordion: {
            '& tr:first-child td': {
                borderTop: '1px solid rgba(224, 224, 224, 1)',
            },
        },
        expanded: {
            '&$expanded': {
                margin: '0 0 2px 0',
            },
        },
        opacity03: {
            opacity: 0.3,
        },
        dayWithDotContainer: {
            position: 'relative',
        },
        dayWithDot: {
            position: 'absolute',
            height: 0,
            width: 0,
            border: '2px solid',
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            right: '50%',
            transform: 'translateX(1px)',
            top: '80%',
        },
        priceAmountContainer: {
            position: 'absolute',
            right: '1%',
            marginTop: '-3px',
            marginRight: '2.5px',
        },
        currencyText: {
            color: theme.palette.primary.main,
            fontWeight: '400',
            fontSize: '14px',
            marginRight: '3px',
        },
        currencyAmount: {
            color: theme.palette.primary.main,
            fontWeight: '700',
            fontSize: '24px',
        },
        switchGraphContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '70px',
            margin: '0 53px 0 66px',
        },
        switchBasic: {
            padding: '1px',
            border: '1px solid transparent',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        chartContainer: {
            padding: '15px',
            width: '91%',
        },
        chartBottomContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: theme.palette.common.white,
            height: '63px',
            borderTop: `1px solid ${theme.palette.grey['300']}`,
            marginTop: '-10px',
            padding: '0 40px',
        },
        chartBottomIconText: {
            marginLeft: '12px',
        },
        chartSecondaryFilterContainer: {
            position: 'absolute',
            right: '80px',
        },
        chartCurrencyText: {
            position: 'absolute',
            fontSize: '13px',
            color: theme.palette.grey['400'],
            top: '120px',
        },
        secondDateHistoryText: {
            color: theme.palette.primary.main,
            fontSize: '16px',
            fontWeight: '700',
        },
        textField: {
            borderBottom: '1px solid transparent',
            opacity: 0.75,
        },
        focusedTextField: {
            borderBottom: '1px solid black',
            opacity: 1,
        },
        timePickerContainer: { position: 'absolute', zIndex: 1, top: '13%', right: '24%' },
        topContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            height: '35px',
            padding: '8px',
            borderBottom: `1px solid ${theme.palette.grey['300']}`,
        },
        noBordersHeader: {
            '& td': {
                border: 'none',
            },
        },
    })
);

export default styles;
