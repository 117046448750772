import i18n from 'i18n-js';

import { theme } from 'config/theme';
import { Roles, OrderServices } from './enums';
import { images } from 'assets';

interface Service {
    icon: string;
    name: string;
}

export const roleColor = new Map([
    [Roles.ADMIN as string, theme.palette.success.main],
    [Roles.OWNER as string, theme.palette.info.dark],
    [Roles.EMPLOYEE as string, theme.palette.info.main],
    [Roles.USER as string, theme.palette.warning.main],
]);

export const services = new Map<OrderServices, Service>([
    [
        OrderServices.OrderInRestaurant,
        {
            icon: images.icons.chartVorort,
            name: i18n.t('form.jamesVorort'),
        },
    ],
    [
        OrderServices.TakeAway,
        {
            icon: images.icons.chartTakeAway,
            name: i18n.t('form.jamesVorort'),
        },
    ],
    [
        OrderServices.HomeDelivery,
        {
            icon: images.icons.chartHomeDelivery,
            name: i18n.t('form.homeDelivery'),
        },
    ],
]);

export function getServices() {
    const t = i18n.t;
    return new Map<OrderServices, Service>([
        [
            OrderServices.OrderInRestaurant,
            {
                icon: images.icons.chartVorort,
                name: t('form.jamesVorort'),
            },
        ],
        [
            OrderServices.TakeAway,
            {
                icon: images.icons.chartTakeAway,
                name: t('form.takeAway'),
            },
        ],
        [
            OrderServices.HomeDelivery,
            {
                icon: images.icons.chartHomeDelivery,
                name: t('form.homeDelivery'),
            },
        ],
    ]);
}
