import React, { useRef, useState } from 'react';

import {
    Toolbar,
    FormControl,
    InputLabel,
    Input,
    InputAdornment,
    IconButton,
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core';

import { images } from 'assets';
import commonStyles from 'config/commonStyles';

interface SearchInputProps {
    value: string;
    name: string;
    onChange: (e: string) => void;
    onEnter: () => void;
    onReset: () => void;
    onFocus?: () => void;
    rightIconClass?: string;
    rightIconStyle?: any;
    trimValue?: boolean;
    fontSize?: any;
    style?: React.CSSProperties;
    readOnly?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        root: {
            ...commonStyles.flexRowSpaceBetween,
            paddingLeft: '0px !important',
            '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
                position: 'absolute',
                top: '50%',
                right: 0,
            },
            '& .MuiFormLabel-root': {
                fontSize: 14,
                fontWeight: 500,
                color: theme.palette.action.disabled,
            },
        },
        label: {
            cursor: 'pointer',
            zIndex: 2,
        },
        name: {
            fontSize: 14,
            letterSpacing: 0.15,
        },
        resetSearchIcon: {
            color: theme.palette.action.disabled,
            cursor: 'pointer',
            height: 12,
            width: 12,
        },
        lensIcon: {
            cursor: 'pointer',
            width: 12,
        },
        input: {
            paddingRight: 24,
            fontSize: '14px',
        },
    })
);

export default function SearchInput(props: SearchInputProps) {
    const classes = useStyles();
    const [showUnderline, setShowUnderline] = useState(false);
    const id = `search-input-${props.name.replace(/ /g, '-')}`;
    const inputRef = useRef(null);
    function preventActions(e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    function hasValue() {
        return props.value.length > 0;
    }

    return (
        <Toolbar style={props.style} className={`${classes.root} search-input`}>
            <FormControl variant="standard">
                <InputLabel
                    className={classes.label}
                    htmlFor={id}
                    style={{
                        display: props.value.length === 0 && !showUnderline ? 'block' : 'none',
                        fontSize: props.fontSize ? props.fontSize : '14px',
                    }}
                >
                    {props.name}
                </InputLabel>
                <Input
                    disabled={props.readOnly}
                    id={id}
                    ref={inputRef}
                    type="text"
                    className={classes.input}
                    disableUnderline={props.value.length === 0 && !showUnderline}
                    value={props.value}
                    onFocus={() => {
                        setShowUnderline(true);
                        props.onFocus && props.onFocus();
                    }}
                    onBlur={() => setShowUnderline(false)}
                    onClick={(e) => preventActions(e)}
                    onChange={(e) => {
                        if (props.trimValue) {
                            props.onChange(e.target.value.trim());
                        } else {
                            props.onChange(e.target.value);
                        }
                    }}
                    onKeyDown={(e) => e.key === 'Enter' && props.onEnter()}
                    endAdornment={
                        props.readOnly ? (
                            <></>
                        ) : (
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        if (!hasValue()) {
                                            // @ts-ignore
                                            inputRef.current.firstChild.focus();
                                            return setShowUnderline(true);
                                        }
                                        props.onReset();
                                    }}
                                >
                                    <img
                                        src={
                                            !hasValue()
                                                ? images.icons.lens
                                                : images.icons.removeCircle
                                        }
                                        alt="remobe-circle"
                                        className={`${classes.resetSearchIcon} ${props.rightIconClass}`}
                                        style={props.rightIconStyle}
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                />
            </FormControl>
        </Toolbar>
    );
}
SearchInput.defaultProps = {
    trimValue: true,
};
