import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { theme } from 'config/theme';

const StyledMenu = withStyles((theme) => ({
    paper: {
        border: '1px solid #d3d4d5',
        '& ul > li:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
    },
}))((props: MenuProps) => (
    <Menu
        variant={'menu'}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        paddingTop: 8,
        paddingBottom: 8,
        minHeight: '48px !important',
        minWidth: 150,
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

interface Props {
    id?: string;
    items: JSX.Element[] | string[] | undefined;
    primaryColor?: boolean;
    disabled?: boolean;
}

export default function BasicThreeDotsMenu({
    id = '',
    items = [],
    primaryColor = true,
    disabled = false,
}: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
        event.preventDefault();
    };

    function hideMenu(e: any) {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(null);
    }

    return (
        <div id={id}>
            {items.length > 0 && (
                <IconButton
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    onClick={openMenu}
                    disabled={disabled}
                >
                    <MoreVertIcon
                        style={{
                            color: primaryColor
                                ? theme.palette.primary.main
                                : theme.palette.grey[600],
                        }}
                    />
                </IconButton>
            )}
            <StyledMenu
                disableAutoFocusItem
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={(e) => hideMenu(e)}
            >
                {items?.length &&
                    // @ts-ignore
                    items.map((item, i) => (
                        // @ts-ignore
                        <StyledMenuItem onClick={hideMenu} key={i}>
                            {item}
                        </StyledMenuItem>
                    ))}
            </StyledMenu>
        </div>
    );
}
