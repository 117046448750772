import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core';

import commonStyles from 'config/commonStyles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        root: {
            height: '100%',
            width: '100%',
            '& .MuiListItem-gutters': {
                padding: '0',
            },
            '& .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters': {
                padding: '0 16px',
            },
            '& .MuiTableContainer-root': {
                height: 'auto',
            },
            '& > .MuiPaper-root': {
                minHeight: 0,
                maxHeight: 'calc(100% - 55px)',
                overflowY: 'auto',
            },
        },
        table: {
            minWidth: 650,
        },
        dots: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        breakfastLogo: {
            marginLeft: '15px',
            marginRight: '15px',
        },
        menuLabel: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            color: theme.palette.common.black,
            marginRight: '10px',
            minWidth: '438px',
            height: '41px',
            background: theme.palette.secondary.light,
            fontWeight: 'bold',
            fontSize: '16px',
            borderRadius: '5px',
        },
        menuPrice: {
            fontWeight: 900,
            fontSize: '16px',
            lineHeight: '19px',
            color: theme.palette.primary.main,
        },
        toolbarTitle: {
            flex: '1 1 80%',
        },
        iconButton: {
            color: theme.palette.common.black,
            marginRight: '10px',
        },
        menuSectionsContainer: {
            //minHeight: 728,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            background: theme.palette.common.white,
            width: '100%',
            '& .MuiPaper-root': {
                height: 'auto',
            },
            height: 'calc(100% - 60px)',
            overflowY: 'auto',
        },
        forModal: {
            height: '100%',
        },
        dailyMenu: {
            color: theme.palette.secondary.main,
        },
        itemAmount: {
            position: 'absolute',
            right: '5%',
            fontSize: '14px',
            color: alpha(theme.palette.common.black, 0.87),
            fontWeight: 'normal',
        },
        daysContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        dayButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 53,
            height: 35,
            fontWeight: 500,
            color: theme.palette.secondary.main,
            textTransform: 'uppercase',
            fontSize: 14,
            backgroundColor: theme.palette.secondary.light,
            borderRadius: 3,
            cursor: 'pointer',
            userSelect: 'none',
        },
        dayButtonSelected: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        chooseExistingContainer: {
            minHeight: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        chooseExistingTitle: {
            fontWeight: 500,
        },
        menuIngredientManagementContainer: {
            height: '100%',
            borderLeft: `1px solid ${theme.palette.secondary.light}`,
            marginLeft: -30,
            backgroundColor: alpha(theme.palette.secondary.light, 0.3),
            overflow: 'auto',
        },
        dishIngredientItemContainer: {
            paddingLeft: 20,
            paddingTop: 10,
        },
        dishIngredientItemHeader: {
            height: '30%',
            paddingBottom: 20,
        },
        chooseDishText: {
            fontSize: '16px',
            fontWeight: '700',
            color: theme.palette.secondary.main,
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
        },
        subTitle: {
            fontSize: '10px',
            fontWeight: '400',
        },
        ingredientText: {
            fontSize: '14px',
            fontWeight: '400',
            color: alpha(theme.palette.common.black, 0.6),
        },
        divider: {
            height: 1,
            backgroundColor: `${theme.palette.grey['300']}`,
            marginLeft: -20,
            marginBottom: 20,
        },
        dishTitle: { fontSize: '18px', fontWeight: '700', color: theme.palette.secondary.dark },
        dishDescription: {
            fontSize: '14px',
            fontWeight: '400',
            color: theme.palette.primary.dark,
        },
        priceText: {
            fontSize: '12px',
            color: theme.palette.primary.main,
        },
    })
);
