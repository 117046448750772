import { AppThunkAction } from '../index';
import agent from 'api/agent';
import globalStore from '../globalStore';
import { FinanceAction } from './actions';

export const actionCreators = {
    selectFinanceItem:
        (payload: any): AppThunkAction<FinanceAction> =>
        (dispatch) => {
            dispatch({
                type: 'SELECT_FINANCE_ITEM',
                payload,
            });
        },
    getFinance:
        (dateFrom: any, dateTo: any, restaurantId: string = ''): AppThunkAction<FinanceAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_FINANCE_DETAILS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.GetFinance(dateFrom, dateTo, restaurantId)
                .then((res) => {
                    dispatch({
                        type: 'GET_FINANCE_DETAILS_SUCCESS',
                        categories: res.categories,
                        orders: res.orders,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_FINANCE_DETAILS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
};
