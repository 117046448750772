import { Action, Reducer } from 'redux';

import { IngredientModel } from 'api/models';
import { TaxType } from 'constants/enums';
import { DaysWithHours } from 'components/menu/MenuManagementTable/components/AddDailyMenuSectionModal';
import { MenuDetailsAction } from './actions';

export interface MenuDetailsState {
    menu: MenuDetailsData;
}

export interface MenuDetailsData {
    id: string;
    name: string;
    description: string;
    availableFrom: number;
    availableTo: number;
    price: number;
    photo: string;
    type: number;
    sections: SectionData[];
    dishesCount: number;
    isEnabled: boolean;
    position: number;
    discount: number;
}

export interface SectionData {
    id: string;
    name: string;
    dishes: DishData[];
    price?: number;
    photo?: string;
    availability?: DaysWithHours;
    searchString?: string;
    isDisabled: boolean;
    color: string;
}

export interface ModifiedIngredient {
    id: string;
    isAdditionChecked?: boolean;
    isRemovalChecked?: boolean;
}

export interface DishData {
    id: string;
    sectionId: string;
    name: string;
    description: string;
    shortDescription: string;
    price: number;
    primeCost?: number;
    photo: string;
    isSpicy: boolean;
    isVegetarian: boolean;
    isVegan: boolean;
    isHalal: boolean;
    isDisabled: boolean;
    sectionName: string;
    sections: SectionData[];
    addToGallery: boolean;
    categoryId: string;
    dailyMenuId: string;
    allergens: string[];
    ingredients: IngredientModel[];
    modifiedIngredients?: ModifiedIngredient[];
    modified?: boolean;
    uniqueId?: string;
    vorortTax?: TaxType;
    homeDeliveryTax?: TaxType;
    takeAwayTax?: TaxType;
    barcode: string;
    stockQuantity: number;
    minimumStock: number;
    notFood: boolean;
    color: string;
}

const initialState: MenuDetailsState = {
    menu: {
        id: '',
        name: '',
        description: '',
        availableFrom: 0,
        availableTo: 0,
        price: 0,
        photo: '',
        type: 0,
        sections: [],
        dishesCount: 0,
        isEnabled: false,
        position: 0,
        discount: 0,
    },
};

export const reducer: Reducer<MenuDetailsState> = (
    state: MenuDetailsState | undefined,
    incomingAction: Action
): MenuDetailsState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as MenuDetailsAction;
    switch (action.type) {
        /* case 'MENU_GET_DETAILS_START':
            return {
                ...state,
                menu: {
                    id: '',
                    name: '',
                    description: '',
                    availableFrom: 0,
                    availableTo: 0,
                    price: 0,
                    photo: '',
                    type: 0,
                    sections: [],
                    dishesCount: 0,
                    isEnabled: false,
                    position: 0,
                    discount: 0,
                },
            }; */
        case 'MENU_GET_DETAILS_SUCCESS':
            return {
                ...state,
                menu: action.menu,
            };
        case 'MENU_RENAME_SECTION_SUCCESS':
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: state.menu.sections.map((x) =>
                        x.id !== action.menu.id
                            ? x
                            : ({
                                  id: action.menu.id,
                                  name: action.menu.name,
                                  dishes: x.dishes,
                              } as SectionData)
                    ),
                },
            };
        case 'MENU_ADD_NEW_ITEM_SUCCESS':
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: state.menu.sections.map((x) =>
                        x.id !== action.dish.sectionId
                            ? x
                            : {
                                  ...x,
                                  dishes: [...x.dishes, action.dish],
                              }
                    ),
                },
            };
        case 'ADD_EXISTING_DISH_TO_SECTION': {
            const sectionIndex = state.menu.sections.findIndex(
                (eachSection) => eachSection.id === action.sectionId
            );
            const newMenu = { ...state.menu };
            newMenu.sections[sectionIndex].dishes.push({
                ...action.dish,
                sectionId: action.sectionId,
            });
            return {
                ...state,
                menu: newMenu,
            };
        }
        case 'MENU_UPDATE_ITEM_SUCCESS': {
            const sectionIndex = state.menu.sections.findIndex(
                (section) => section.id === action.sectionId
            );
            const dishIndex = state.menu.sections[sectionIndex].dishes.findIndex(
                (sectionDish) => sectionDish.id === action.dish.id
            );

            const newMenu = { ...state.menu };
            newMenu.sections[sectionIndex].dishes[dishIndex] = action.dish;
            return {
                ...state,
                menu: newMenu,
            };
        }
        case 'DISABLE_DISH_SUCCESS': {
            const { dishId, sectionId } = action;

            const sectionIndex = state.menu.sections.findIndex(
                (section) => section.id === sectionId
            );
            const dishIndex = state.menu.sections[sectionIndex].dishes.findIndex(
                (sectionDish) => sectionDish.id === dishId
            );
            const newMenu = { ...state.menu };
            newMenu.sections[sectionIndex].dishes[dishIndex].isDisabled =
                !newMenu.sections[sectionIndex].dishes[dishIndex].isDisabled;

            return {
                ...state,
                menu: newMenu,
            };
        }
        case 'DISABLE_ENABLE_SECTION_SUCCESS': {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: state.menu.sections.map((x) =>
                        x.id !== action.payload ? x : { ...x, isDisabled: !x.isDisabled }
                    ),
                },
            };
        }
        case 'EDIT_DAILY_MENU_SECTION_SUCCESS': {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: state.menu.sections.map((x) =>
                        x.id !== action.id ? x : { ...x, name: action.name, price: action.price }
                    ),
                },
            };
        }
        case 'MENU_SECTIONS_REORDER_SUCCESS': {
            const sectionsCopy = [...state.menu.sections];
            sectionsCopy.sort(
                (a, b) => action.sections.indexOf(a.id) - action.sections.indexOf(b.id)
            );
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: sectionsCopy,
                },
            };
        }
        case 'MENU_SECTION_DISHES_REORDER_SUCCESS': {
            const sectionCopy = {
                ...state.menu.sections.find((sec) => sec.id === action.sectionId)!,
            };
            sectionCopy.dishes.sort(
                (a, b) => action.dishes.indexOf(a.id) - action.dishes.indexOf(b.id)
            );
            const sections = state.menu.sections.map((sec) =>
                sec.id === sectionCopy.id ? sectionCopy : sec
            );
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: sections,
                },
            };
        }
        case 'MENU_SECTION_SET_COLOR_SUCCESS':
            const targetSection = state.menu.sections.find((x) => x.id === action.sectionId);
            if (targetSection) {
                targetSection.color = action.color;
                targetSection.dishes.forEach((dish) => {
                    dish.color = action.color;
                });
            }
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: state.menu.sections.map((x) =>
                        x.id === targetSection?.id ? targetSection : x
                    ),
                },
            };
        case 'MENU_SECTION_DISH_SET_COLOR_SUCCESS': {
            const targetSection = state.menu.sections.find((x) => x.id === action.sectionId);
            if (targetSection) {
                const targetDish = targetSection.dishes.find((dish) => dish.id === action.dishId);
                if (targetDish) {
                    targetDish.color = action.color;
                }
            }
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: state.menu.sections.map((x) =>
                        x.id === targetSection?.id ? targetSection : x
                    ),
                },
            };
        }
        case 'MENU_SECTION_DISH_COPY_SUCCESS': {
            const targetSection = state.menu.sections.find((x) => x.id === action.sectionId);
            if (targetSection) {
                targetSection.dishes.push(action.dish);
            }
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: state.menu.sections.map((x) =>
                        x.id === targetSection?.id ? targetSection : x
                    ),
                },
            };
        }
        default:
            return state;
    }
};
