import { createStyles, makeStyles, Theme } from '@material-ui/core';
import commonStyles from 'config/commonStyles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        headerContainer: {
            display: 'flex',
            alignItems: 'center',
            height: 55,
            padding: '0px 13px 0 65px',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${theme.palette.grey['300']}`,
        },
        flexRowAlignCenter: {
            minWidth: 100,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
        },
        backArrow: { cursor: 'pointer', width: '24px', height: '24px', marginRight: '20px' },
        headerButtonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        tab: {
            padding: '0 0.5%',
            width: '100%',
            minWidth: 100,
            height: '100%',
            '& .MuiTab-wrapper': {
                letterSpacing: 1.25,
            },
            '& .MuiTabs-flexContainer': {
                height: '100%',
            },
            '& button': {
                width: '100%',
            },
        },
        formContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            backgroundColor: theme.palette.common.white,
        },
        formInnerContainer: {
            paddingLeft: '40px',
            width: '30%',
        },
        percentageInput: {
            marginBottom: '20px',
            '& input[type=number]': {
                width: 30,
            },
        },
    })
);
