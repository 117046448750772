import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { ApplicationState } from 'store';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        '& .MuiSnackbarContent-root': {
            color: theme.palette.common.white,
        },
    },
    success: {
        backgroundColor: '#5FCF55',
    },
    warning: {
        backgroundColor: '#E1BA2E',
    },
    info: {
        backgroundColor: '#2196f3',
    },
    error: {
        backgroundColor: '#D45336',
    },
}));

export default function ToastrNotification() {
    const dispatch = useDispatch();
    const showToaster = useSelector((state: ApplicationState) => state.global.showToaster);
    const toasterType = useSelector((state: ApplicationState) => state.global.toasterType);
    const message = useSelector((state: ApplicationState) => state.global.toasterMessage);
    const classes = useStyles();

    function handleClose(event?: React.SyntheticEvent, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }

        dispatch({
            type: 'GLOBAL_HIDE_TOASTER',
        });
    }

    return (
        <div className={classes.root}>
            <Snackbar open={showToaster} autoHideDuration={3000} onClose={handleClose}>
                <SnackbarContent
                    message={message ? message : showToaster}
                    className={classes[toasterType]}
                    action={
                        <div
                            style={{
                                cursor: 'pointer',
                                textTransform: 'uppercase',
                                paddingRight: '16px',
                            }}
                            onClick={handleClose}
                        >
                            {i18n.t('button.close')}
                        </div>
                    }
                />
            </Snackbar>
        </div>
    );
}
