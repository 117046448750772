import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import i18n from 'i18n-js';
import { Button, createStyles, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';

import commonStyles from 'config/commonStyles';
import { ApplicationState } from 'store';
import { getFinanceTotalAmount } from 'components/restaurantInformation/finance/logic';
//import styles from '../styles';
import { images } from 'assets';

interface Props {
    tabs?: string[];
    toUpdate: boolean;
    onAddClick: () => void;
    onBackClick: () => void;
    activeTab: number;
    onTabChange: (i: number) => void;
    formChanged: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        headerContainer: {
            display: 'flex',
            alignItems: 'center',
            height: 55,
            padding: '0px 13px 0 28px',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${theme.palette.grey['300']}`,
        },
        flexRowAlignCenter: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
        },
        backArrow: { cursor: 'pointer', width: '24px', height: '24px', marginRight: '20px' },
        headerButtonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        tab: {
            padding: '0 0.5%',
            width: '100%',
            height: '100%',
            '& .MuiTab-wrapper': {
                letterSpacing: 1.25,
            },
            '& .MuiTabs-flexContainer': {
                height: '100%',
            },
        },
    })
);

export default function CreateRestaurantHeader({
    tabs = [
        i18n.t('restaurant.newRestaurant'),
        i18n.t('restaurant.restaurantUsers'),
        i18n.t('restaurant.finance'),
    ],
    toUpdate,
    onAddClick,
    onBackClick,
    onTabChange,
    formChanged = false,
}: Props) {
    const financeDetails = useSelector((state: ApplicationState) => state.finance.financeDetails);
    const financeItem = useSelector((state: ApplicationState) => state.finance.financeItem);

    const [view, setView] = useState(0);
    const classes = useStyles();

    useEffect(() => {
        checkLastTab();

        return () => {
            removeLastTab();
        };
    }, []); // eslint-disable-line

    function checkLastTab() {
        const lastTab = localStorage.getItem('@tab');
        if (lastTab) {
            const index = parseInt(lastTab);
            setView(index);
            onTabChange(index);
        }
    }

    function saveLastTab(index: number) {
        localStorage.setItem('@tab', index.toString());
    }

    function removeLastTab() {
        localStorage.removeItem('@tab');
    }

    function financeTotalAmountContainer() {
        return (
            <div className={`${classes.tabPriceContainer} ${classes.tabPriceAlignRight10}`}>
                <h4>{i18n.t('common.total')}: </h4>
                <div>
                    <span className={classes.currencyText}>{i18n.t('common.currencies.chf')}</span>
                    <span className={classes.currencyAmount}>
                        {getFinanceTotalAmount(financeItem ? [financeItem] : financeDetails)}
                    </span>
                </div>
            </div>
        );
    }

    function changeTab(event: React.ChangeEvent<{}>, view: number) {
        setView(view);
    }

    return (
        <div className={classes.headerContainer}>
            <div className={classes.flexRowAlignCenter}>
                <img
                    src={images.icons.backArrow}
                    className={classes.backArrow}
                    onClick={onBackClick}
                    alt="back-arrow"
                />
                <Tabs
                    value={view}
                    onChange={changeTab}
                    aria-label="wrapped label tabs example"
                    indicatorColor="primary"
                    textColor="primary"
                    className={classes.tab}
                >
                    {tabs.map((tab, i) => (
                        <Tab
                            key={tab}
                            style={{ textTransform: tabs.length === 1 ? 'none' : '-moz-initial' }}
                            value={i}
                            label={tab}
                            onClick={() => {
                                onTabChange(i);
                                saveLastTab(i);
                            }}
                        />
                    ))}
                </Tabs>
            </div>

            {view === 2 && financeTotalAmountContainer()}
            <div style={{ visibility: view === 0 ? 'visible' : 'hidden' }}>
                <div className={classes.headerButtonContainer}>
                    <Button
                        /* style={styles.button} */
                        onClick={() => onAddClick()}
                        variant="contained"
                        color="primary"
                        disabled={!formChanged}
                    >
                        {toUpdate
                            ? i18n.t('common.saveChanges')
                            : i18n.t('restaurant.addRestaurant')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
