import { AppThunkAction } from '../index';
import { GlobalAction } from './actions';

export type ToasterType = 'success' | 'info' | 'warning' | 'error';

export type LangCode = 'en' | 'de';

export const actionCreators = {
    showSpiner: (): AppThunkAction<GlobalAction> => (dispatch) => {
        dispatch({
            type: 'GLOBAL_SHOW_SPINER',
        });
    },
    hideSpiner: (): AppThunkAction<GlobalAction> => (dispatch) => {
        dispatch({
            type: 'GLOBAL_HIDE_SPINER',
        });
    },
    showToaster:
        (type: ToasterType = 'info', message: string = ''): AppThunkAction<GlobalAction> =>
        (dispatch) => {
            dispatch({
                type: 'GLOBAL_SHOW_TOASTER',
                toasterMessage: message,
                toasterType: type,
            });
        },
    hideToaster: (): AppThunkAction<GlobalAction> => (dispatch) => {
        dispatch({
            type: 'GLOBAL_HIDE_TOASTER',
        });
    },
    changeLanguage:
        (language: LangCode): AppThunkAction<GlobalAction> =>
        (dispatch) => {
            dispatch({
                type: 'GLOBAL_CHANGE_LANGUAGE',
                code: language,
            });
        },
};
