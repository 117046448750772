import { Action, Reducer } from 'redux';
import { FinanceAction } from './actions';

export interface FinanceDishData {
    amount: number;
    name: string;
    number: number;
    price: number;
}
export interface FinanceServicesData {
    dishes: FinanceDishData[];
    name: string;
    totalAmount: number;
    cancelledDishes: FinanceDishData[];
}

export interface FinanceData {
    amount: number;
    name: string;
    services: FinanceServicesData[];
}

export interface FinanceOrderData {
    id: string;
    createDate: string;
    service: string;
    cancelled?: boolean;
    totalAmount: number;
}
export interface FinanceState {
    financeItem: null | FinanceData;
    financeDetails: null | FinanceData[];
    financeOrders: FinanceOrderData[];
}

const initialState: FinanceState = {
    financeItem: null,
    financeDetails: null,
    financeOrders: [],
};

export const reducer: Reducer<FinanceState> = (
    state: FinanceState | undefined,
    incomingAction: Action
): FinanceState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as FinanceAction;
    switch (action.type) {
        case 'SELECT_FINANCE_ITEM':
            return {
                ...state,
                financeItem: action.payload,
            };
        case 'GET_FINANCE_DETAILS_SUCCESS': {
            let updatedFinanceItem: undefined | FinanceData | null = null;
            if (state.financeItem) {
                updatedFinanceItem = action.categories.find(
                    (eachItem) => eachItem.name === state.financeItem?.name
                );
                updatedFinanceItem = !updatedFinanceItem ? null : updatedFinanceItem;
            }
            return {
                ...state,
                financeDetails: action.categories,
                financeOrders: action.orders,
                financeItem: updatedFinanceItem,
            };
        }
        default:
            return state;
    }
};
