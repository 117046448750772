import React, { PropsWithChildren, ReactNode } from 'react';

import { Toolbar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    rightActions: {
        '& button': {
            marginLeft: 15,
        },
    },
});

interface TableToolbarProps {
    rightActions: ReactNode | ReactNode[];
}

export default function TableToolbar(props: PropsWithChildren<TableToolbarProps>) {
    const classes = useStyles();
    return (
        <Toolbar>
            <div>{props.children}</div>
            <div className={classes.rightActions}>{props.rightActions}</div>
        </Toolbar>
    );
}
