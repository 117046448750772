import { AppThunkAction } from 'store';

import i18n from 'i18n-js';

import agent from 'api/agent';
import { RestaurantFilterModel } from 'api/models';
import globalStore from '../globalStore';
import { RestaurantsAction } from './actions';
import { RestaurantCreateModel, RestaurantData } from './reducer';

export const actionCreators = {
    getRestaurants:
        (
            restaurantFilterModel: RestaurantFilterModel | null = null,
            page?: number,
            limit?: number,
            asc?: boolean,
            orderBy?: string
        ): AppThunkAction<RestaurantsAction> =>
        (dispatch) => {
            dispatch({
                type: 'RESTAURANTS_GET_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.GetList(restaurantFilterModel, page, limit, asc, orderBy)
                .then((response) =>
                    dispatch({ type: 'RESTAURANTS_GET_SUCCESS', restaurantPagedModel: response })
                )
                .catch((e) => {
                    dispatch({ type: 'RESTAURANTS_GET_ERROR' });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    createRestaurant:
        (model: RestaurantCreateModel): AppThunkAction<RestaurantsAction> =>
        (dispatch) => {
            dispatch({
                type: 'RESTAURANTS_CREATE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.Create(model)
                .then((response) => {
                    dispatch({
                        type: 'RESTAURANTS_CREATE_SUCCESS',
                        new: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.createdSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'RESTAURANTS_CREATE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    switchRestaurantStatus:
        (restaurant: RestaurantData): AppThunkAction<RestaurantsAction> =>
        (dispatch) => {
            dispatch({
                type: 'RESTAURANT_SWITCH_STATUS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Restaurants.DisableRestaurant(restaurant.id)
                .then(() => {
                    restaurant.isDisabled = !restaurant.isDisabled;
                    dispatch({
                        type: 'RESTAURANT_SWITCH_STATUS_SUCCESS',
                        data: restaurant,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'RESTAURANT_SWITCH_STATUS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
};
