import { makeStyles } from '@material-ui/core';
import { theme } from 'config/theme';
import commonStyles from 'config/commonStyles';

export const useStyles = makeStyles({
    ...commonStyles,
    navbarList: {
        width: 'auto',
        transition: 'padding 0.5s',
    },
    navbarListOpened: {
        padding: '0 8px',
    },
    navbarListCollapsed: {
        padding: '0 15px',
        '& .MuiListItem-gutters': {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& .MuiListItem-root .MuiListItemIcon-root': {
            width: '100%',
        },
    },
    tabActive: {
        backgroundColor: theme.palette.background.default,
        opacity: 0.8,
        color: theme.palette.primary.dark,
        borderRadius: 4,
        '& .MuiTab-wrapper': {
            opacity: 0.7,
        },
        '& .MuiTypography-root.MuiListItemText-primary': {
            color: theme.palette.primary.main,
        },
    },
    navbarItem: {
        width: 'auto',
        transition: 'padding 0.5s',
        '& .MuiListItem-root .MuiListItemIcon-root': {
            marginRight: 0,
            minWidth: 32,
            justifyContent: 'center',
        },
        '& .MuiListItem-root': {
            height: 40,
            margin: '5px 0',
            cursor: 'pointer',
        },
        '& .MuiListItemText-root': {
            margin: '0 0 0 25px',
        },
        '& .MuiListItem-gutters': {
            transition: 'padding 0.5s',
        },
    },
    imageContainer: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 24,
        width: 24,
    },
    listItemText: {
        color: theme.palette.primary.dark,
        opacity: 1,
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 600,
        marginLeft: 25,
    },
    navbarSectionHeder: {
        padding: '0 0 0 16px',
        margin: '5px 0',
        height: 40,
        transition: 'display 0.5s',
        '& .MuiListItemText-root': {
            margin: 0,
        },
        '& .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1': {
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.text.disabled,
        },
    },
    navbarSectionHederOpened: {
        display: 'flex',
    },
    navbarSectionHederCollapsed: {
        display: 'none',
        height: '10px !important',
    },
    root: {
        padding: 0,
        '& .MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1': {
            filter: 'none',
        },
        '& .MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd': {
            marginRight: -12,
            flexGrow: 1,
        },
        '& .MuiAccordion-root:before': {
            content: 'unset',
        },
        '& .MuiList-root': {
            width: '100%',
        },
        '& .MuiListItem-root': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        '& .MuiListItem-divider:first-child': {
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        },
        '& .MuiAccordionDetails-root': {
            padding: 0,
        },
    },
    header: {
        '& .MuiTypography-root.MuiTypography-body1': {
            fontSize: 18,
            fontWeight: 500,
            color: theme.palette.primary.dark,
        },
    },
    headerHidden: {
        '& .MuiAccordionSummary-content': {
            //display: 'none',
        },
        '& .MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd': {
            display: 'none',
        },
        '& .MuiTypography-root.MuiTypography-body1': {
            display: 'none',
        },
        '& .MuiListItem-gutters img': {
            marginLeft: 5,
        },
    },
    switchButton: {
        ...commonStyles.flexRowCenter,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '70px !important',
        width: 40,
        borderRadius: 50,
        color: theme.palette.common.white,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    usersChooseDropdown: {
        '& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded': {
            top: '60px !important',
            width: 468,
        },
        '& .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters': {
            padding: '0 24px',
        },
        '& .MuiListItem-root.MuiListItem-gutters.MuiListItem-divider.Mui-selected:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.primary.light,
        },
        '& .MuiListItem-root.MuiListItem-gutters.MuiListItem-divider.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
        },
        '& .MuiList-root.MuiMenu-list': {
            width: '100%',
        },
    },
    usersChooseDropdownTitle: {
        fontWeight: '500',
        fontSize: 20,
        padding: '16px 0 16px 24px',
    },
    logoContainer: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 32,
        width: 32,
        borderRadius: 100,
        marginRight: 16,
    },
    buttonsArea: {
        ...commonStyles.flexRowEnd,
        margin: '45px 0',
        padding: '0 24px',
        '& button': {
            marginLeft: 20,
        },
    },
    newOrdersCount: {
        ...commonStyles.flexRowCenter,
        borderRadius: 50,
        height: 12,
        fontSize: 9,
        fontWeight: 500,
        position: 'absolute',
        zIndex: 99,
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
        bottom: 25,
        transition: 'left 0.3s',
    },
    overdudedPannel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.error.main,
        padding: '0 8px',
        height: '40px',
        borderRadius: 4,
        color: theme.palette.common.white,
        marginBottom: 10,
        cursor: 'pointer',
    },
    overdudedTable: {
        '& .MuiTypography-root.MuiTypography-h6, .MuiDialogContentText-root': {
            color: theme.palette.error.main,
        },
        '& .MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        '& .MuiButton-text': {
            color: theme.palette.primary.main,
        },
        '& table .MuiTableCell-root.MuiTableCell-body': {
            color: theme.palette.common.black,
        },
        '& .MuiDialogActions-root': {
            paddingRight: 24,
        },
    },
    warningTitle: {
        color: theme.palette.error.light,
    },
    warningSubTitle: {
        color: theme.palette.error.light,
        fontSize: '16px',
        textAlign: 'left',
        fontWeight: 'bold',
    },
    warningHours: {
        color: theme.palette.error.light,
        marginLeft: '5px',
    },
    contentContainer: {
        display: 'flex',
    },
    closeButton: {
        minWidth: '101px',
    },
});
