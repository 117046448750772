import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Paper, Tab, Tabs } from '@material-ui/core';
import i18n from 'i18n-js';
import { parsePhoneNumber } from 'react-phone-number-input';

import { ApplicationState } from 'store';
import { default as restaurantStore } from 'store/restaurantDetailsStore';
import categoryStore from 'store/categoryStore';
import financeStore from 'store/financeStore';
import { getCoordinatesFromTheAddress, trimLeftAndRight } from 'helpers/helperFunctions';
import QR from './qr';
import { ContactPersons, Categories, RestaurantDetails } from './components';
import { images } from 'assets';
import styles from './styles';
import PaymentDetails from './payment-details';
import globalStore from 'store/globalStore';
import { ImageContainerRefProps } from 'components/shared/components/ImageContainer';
import { HourData } from './components/AddWorkingHoursModal';

export default function RestaurantInformation() {
    const dispatch = useDispatch();
    const categoryDetails = useSelector(
        (state: ApplicationState) => state.category.categoryDetails
    );
    const restaurantInformation = useSelector(
        (state: ApplicationState) => state.restaurantDetails.restaurantInformation
    );
    const financeItem = useSelector((state: ApplicationState) => state.finance.financeItem);
    const browserData = useSelector((state: ApplicationState) => state.user.browserData);
    const [view, setView] = useState(1);
    const [formChanged, setFormChanged] = useState(false);
    const formikRef: any = useRef(null);
    const logoUploadRef = useRef<ImageContainerRefProps>(null);
    const photoUploadRef = useRef<ImageContainerRefProps>(null);
    const paymentDetailsRef = useRef(null);
    const classes = styles();
    const [orderBy, setOrderBy] = useState('name');
    const [asc, setAsc] = useState(true);
    const orderNames: string[] = ['name', 'count'];
    const [downloadQrName, setDownloadQrName] = useState<string>('');

    useEffect(() => {
        dispatch(restaurantStore.actionCreators.getRestaurantInformation());
        dispatch(categoryStore.actionCreators.getCategoryDetails(asc, orderBy));
        dispatch(restaurantStore.actionCreators.getCuisineTypes());

        checkLastTab();

        return () => {
            removeLastTab();
        };
    }, []); // eslint-disable-line

    function checkLastTab() {
        const lastTab = localStorage.getItem('@tab');
        if (lastTab) {
            setView(parseInt(lastTab));
        }
    }

    function saveLastTab(index: number) {
        localStorage.setItem('@tab', index.toString());
    }

    /**
     * Removes last used tab in case of user leaves this restaurant information page
     */
    function removeLastTab() {
        localStorage.removeItem('@tab');
    }

    function changeTab(event: React.ChangeEvent<{}>, view: number) {
        if (view === -1) {
            return dispatch(financeStore.actionCreators.selectFinanceItem(null));
        }
        saveLastTab(view);
        setView(view);
    }

    function handleOrder(id: string) {
        if (id === orderBy) {
            dispatch(categoryStore.actionCreators.getCategoryDetails(!asc, orderBy));
            setAsc(!asc);
        } else {
            setOrderBy(id);
            dispatch(categoryStore.actionCreators.getCategoryDetails(asc, id));
        }
    }

    function createCategory(
        categoryName: string,
        dishes: string[],
        openHours: HourData[],
        position: string
    ) {
        dispatch(
            categoryStore.actionCreators.createCategory({
                Name: categoryName,
                Dishes: dishes,
                openHours,
                position,
            })
        );
    }

    function editCategory(
        categoryName: string,
        dishes: string[],
        openHours: HourData[],
        position: string,
        categoryId: string
    ) {
        dispatch(
            categoryStore.actionCreators.editCategory(
                {
                    Name: categoryName,
                    Dishes: dishes,
                    openHours,
                    position,
                },
                categoryId
            )
        );
    }

    function disableOrEnableCategory(categoriesIds: string[]) {
        dispatch(categoryStore.actionCreators.disableOrEnableCategory(categoriesIds));
    }

    function renderTabs() {
        switch (view) {
            case 1:
                return (
                    <>
                        <RestaurantDetails
                            onFormChange={() => setFormChanged(true)}
                            restaurantInformation={restaurantInformation}
                            formikRef={formikRef}
                            logoUploadRef={logoUploadRef}
                            photoUploadRef={photoUploadRef}
                        />
                    </>
                );
            case 2:
                if (categoryDetails)
                    return (
                        <>
                            <Categories
                                categoryDetails={categoryDetails}
                                onCreate={createCategory}
                                onEdit={editCategory}
                                onDisableOrEnable={disableOrEnableCategory}
                                onOrder={handleOrder}
                                orderBy={orderBy}
                                orderNames={orderNames}
                                asc={asc}
                            />
                        </>
                    );
                return;
            case 3:
                return <ContactPersons />;
            case 4:
                return (
                    <PaymentDetails
                        paymentDetailsRef={paymentDetailsRef}
                        onFormChange={setFormChanged}
                    />
                );
            case 5:
                return (
                    <QR
                        restaurantId={restaurantInformation.id}
                        downloadQrName={downloadQrName}
                        setDownloadQrName={setDownloadQrName}
                    />
                );
        }
    }

    function resetForm() {
        dispatch({
            type: 'RESTAURANT_DETAILS_GET_START',
        });

        formikRef.current.resetForm();
        setFormChanged(false);

        setTimeout(() => {
            dispatch({
                type: 'SET_RESTAURANT_IS_LOADING_FALSE',
            });
        }, 250);
    }

    async function updateRestaurantInformation() {
        const errors = formikRef.current.errors;
        const errorsLength = Object.keys(errors).length;
        function hasAnyService() {
            const { hasHomeDelivery, hasOnSite, hasTakeAway } = formikRef.current.values;
            return !!(hasHomeDelivery || hasOnSite || hasTakeAway);
        }
        if (!hasAnyService()) {
            return;
        }
        if (errorsLength === 0) {
            let { logoUrl, photoUrl } = restaurantInformation;
            const formValues = { ...formikRef.current.values };
            const typeIds: string[] = [];

            Object.keys(formValues.type).forEach((x) => {
                typeIds.push(formValues.type[x].id);
            });

            if (!typeIds.length) {
                return formikRef.current.setFieldError('key', 'val');
            }

            formValues.type = typeIds;

            dispatch({
                type: 'UPDATE_RESTAURANT_INFORMATION_START',
            });

            if (
                formValues.latitude === 0 ||
                formValues.longitude === 0 ||
                formValues.addressLine !== restaurantInformation.addressLine ||
                restaurantInformation.city !== formValues.city ||
                restaurantInformation.zip !== formValues.zip
            ) {
                const { latitude, longitude, timeZone } = await getCoordinatesFromTheAddress(
                    `${formValues.addressLine}, ${formValues.zip} ${formValues.city}`
                );

                if (latitude === 0 || longitude === 0) {
                    dispatch(
                        globalStore.actionCreators.showToaster(
                            'warning',
                            i18n.t('warnings.writeMoreDetailedAddress')
                        )
                    );
                    dispatch({
                        type: 'UPDATE_RESTAURANT_INFORMATION_ERROR',
                    });
                    return;
                }

                formValues.latitude = latitude;
                formValues.longitude = longitude;
                formValues.timeZone = timeZone;
            }

            if (formValues.logoUrl !== logoUrl) {
                const logoUrl = await logoUploadRef.current?.uploadImage();
                formValues.logoUrl = logoUrl;
            }
            if (formValues.photoUrl !== photoUrl) {
                const photoUrl = await photoUploadRef.current?.uploadImage();
                formValues.photoUrl = photoUrl;
            }

            /**
             * Required to avoid backend validation errors
             * time format should be with dots - 10.30
             */
            for (const [, value] of Object.entries(formValues.openHours)) {
                // eslint-disable-next-line
                (value as any).workingHours.map((hour: { from: string; to: string }) => {
                    hour.from = hour.from.toString().replace(':', '.');
                    hour.to = hour.to.toString().replace(':', '.');
                });
            }

            const parsedPhoneNumber = parsePhoneNumber(formValues.phoneNumber);
            if (parsedPhoneNumber) {
                if (!parsedPhoneNumber.country) {
                    formValues.phoneNumber = formValues.phoneNumber.replace(
                        '+' + parsedPhoneNumber.countryCallingCode.toString(),
                        browserData?.country_calling_code
                    );
                }
            }

            dispatch(
                restaurantStore.actionCreators.updateRestaurantInformation({
                    ...formValues,
                    name: trimLeftAndRight(formValues.name),
                    addressLine: trimLeftAndRight(formValues.addressLine),
                    city: trimLeftAndRight(formValues.city),
                    website: trimLeftAndRight(formValues.website),
                    facebook: trimLeftAndRight(formValues.facebook),
                    instagram: trimLeftAndRight(formValues.instagram),
                    description: trimLeftAndRight(formValues.description),
                })
            );
            setFormChanged(false);
        } else {
            Object.keys(errors).forEach(function (key, index) {
                const val = errors[key];
                formikRef.current.setFieldError(key, val);
            });
        }
    }

    function getTabs() {
        let tabs = [];
        if (financeItem) {
            tabs.push(
                <Tab
                    key="0"
                    style={{ textTransform: 'none' }}
                    value={-1}
                    label={
                        <div className={classes.flexRow}>
                            <img
                                src={images.icons.backArrow}
                                alt="back-arrow"
                                className={`${classes.smallIcon} ${classes.mRight20}`}
                            />
                            <span className={classes.financeTabText}>{financeItem.name}</span>
                        </div>
                    }
                />
            );
        } else {
            tabs = [
                <Tab key="1" value={1} label={i18n.t('restaurant.restaurantDetails')} />,
                <Tab key="2" value={2} label={i18n.t('common.categories')} />,
                <Tab key="3" value={3} label={i18n.t('restaurant.contactPersons')} />,
                <Tab key="4" value={4} label={i18n.t('restaurant.paymentDetails')} />,
                <Tab key="5" value={5} label={i18n.t('restaurant.qr')} />,
                view === 1 && (
                    <Tab
                        key="6"
                        value={5}
                        disabled={true}
                        component={React.forwardRef((props, ref) => (
                            <div className={classes.tabButtons}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => resetForm()}
                                >
                                    {i18n.t('common.cancel')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!formChanged}
                                    onClick={() => updateRestaurantInformation()}
                                >
                                    {i18n.t('common.saveChanges')}
                                </Button>
                            </div>
                        ))}
                    />
                ),
            ];
        }

        if (view === 4) {
            tabs.push(
                <Tab
                    key="tab"
                    disabled={true}
                    component={React.forwardRef((props, ref) => (
                        <Button
                            className={classes.saveChangesButton}
                            variant="contained"
                            color="primary"
                            disabled={!formChanged}
                            onClick={() => {
                                // @ts-ignore
                                paymentDetailsRef.current.submitForm();
                            }}
                        >
                            {i18n.t('common.saveChanges')}
                        </Button>
                    ))}
                />
            );
        } else if (view === 5) {
            tabs.push(
                <Tab
                    key="6"
                    value={6}
                    component={() => (
                        <Button
                            className={classes.saveChangesButton}
                            variant="contained"
                            disabled={downloadQrName === restaurantInformation.qrCodesName}
                            color="primary"
                            onClick={() => {
                                // @ts-ignore
                                dispatch(actionCreators.updateQrCodesName(downloadQrName));
                            }}
                        >
                            {i18n.t('common.saveChanges')}
                        </Button>
                    )}
                />
            );
        }
        return tabs;
    }

    return (
        <>
            <Paper className={classes.mainContainer}>
                <Tabs
                    id="hide-in-print"
                    className={`${classes.tab} ${financeItem ? classes.noPadding : ''}`}
                    value={view}
                    onChange={changeTab}
                    aria-label="wrapped label tabs example"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {getTabs()}
                </Tabs>
                <div className={classes.sectionContainer}>{renderTabs()}</div>
            </Paper>
        </>
    );
}
