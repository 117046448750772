import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import Highlighter from 'react-highlight-words';
import {
    alpha,
    Button,
    Card,
    Checkbox,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    InputAdornment,
    FormControl,
    InputLabel,
    Input,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CreateIcon from '@material-ui/icons/Create';
import moment from 'moment';
import clsx from 'clsx';
import * as yup from 'yup';

import categoryStore from 'store/categoryStore';
import { default as ordersStore } from 'store/ordersStore';
import { images } from 'assets';
import {
    OrderDishStatus,
    OrderStatus,
    PaymentMethod,
    Roles,
    OrderServices,
    Positions,
} from 'constants/enums';
import { OrderItemStatusChangeModel, OrderUpdateModel } from 'api/models';
import { priceStyleHelper } from 'helpers/helperFunctions';
import { theme } from 'config/theme';
import { PopupConfirmation, SectionTab, Text } from 'components/shared';
import OrderStatusItem from './OrderStatusItem';
import ServiceType from './ServiceType';
import DeliveryTime from './DeliveryTime';
import OrderTime from './OrderTime';
import MenusModal from 'components/menu/Modals/MenusModal';
import OrderPayment from './OrderPayment';
import OrderQRLeftCode from './OrderQrLeftCode';
import { useStyles } from './styles';
import MenuIngredientManagement from 'components/menu/MenuManagementTable/components/MenuIngredientManagement';
import { useAppSelector } from 'index';
import ResponsibleUserSelect from './ResponsibleUserSelect';
import { Formik, Form } from 'formik';
import {
    OrderDetails as OrDetails,
    OrderItem,
    OrderDetailsItem,
    ModifiedIngredient,
} from 'store/ordersStore/reducer';

const dailyMenuKey = 'Daily Menu';

//TODO: need to refactor and simplify logic
export default function OrderDetails() {
    const classes = useStyles();
    const initialOrder: OrDetails = {
        id: '',
        orderNumber: 0,
        createDate: 0,
        orderItems: [],
        status: OrderStatus.New,
        isPaid: false,
        totalAmount: 0,
        firstName: '',
        lastName: '',
        addressLine: '',
        city: '',
        zip: '',
        phoneNumber: '',
        email: '',
        comment: '',
        deliveryTime: 0,
        service: OrderServices.OrderInRestaurant,
        paymentMethod: PaymentMethod.None,
        qrCodeName: '',
    };
    const dispatch = useDispatch();
    const orders = useAppSelector((state) => state.orders);
    const orderDetails = useAppSelector((state) => state.orders.orderDetails);
    const category = useAppSelector((state) => state.category);
    const user = useAppSelector((state) => state.user);
    const currentRestaurant = useAppSelector((state) =>
        state.user.restaurants.find((rest) => rest.id === state.user.currentRestaurantId)
    );
    const itemsToAdd = useAppSelector((state) => state.orders.itemsToAdd);

    const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
    const [showFilter, setShowFilter] = useState(false);
    const [editOrder, setEditOrder] = useState(false);
    const [initialOrderState, setInitialOrderState] = useState<OrDetails>({
        ...initialOrder,
    });
    const [hasChanged, setHasChanged] = useState(false);
    const [showMenus, setShowMenus] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(0);
    const [saved, setSaved] = useState(false);
    const [showIngredients, setShowIngredients] = useState(false);
    const [canEditOrder, setCanEditOrder] = useState(false);
    const [editedComment, setEditedComment] = useState<string>('');
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        category.categoryDetails === null &&
            dispatch(categoryStore.actionCreators.getCategoryDetails());
        if (!!orders.orderDetailsId) {
            refreshCurrentState();
        }
    }, [
        orders.orderDetailsId,
        orderDetails.status === OrderStatus.Cancel,
        orderDetails.status === OrderStatus.Closed,
        orders.needToUpdate,
        orders.orderDetails.deliveryTime,
    ]);

    useEffect(() => {
        if (listRef.current !== null) {
            listRef.current.scrollTop = 0;
        }
    }, [orders.orderDetailsId]);

    useEffect(() => {
        setCanEditOrder(
            currentRestaurant?.currentPosition !== Positions.COOK &&
                currentRestaurant?.currentPosition !== Positions.MANAGER
        );
    }, [currentRestaurant?.currentPosition]);

    const validationSchema = yup.object().shape({
        comment: yup.string().required().max(256),
    });

    function refreshCurrentState() {
        dispatch(
            ordersStore.actionCreators.getOrderDetails(
                orders.orderDetailsId,
                undefined,
                setInitialOrderState
            )
        );
        setShowFilter(false);
        setSelectedFilter([]);
        setEditOrder(false);
    }

    const showAddButton = (isEmpty: boolean) =>
        canEditOrder &&
        (editOrder ||
            (!!orders.orderDetailsId && isEmpty && orderDetails.status !== OrderStatus.Cancel));

    function handleCheckingItems(id: string, dailyMenuId: string) {
        const model: OrderItemStatusChangeModel = {
            orderId: orderDetails.id,
            itemId: id,
            dailyMenuId: dailyMenuId,
        };
        let updatedOrder = {
            ...orderDetails,
            orderItems: orderDetails.orderItems.map((x) =>
                x.id === id && x.dailyMenuId === dailyMenuId
                    ? {
                          ...x,
                          status:
                              x.status !== OrderDishStatus.Ready
                                  ? OrderDishStatus.Ready
                                  : OrderDishStatus.None,
                      }
                    : x
            ),
        };
        if (
            updatedOrder.orderItems.filter((x) => x.status === OrderDishStatus.Ready).length ===
            updatedOrder.orderItems.length
        ) {
            updatedOrder.status = OrderStatus.Ready;
        } else {
            updatedOrder.status = OrderStatus.InProgress;
        }
        dispatch(ordersStore.actionCreators.changeOrderItemStatus(model));
    }

    function handleSelectingFilter(id: string) {
        if (selectedFilter.includes(id)) {
            setSelectedFilter(selectedFilter.filter((x) => x !== id));
        } else {
            setSelectedFilter([...selectedFilter, id]);
        }
    }

    /* function resetEditedOrders() {
        let editedOrder = { ...orderDetails };
        editedOrder.orderItems.forEach((item) => {
            item.modifiedByManagement = 0;
        });
        dispatch(OrdersStore.actionCreators.setOrderDetails(editedOrder));
    } */

    function saveChanges(model: OrderUpdateModel | null = null) {
        if (!model) {
            model = {
                orderItems: [...orderDetails.orderItems],
                deliveryTime: orderDetails.deliveryTime,
                status: OrderStatus.Non,
                paymentMethod: orderDetails.paymentMethod,
                qrCodeName: orderDetails.qrCodeName,
                service: orderDetails.service,
            };
            if (itemsToAdd.length) {
                itemsToAdd.forEach((item) => {
                    const existsItem = model!.orderItems.find(
                        (it) => it.id === item.id && it.status === OrderDishStatus.None
                    );
                    if (existsItem) {
                        if (
                            JSON.stringify(existsItem.modifiedIngredients) ===
                            JSON.stringify(item.modifiedIngredients || [])
                        ) {
                            const index = model!.orderItems.indexOf(existsItem);
                            if (index !== -1) {
                                model!.orderItems[index].amount += item.amount;
                            }
                        } else {
                            model!.orderItems.push(item);
                        }
                    } else {
                        model!.orderItems.push(item);
                    }
                });
            }
        }
        if (model.status === OrderStatus.Cancel)
            dispatch(
                ordersStore.actionCreators.setOrderDetails({
                    ...orderDetails,
                    status: OrderStatus.Cancel,
                })
            );
        dispatch(
            ordersStore.actionCreators.updateOrder(orderDetails.id, model, () => {
                setSaved(true);
                //resetEditedOrders();
                setEditOrder(false);
                setShowMenus(false);
            })
        );
        //setInitialOrderState({ ...initialOrder });
        //setTimeout(refreshCurrentState, 500);
    }

    function saveComment(comment: string) {
        const updatedCommentItem = orderDetails.orderItems.find(
            (x) => x.uniqueId === editedComment
        );
        if (updatedCommentItem) {
            updatedCommentItem.comment = comment;
        }

        const model = {
            orderItems: orderDetails.orderItems.map((item) =>
                item.uniqueId === updatedCommentItem?.uniqueId ? updatedCommentItem! : item
            ),
            deliveryTime: orderDetails.deliveryTime,
            status: orderDetails.status,
            paymentMethod: orderDetails.paymentMethod,
            qrCodeName: orderDetails.qrCodeName,
            service: orderDetails.service,
        };
        dispatch(
            ordersStore.actionCreators.updateOrder(orderDetails.id, model, () => {
                setSaved(true);
                setEditedComment('');
                setEditOrder(false);
            })
        );
    }

    function removeComment() {
        const updatedCommentItem = orderDetails.orderItems.find(
            (x) => x.uniqueId === editedComment
        )!;

        if (!updatedCommentItem.comment) {
            setEditedComment('');
        } else {
            updatedCommentItem.comment = '';
            const model = {
                orderItems: orderDetails.orderItems.map((item) =>
                    item.uniqueId === updatedCommentItem?.uniqueId ? updatedCommentItem! : item
                ),
                deliveryTime: orderDetails.deliveryTime,
                status: orderDetails.status,
                paymentMethod: orderDetails.paymentMethod,
                qrCodeName: orderDetails.qrCodeName,
                service: orderDetails.service,
            };
            dispatch(
                ordersStore.actionCreators.updateOrder(orderDetails.id, model, () => {
                    setSaved(true);
                    setEditedComment('');
                    setEditOrder(false);
                })
            );
        }
    }

    function checkIsEdititngDisabled() {
        return (
            !orders.orderDetailsId ||
            orderDetails.status === OrderStatus.Closed ||
            (orderDetails.isPaid && orderDetails.status !== OrderStatus.Ready)
        );
    }

    function cancelChanges() {
        setHasChanged(false);
        //dispatch(OrdersStore.actionCreators.resetOrderDetails());
        !saved &&
            dispatch(
                ordersStore.actionCreators.setOrderDetails({
                    ...initialOrderState,
                    //orderItems: _.cloneDeep(initialOrderState.orderItems),
                })
            );
        setEditOrder(false);
        setShowCancelConfirmation(0);
    }

    function showIngredientsEditIcon(dish: OrderItem) {
        return (
            user.role !== Roles.OWNER &&
            orderDetails.status !== OrderStatus.Cancel &&
            dish.status !== OrderDishStatus.Ready &&
            dish.status !== OrderDishStatus.Canceled &&
            !!dish.ingredients?.find((i) => i.isAddition || i.isRemoval)
        );
    }

    function setDeliveryTime(time: Date) {
        const newTime = moment(time).valueOf();
        if (orderDetails.deliveryTime !== newTime) {
            dispatch(
                ordersStore.actionCreators.setOrderDetails({
                    ...orderDetails,
                    deliveryTime: newTime,
                    status:
                        orderDetails.status === OrderStatus.New
                            ? OrderStatus.InProgress
                            : orderDetails.status,
                })
            );
            saveChanges({
                orderItems: orderDetails.orderItems,
                deliveryTime: newTime,
                paymentMethod: orderDetails.paymentMethod,
                status: OrderStatus.Non,
                qrCodeName: orderDetails.qrCodeName,
                service: orderDetails.service,
            });
        }
    }

    /* function changePaymentMethod(method: PaymentMethod) {
        saveChanges({
            orderItems: orderDetails.orderItems,
            deliveryTime: orderDetails.deliveryTime,
            paymentMethod: method,
            status: OrderStatus.Non,
            qrCodeName: orderDetails.qrCodeName,
            service: orderDetails.service,
        });
        dispatch(
            OrdersStore.actionCreators.setOrderDetails({ ...orderDetails, paymentMethod: method })
        );
    } */

    function changeServiceTypeMethod(service: OrderServices) {
        saveChanges({
            orderItems: orderDetails.orderItems,
            deliveryTime: orderDetails.deliveryTime,
            paymentMethod: orderDetails.paymentMethod,
            status: OrderStatus.Non,
            qrCodeName: orderDetails.qrCodeName,
            service: service,
        });
        dispatch(ordersStore.actionCreators.setOrderDetails({ ...orderDetails, service: service }));
    }

    function changeOrderQRCode(qrCodeName: string) {
        saveChanges({
            orderItems: orderDetails.orderItems,
            deliveryTime: orderDetails.deliveryTime,
            paymentMethod: orderDetails.paymentMethod,
            status: OrderStatus.Non,
            qrCodeName: qrCodeName,
            service: orderDetails.service,
        });
        dispatch(
            ordersStore.actionCreators.setOrderDetails({ ...orderDetails, qrCodeName: qrCodeName })
        );
    }

    function prepareDishesData() {
        let result: {
            name: string;
            items: OrderItem[];
            dailyMenu?: OrderDetailsItem;
        }[] = [];
        orderDetails.orderItems.forEach((item) => {
            if (item.dishes?.length > 0) {
                const dailyMenuCategoryName = item.name || dailyMenuKey + ' ' + item.id;
                result.push({
                    name: dailyMenuCategoryName,
                    items: [...item.dishes],
                    dailyMenu: { ...item },
                });
            } else {
                if (result.find((x) => x.name === item.categoryName)) {
                    result.find((x) => x.name === item.categoryName)?.items.push({ ...item });
                } else {
                    result.push({ name: item.categoryName, items: [{ ...item }] });
                }
            }
        });
        return result;
    }

    function checkIsDisabled(dishStatus: OrderDishStatus, categoryId: string) {
        return (
            currentRestaurant?.currentCategory !== categoryId ||
            dishStatus === OrderDishStatus.Ready ||
            currentRestaurant?.currentPosition === Positions.MANAGER ||
            orderDetails.status === OrderStatus.Ready ||
            orderDetails.status === OrderStatus.Closed ||
            orderDetails.status === OrderStatus.Cancel
        );
    }

    function checkIsDisableDailyMenuItem(dish: OrderItem) {
        let isEnabled = false;
        if (user.role === Roles.OWNER) {
            return !isEnabled;
        }
        if (dish.status === OrderDishStatus.None) {
            const categoryId = category.categoryDetails?.dishes.find(
                (x) => x.id === dish.id
            )?.categoryId;
            if (categoryId) {
                isEnabled =
                    category.categoryDetails?.categories.find((x) => x.id === categoryId)
                        ?.position ===
                    user.restaurants.find((x) => x.id === user.currentRestaurantId)
                        ?.currentPosition;
            }
        }

        return !isEnabled;
    }

    function checkIsAllowed(item: OrderItem) {
        return !item.isPaid && item.status === OrderDishStatus.None;
    }

    function handleIngredientsClose() {
        const editedDish = orderDetails.orderItems.find((x) => x.id === orders.selectedDishId);
        const defaultDish = initialOrderState.orderItems.find(
            (x) => x.id === orders.selectedDishId
        );
        if (
            JSON.stringify(editedDish?.modifiedIngredients) ===
            JSON.stringify(defaultDish?.modifiedIngredients)
        ) {
            setShowIngredients(false);
        } else {
            setShowCancelConfirmation(1);
        }
    }

    function getFilters() {
        const filters = [];
        const filterData: string[] = [];
        let hasDailyMenu = false;
        orderDetails.orderItems.forEach((orderItem) => {
            if (!filterData.includes(orderItem.categoryId) && orderItem.dishes.length === 0) {
                filters.push(
                    <div
                        key={orderItem.id}
                        className={`${classes.categoryFilterItem} ${
                            selectedFilter.includes(orderItem.categoryId) ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectingFilter(orderItem.categoryId)}
                    >
                        {orderItem.categoryName}
                    </div>
                );
                filterData.push(orderItem.categoryId);
            }
            if (orderItem.dishes.length > 0) {
                hasDailyMenu = true;
            }
        });
        hasDailyMenu &&
            filters.push(
                <div
                    className={`${classes.categoryFilterItem} ${
                        selectedFilter.includes(dailyMenuKey) ? 'selected' : ''
                    }`}
                    onClick={() => handleSelectingFilter(dailyMenuKey)}
                >
                    {i18n.t('restaurant.dailyMenu')}
                </div>
            );
        return filters;
    }

    function prepareOrderItemsHeader() {
        const isEmpty = orderDetails.orderItems.length === 0;
        return (
            <>
                <div>
                    <div className={classes.orderItemsDetailsHeader}>
                        <div className={classes.orderDetailsHeadingText}>
                            {i18n.t('common.listOfOrders')}
                        </div>
                        {user.role === Roles.EMPLOYEE &&
                            !!orders.orderDetailsId &&
                            isEmpty &&
                            orderDetails.status !== OrderStatus.Cancel && (
                                <div>
                                    <Button color="primary" onClick={() => cancelChanges()}>
                                        {i18n.t('button.cancel')}
                                    </Button>
                                    <Button
                                        disabled={!hasChanged}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => saveChanges()}
                                    >
                                        {i18n.t('button.save')}
                                    </Button>
                                </div>
                            )}
                        {canEditOrder && !isEmpty && editOrder && (
                            <div className={classes.buttonsContainer} style={{ display: 'flex' }}>
                                <Button
                                    color="primary"
                                    onClick={() => cancelChanges()}
                                    style={{ marginRight: 5 }}
                                >
                                    {i18n.t('button.cancel')}
                                </Button>
                                <Button
                                    disabled={!hasChanged}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => saveChanges()}
                                >
                                    {i18n.t('button.save')}
                                </Button>
                            </div>
                        )}
                        {!isEmpty && !editOrder && orderDetails.status !== OrderStatus.Cancel && (
                            <div
                                className={classes.oderActions}
                                style={{
                                    justifyContent:
                                        user.role === Roles.OWNER ? 'flex-end' : 'space-between',
                                }}
                            >
                                <IconButton
                                    onClick={() => setShowFilter(!showFilter)}
                                    className={`${
                                        !showFilter && selectedFilter.length > 0
                                            ? classes.selectedFilterIcon
                                            : ''
                                    } ${!orders.orderDetailsId ? classes.filterDisabled : ''}`}
                                >
                                    <FilterListIcon
                                        style={{
                                            //marginRight: 40,
                                            color: showFilter
                                                ? theme.palette.primary.main
                                                : theme.palette.secondary.dark,
                                        }}
                                    />
                                </IconButton>
                                {canEditOrder && (
                                    <CreateIcon
                                        className={clsx(classes.orderEditButton, {
                                            Disabled: checkIsEdititngDisabled(),
                                        })}
                                        onClick={() => {
                                            setShowFilter(false);
                                            setEditOrder(true);
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    {showAddButton(isEmpty) && (
                        <SectionTab
                            text={i18n.t('button.addNewItem')}
                            onClick={() => {
                                setShowMenus(true);
                                setSaved(false);
                            }}
                            disabled={false}
                        />
                    )}
                </div>
                {showFilter && (
                    <div className={classes.categoryFilterContainer}>{getFilters()}</div>
                )}
            </>
        );
    }

    function HighlitedText(text: string) {
        return (
            <Highlighter
                highlightClassName={classes.highlighter}
                searchWords={[orders.searchString]}
                autoEscape={true}
                textToHighlight={text}
            />
        );
    }

    const PriceText = ({
        price,
        isDailyMenu = false,
        isPaid = false,
    }: {
        price: string | number;
        isDailyMenu?: boolean;
        isPaid?: boolean;
    }) => (
        <div>
            <div
                className={`${classes.orderItemCardPrice} ${
                    isDailyMenu &&
                    selectedFilter.includes(dailyMenuKey) &&
                    classes.disabledOrderItemAmount
                }`}
            >
                <span style={{ paddingBottom: 2 }}>{i18n.t('common.currencies.chf')}</span>
                <span className={classes.itemPrice}>{priceStyleHelper(price, true)}</span>
            </div>
            {isPaid && <div className={classes.paidText}>/ PAID</div>}
        </div>
    );

    /* function checkIsPaymentDisabled() {
        const status =
            orders.orders.filter((x) => x.id === orderDetails.id)[0]?.status ?? orderDetails.status;
        return (
            user.role === Roles.OWNER ||
            status === OrderStatus.Cancel ||
            status === OrderStatus.Closed ||
            orders.orders.length === 0
        );
    } */

    function renderModifiedIngredient(ingredient: ModifiedIngredient, dish: OrderItem) {
        const targetIngredient = dish.ingredients?.find((ing) => ingredient.id === ing.id);
        if (!targetIngredient) {
            return null;
        } else {
            const val = ingredient.hasOwnProperty('isAdditionChecked')
                ? ingredient.isAdditionChecked
                : ingredient.isRemovalChecked;
            return (
                <div
                    key={ingredient.id}
                    className={classes.changeOfIngredientChip}
                    style={{
                        backgroundColor: ingredient.isAdditionChecked
                            ? alpha(theme.palette.success.main, 0.9)
                            : alpha(theme.palette.error.main, 0.9),
                    }}
                >{`${val ? '+' : '-'} 
    ${targetIngredient.amount}${targetIngredient.unitOfMeasure ?? 'gr'} ${
                    targetIngredient.name ?? 'No name'
                }`}</div>
            );
        }
    }

    function renderEditedIngredients(dish: OrderItem, dailyMenuId?: string) {
        return (
            <div
                className={classes.changeOfIngredientsContainer}
                style={{
                    marginTop: dish.amount > 1 ? 5 : 0,
                }}
            >
                <div style={{ height: 10 }} />
                <div className={classes.flexRowSpaceBetween}>
                    <span className={classes.orderDetailsHeadingText}>
                        {i18n.t('restaurant.changeOfIngredients')}
                    </span>
                    {showIngredientsEditIcon(dish) && (
                        <CreateIcon
                            className={classes.orderEditButton}
                            onClick={() => {
                                dispatch(
                                    ordersStore.actionCreators.setSelectedDish(dish.id, dailyMenuId)
                                );
                                setShowIngredients(true);
                            }}
                        />
                    )}
                </div>

                <div className={classes.changeOfIngredientsChipContainer}>
                    {dish?.modifiedIngredients?.map((ingredient) =>
                        renderModifiedIngredient(ingredient, dish)
                    )}
                </div>
            </div>
        );
    }

    function prepareOrderDishesList(
        categoryName: string,
        dishes: OrderItem[],
        dailyMenu?: OrderDetailsItem
    ) {
        let renderedCategoryName: string | undefined =
            categoryName !== 'null' ? categoryName : 'no category';
        if (dailyMenu) {
            renderedCategoryName = dailyMenu.name;
        }

        function renderDailyMenu() {
            const hasModifiedIngredients = (dish: any) => dish.modifiedIngredients?.length > 0;

            return (
                <Card
                    className={clsx({
                        [classes.disabledMenu]: selectedFilter.includes(dailyMenuKey),
                        [classes.orderItemCardPaid]: dailyMenu?.isPaid,
                    })}
                    style={{ boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.11)' }}
                >
                    {dishes.map((dish, index) => {
                        return (
                            <div key={dish.id + index}>
                                <div
                                    className={clsx(
                                        classes.orderItemCard,
                                        classes.orderItemCardDaily
                                    )}
                                    style={{
                                        paddingBottom: editedComment === dish.uniqueId ? 8 : 0,
                                    }}
                                >
                                    {!editOrder && (
                                        <Checkbox
                                            onChange={() =>
                                                handleCheckingItems(dish.id, dish.dailyMenuId)
                                            }
                                            inputProps={{ 'aria-label': 'select all desserts' }}
                                            checked={dish.status === OrderDishStatus.Ready}
                                            disabled={checkIsDisableDailyMenuItem(dish)}
                                        />
                                    )}
                                    <div className={classes.checkboxContainer}>
                                        <div>
                                            <div className={classes.orderItemCardTitle}>
                                                {editOrder ? dish.name : HighlitedText(dish.name)}
                                            </div>
                                            <div className="OrderItemCardDescription">
                                                {!!dish.shortDescription
                                                    ? dish.shortDescription
                                                    : i18n.t('common.noDescription')}
                                            </div>
                                        </div>
                                        {index === 0 && (
                                            <>
                                                <PriceText
                                                    price={dailyMenu?.price || 0}
                                                    isDailyMenu={true}
                                                    isPaid={dailyMenu?.isPaid}
                                                />
                                                {dailyMenu!.amount > 1 && !editOrder && (
                                                    <div
                                                        className={classes.dishAmount}
                                                        style={{
                                                            top: 43,
                                                            right: 0,
                                                            bottom: 'auto',
                                                        }}
                                                    >
                                                        {dailyMenu!.amount}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                {hasModifiedIngredients(dish) &&
                                    renderEditedIngredients(
                                        dish,
                                        dailyMenu ? dailyMenu.id : undefined
                                    )}
                            </div>
                        );
                    })}
                </Card>
            );
        }

        function showCategoryName() {
            if (selectedFilter.length === 0) {
                return true;
            }
            if (selectedFilter.includes(dishes[0].categoryId)) {
                return true;
            }
            if (dailyMenu && selectedFilter.includes(dailyMenuKey)) {
                return true;
            }
        }

        return (
            <div key={categoryName + Math.random() * 123}>
                {showCategoryName() &&
                    (editOrder && dailyMenu ? (
                        <div
                            className={classes.flexRowSpaceBetween}
                            style={{ padding: '0px 14px 0 6px' }}
                        >
                            <div className={classes.orderDetailsGroupText}>
                                <img
                                    style={{ marginRight: 18 }}
                                    src={images.icons.removeCircle}
                                    alt="remove-circle"
                                    onClick={() =>
                                        checkIsAllowed(dailyMenu!) &&
                                        dispatch(
                                            ordersStore.actionCreators.addItemToOrder(dailyMenu, 0)
                                        )
                                    }
                                    className={clsx(classes.removeOrderItem, {
                                        Disabled: !checkIsAllowed(dailyMenu),
                                    })}
                                />
                                {renderedCategoryName}{' '}
                            </div>
                            <div
                                className={classes.orderItemAmount}
                                style={{ width: 80, margin: '0 0 10px' }}
                            >
                                <span
                                    className={clsx(classes.orderItemAmountControl, {
                                        Disabled:
                                            dailyMenu?.amount === 0 || !checkIsAllowed(dailyMenu),
                                    })}
                                    onClick={() =>
                                        dailyMenu?.amount - 1 >= 0 &&
                                        dispatch(
                                            ordersStore.actionCreators.addItemToOrder(
                                                dailyMenu,
                                                -1,
                                                () => setHasChanged(true)
                                            )
                                        )
                                    }
                                >
                                    -
                                </span>
                                <span className={classes.orderItemAmountNumber}>
                                    {dailyMenu?.amount || 0}
                                </span>
                                <span
                                    className={clsx(classes.orderItemAmountControl, {
                                        Disabled: !checkIsAllowed(dailyMenu),
                                    })}
                                    onClick={() =>
                                        dispatch(
                                            ordersStore.actionCreators.addItemToOrder(
                                                dailyMenu,
                                                1,
                                                () => setHasChanged(true)
                                            )
                                        )
                                    }
                                >
                                    +
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.orderDetailsGroupText}>{renderedCategoryName} </div>
                    ))}
                {dailyMenu
                    ? renderDailyMenu()
                    : dishes.map((dish, index) => (
                          <Card
                              key={dish.id + index}
                              className={clsx(classes.orderItemCard, {
                                  Disabled:
                                      selectedFilter.length > 0 &&
                                      !selectedFilter.includes(
                                          category.categoryDetails?.dishes?.filter(
                                              (x) => x.id === dish.id
                                          )[0]?.categoryId!
                                      ),
                                  [classes.orderItemCardPaid]: dish.isPaid,
                              })}
                              style={{
                                  paddingBottom: editedComment === dish.uniqueId ? 8 : 0,
                              }}
                          >
                              <div
                                  style={{
                                      position: 'relative',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      minHeight: '80px',
                                  }}
                              >
                                  {editOrder ? (
                                      <img
                                          src={images.icons.removeCircle}
                                          alt="remove-circle"
                                          onClick={() =>
                                              checkIsAllowed(dish) &&
                                              dispatch(
                                                  ordersStore.actionCreators.addItemToOrder(
                                                      dish,
                                                      0,
                                                      () => setHasChanged(true)
                                                  )
                                              )
                                          }
                                          className={clsx(classes.removeOrderItem, {
                                              Disabled: !checkIsAllowed(dish),
                                          })}
                                      />
                                  ) : (
                                      <Checkbox
                                          onChange={() =>
                                              handleCheckingItems(dish.id, dish.dailyMenuId)
                                          }
                                          inputProps={{ 'aria-label': 'select all desserts' }}
                                          checked={dish.status === OrderDishStatus.Ready}
                                          disabled={checkIsDisabled(dish.status, dish.categoryId)}
                                      />
                                  )}
                                  <div className={classes.checkboxContainer}>
                                      <div>
                                          <div className={classes.orderItemCardTitle}>
                                              {editOrder ? dish.name : HighlitedText(dish.name)}
                                          </div>
                                          <div className="OrderItemCardDescription">
                                              {!!dish.shortDescription
                                                  ? dish.shortDescription
                                                  : i18n.t('common.noDescription')}
                                          </div>
                                      </div>
                                      {editOrder ? (
                                          <div className={classes.orderItemAmountContainer}>
                                              <div className="OrderItemCardPrice">
                                                  <span style={{ paddingBottom: 2 }}>
                                                      {i18n.t('common.currencies.chf')}
                                                  </span>{' '}
                                                  <span className={classes.itemPrice}>
                                                      {priceStyleHelper(dish.price, true)}
                                                  </span>{' '}
                                              </div>
                                              <div className={classes.orderItemAmount}>
                                                  <span
                                                      className={clsx(
                                                          classes.orderItemAmountControl,
                                                          {
                                                              Disabled:
                                                                  dish.amount === 0 ||
                                                                  !checkIsAllowed(dish),
                                                          }
                                                      )}
                                                      onClick={() =>
                                                          dish.amount - 1 >= 0 &&
                                                          dispatch(
                                                              ordersStore.actionCreators.addItemToOrder(
                                                                  dish,
                                                                  -1,
                                                                  () => setHasChanged(true)
                                                              )
                                                          )
                                                      }
                                                  >
                                                      -
                                                  </span>
                                                  <span className={classes.orderItemAmountNumber}>
                                                      {dish.amount}
                                                  </span>
                                                  <span
                                                      className={clsx(
                                                          classes.orderItemAmountControl,
                                                          {
                                                              Disabled: !checkIsAllowed(dish),
                                                          }
                                                      )}
                                                      onClick={() =>
                                                          dispatch(
                                                              ordersStore.actionCreators.addItemToOrder(
                                                                  dish,
                                                                  1,
                                                                  () => setHasChanged(true)
                                                              )
                                                          )
                                                      }
                                                  >
                                                      +
                                                  </span>
                                              </div>
                                          </div>
                                      ) : (
                                          <PriceText
                                              price={dish.price}
                                              isPaid={dish.isPaid || orderDetails.isPaid}
                                          />
                                      )}
                                  </div>
                                  {!editOrder && dish.amount > 1 && (
                                      <div className={classes.dishAmount}>{dish.amount}</div>
                                  )}
                              </div>
                              {!!dish.ingredients?.find((i) => i.isAddition || i.isRemoval) &&
                                  renderEditedIngredients(dish)}
                              {renderComment(dish)}
                          </Card>
                      ))}
            </div>
        );
    }

    function renderComment(dish: OrderItem) {
        const isActive = editedComment === dish.uniqueId;
        return (
            <div className={classes.commentSection}>
                <Formik
                    initialValues={{
                        comment: dish.comment || '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        saveComment(values.comment);
                    }}
                >
                    {({ setFieldValue, submitForm, values, errors }) => (
                        <Form>
                            <FormControl fullWidth variant="standard">
                                <InputLabel
                                    htmlFor="filled-adornment-password"
                                    className={classes.orderDetailsHeadingText}
                                >
                                    {i18n.t('common.comment')}
                                </InputLabel>
                                <Input
                                    disabled={!isActive}
                                    disableUnderline={!isActive}
                                    autoFocus={isActive}
                                    error={!!errors.comment}
                                    id="filled-adornment-password"
                                    type="text"
                                    placeholder="Comment"
                                    value={values.comment}
                                    onChange={(e) => setFieldValue('comment', e.target.value)}
                                    endAdornment={
                                        <InputAdornment
                                            position="end"
                                            style={{ marginRight: isActive ? 7 : -3, width: 25 }}
                                        >
                                            {isActive ? (
                                                <>
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={removeComment}
                                                    >
                                                        <img
                                                            src={images.icons.removeCircle}
                                                            alt="remove-circle"
                                                            style={{
                                                                marginRight: 5,
                                                            }}
                                                        />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => submitForm()}
                                                    >
                                                        <img
                                                            height={13}
                                                            src={images.icons.checkedCircle}
                                                            alt="remove-circle"
                                                        />
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setEditedComment(dish.uniqueId!)}
                                                >
                                                    <img
                                                        src={images.icons.comment}
                                                        alt="remove-circle"
                                                        style={{ marginRight: 0 }}
                                                    />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }

    function hasFilter(dish: OrderItem) {
        if (selectedFilter.length === 0) {
            return true;
        }
        if (selectedFilter.includes(dailyMenuKey) && dish.dailyMenuId) {
            return true;
        }
        return selectedFilter.length > 0 && selectedFilter.includes(dish.categoryId);
    }

    function getMenuDishes(dailyMenu = false) {
        return prepareDishesData().filter((each) => {
            let isDailyMenu;
            each.items.forEach((orderItem) => {
                isDailyMenu = dailyMenu ? orderItem.dailyMenuId : !orderItem.dailyMenuId;
            });
            return isDailyMenu;
        });
    }

    function filterDishes(dishes: OrderItem[]) {
        let shouldBeFiltered = false;
        dishes.forEach((dish) => {
            if (hasFilter(dish)) {
                shouldBeFiltered = true;
            }
        });
        if (dishes[0].dailyMenuId) {
            const otherDishes = getMenuDishes();
            dishes.forEach((eachDailyMenuDish) => {
                otherDishes.forEach((dish) => {
                    const commonItem = dish.items.find((dish) => dish.id === eachDailyMenuDish.id);
                    if (commonItem && hasFilter(commonItem)) {
                        shouldBeFiltered = true;
                    }
                });
            });
        }
        return shouldBeFiltered;
    }

    function checkIfOrderEdited() {
        dispatch(ordersStore.actionCreators.addItemToEditedOrder(null));
        setShowMenus(false);
        /* let edited = false;
        orderDetails.orderItems.forEach((each) => {
            if (each.modified) {
                edited = true;
                return;
            }
        });
        if (edited && showCancelConfirmation === 0) {
            return setShowCancelConfirmation(1);
        }
        setShowCancelConfirmation(2);
        setShowMenus(false);
        cancelChanges(); */
    }

    return (
        <Card
            className={classes.root}
            style={
                user.role === Roles.EMPLOYEE
                    ? { backgroundColor: 'transparent', marginLeft: 8 }
                    : {}
            }
        >
            <div
                className={`${classes.orderDetailsHeader} ${classes.flexRowSpaceBetween}`}
                style={
                    user.role === Roles.EMPLOYEE
                        ? { backgroundColor: theme.palette.common.white }
                        : {}
                }
            >
                <ServiceType
                    order={orderDetails}
                    onChange={changeServiceTypeMethod}
                    isEditable={
                        user.role === Roles.EMPLOYEE &&
                        orderDetails.status !== OrderStatus.Cancel &&
                        orderDetails.status !== OrderStatus.Closed
                    }
                />
                <div
                    style={{
                        pointerEvents: orderDetails.id.length > 0 ? 'auto' : 'none',
                        flexGrow: 1,
                    }}
                    className={classes.flexRow}
                >
                    <div
                        style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            height: 52,
                            marginTop: 0,
                            marginBottom: 0,
                            flexGrow: 1,
                            /*  width: 170 */
                        }}
                        className={`${classes.flexColumn} ${classes.orderHeaderItem}`}
                    >
                        <OrderQRLeftCode
                            order={orderDetails}
                            submit={changeOrderQRCode}
                            isDisabled={!(user.role === Roles.EMPLOYEE)}
                        />
                        <span className={classes.orderNumber}>{`№ ${
                            orders.orderDetailsId ? orderDetails.orderNumber : ''
                        }`}</span>
                    </div>

                    <OrderTime order={orderDetails} />
                    <DeliveryTime
                        order={orderDetails}
                        submit={setDeliveryTime}
                        disabled={user.role === Roles.OWNER}
                    />
                    <OrderStatusItem
                        status={
                            orders.orders.filter((x) => x.id === orderDetails.id)[0]?.status ?? 5
                        }
                        update={saveChanges}
                        order={orderDetails}
                        isDisabled={user.role === Roles.OWNER}
                    />
                </div>
            </div>
            <div
                className={classes.orderItemsDetailsContainer}
                style={
                    user.role === Roles.EMPLOYEE
                        ? {
                              backgroundColor: theme.palette.common.white,
                              height: '90%',
                              marginTop: 8,
                          }
                        : { height: '90%' }
                }
            >
                <div
                    className={classes.orderItemsDetails}
                    style={user.role === Roles.EMPLOYEE ? { flexGrow: 1 } : {}}
                >
                    {prepareOrderItemsHeader()}
                    <div className={classes.orderItemsContainer}>
                        <div
                            style={{
                                visibility: orders.orderDetailsId ? 'visible' : 'hidden',
                                overflow: 'auto',
                                padding: 2,
                                //maxHeight: 500,
                            }}
                            ref={listRef}
                        >
                            {prepareDishesData()
                                .filter((x) => filterDishes(x.items))
                                .map((x) => prepareOrderDishesList(x.name, x.items, x.dailyMenu))}
                        </div>
                    </div>
                    <div className={classes.orderItemsFooter}>
                        <div className={classes.orderDetailsHeadingText}>
                            <span style={{ fontSize: '10px', lineHeight: '12px' }}>{`${i18n.t(
                                'common.numberOfDishes'
                            )}: `}</span>
                            <span style={{ fontWeight: 900, fontSize: 14 }}>
                                {orders.orderDetailsId && orderDetails.orderItems?.length
                                    ? orderDetails.orderItems
                                          .map((x) => x.amount)
                                          .reduce((x, y) => x + y)
                                    : 0}
                            </span>
                        </div>
                        <Card className={classes.orderItemsFooterCard}>
                            <div className={classes.orderItemCardTitle}>
                                {i18n.t('common.totalPrice')}:
                            </div>
                            <div className={classes.orderItemsFooterCardPrice}>
                                {' '}
                                CHF{' '}
                                <span>
                                    {orders.orderDetailsId
                                        ? priceStyleHelper(orderDetails.totalAmount, true)
                                        : 0}
                                </span>
                            </div>
                        </Card>
                    </div>
                </div>
                <div className={classes.customerDataContainer}>
                    <div className={classes.customerData}>
                        <ResponsibleUserSelect />
                        <span
                            className={classes.orderDetailsHeadingText}
                            style={{ marginBottom: 20, marginTop: 20 }}
                        >
                            {i18n.t('common.paymentStatus')}
                        </span>
                        <OrderPayment
                            isPaid={orderDetails.isPaid}
                            totalAmount={orderDetails.totalAmount}
                        />
                        <div className={classes.orderDetailsHeadingText}>
                            {i18n.t('common.clientNotes')}
                        </div>
                        {orders.orderDetails && !!orderDetails.comment && (
                            <Text
                                text={orderDetails.comment}
                                className={classes.customerNote}
                                whiteSpace={'normal'}
                                fontSize={14}
                                maxLength={70}
                            />
                        )}
                        <div className={classes.orderDetailsHeadingText} style={{ marginTop: 10 }}>
                            {i18n.t('common.clientInformation')}
                        </div>
                        <div className={classes.customerDataItem}>
                            <div style={{ backgroundImage: `url(${images.icons.user})` }} />
                            <span>
                                {orders.orderDetailsId &&
                                    HighlitedText(
                                        `${orderDetails.firstName} ${orderDetails.lastName}`
                                    )}
                            </span>
                        </div>
                        <div className={classes.customerDataItem}>
                            <div style={{ backgroundImage: `url(${images.icons.marker})` }} />
                            <span>
                                {orders.orderDetailsId &&
                                    HighlitedText(
                                        `${orderDetails.addressLine} ${orderDetails.zip} ${orderDetails.city}`
                                    )}
                            </span>
                        </div>
                        <div className={classes.customerDataItem}>
                            <div style={{ backgroundImage: `url(${images.icons.email})` }} />
                            <span>
                                {orders.orderDetailsId && HighlitedText(orderDetails.email)}
                            </span>
                        </div>
                        <div className={classes.customerDataItem}>
                            <div style={{ backgroundImage: `url(${images.icons.phone})` }} />
                            <span>
                                {orders.orderDetailsId && HighlitedText(orderDetails.phoneNumber)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <MenusModal
                isOpen={showMenus}
                onClose={() => {
                    checkIfOrderEdited();
                }}
                saveChanges={saveChanges}
            />
            <PopupConfirmation
                open={showCancelConfirmation === 1}
                close={() => {
                    setShowCancelConfirmation(2);
                }}
                action={() => {
                    setShowCancelConfirmation(2);
                    setShowMenus(false);
                    setShowIngredients(false);
                    cancelChanges();
                }}
                title={i18n.t('warnings.cancelChanges')}
                description={i18n.t('warnings.areYouSureToCloseWithoutChanges')}
                activeBtn={i18n.t('button.yesClose')}
                basicButton
            />
            <Dialog maxWidth="sm" open={showIngredients} onClose={() => setShowIngredients(false)}>
                <div className={classes.flexRowSpaceBetween}>
                    <DialogTitle style={{ paddingLeft: 14 }}>{i18n.t('common.edit')}</DialogTitle>
                    <DialogActions style={{ marginBottom: 0, paddingRight: 14 }}>
                        <Button color="primary" variant="text" onClick={handleIngredientsClose}>
                            {i18n.t('button.cancel')}
                        </Button>
                        <Button
                            variant={'contained'}
                            color="primary"
                            style={{ width: '92px' }}
                            onClick={() => {
                                setShowIngredients(false);
                                saveChanges();
                            }}
                        >
                            {i18n.t('common.save')}
                        </Button>
                    </DialogActions>
                </div>
                <DialogContent className={classes.dishIngredientDialog}>
                    <MenuIngredientManagement />
                </DialogContent>
            </Dialog>
            <div style={{ display: 'none' }} />
        </Card>
    );
}
