import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import i18n from 'i18n-js';

import { OrderStatus } from 'constants/enums';
import { useStyles } from './styles';
import { OrderDetails } from 'store/ordersStore/reducer';
import { DateTimePicker } from 'components/shared';
import { formatDate, formatTime } from 'helpers/datetimeHelper';
import { ApplicationState } from 'store';

export default function DeliveryTime(props: {
    order: OrderDetails;
    submit: any;
    disabled: boolean;
}) {
    const classes = useStyles();
    const orders = useSelector((state: ApplicationState) => state.orders);
    const [showPicker, setShowPicker] = useState(false);
    return (
        <div style={{ position: 'relative' }}>
            <div
                id="date-time-picker"
                className={`${classes.orderHeaderItem} ${classes.flexColumnSpaceBetween} ${
                    showPicker && classes.selected
                }`}
                style={{
                    cursor:
                        props.order.status !== OrderStatus.Closed && !props.disabled
                            ? 'pointer'
                            : 'auto',
                    position: 'relative',
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (
                        !props.disabled &&
                        props.order.status !== OrderStatus.Closed &&
                        !showPicker &&
                        orders.orders.length
                    ) {
                        setShowPicker(!showPicker);
                    } //showDateTime?.current?.click();
                }}
                //ref={showDateTime}
            >
                <div className={classes.orderDetailsHeadingText}>
                    {i18n.t('common.timeOfDelivery')}{' '}
                </div>

                <div
                    className={classes.orderHeaderItemText}
                    style={{ visibility: orders.orderDetailsId ? 'visible' : 'hidden' }}
                >
                    <span>
                        {props.order.deliveryTime > 0
                            ? formatTime(moment(props.order.deliveryTime).toDate())
                            : `${i18n.t('common.time')} /`}
                    </span>
                    <span>
                        {props.order.deliveryTime > 0
                            ? formatDate(moment(props.order.deliveryTime).toDate())
                            : i18n.t('common.date')}
                    </span>
                </div>
            </div>
            {props.order.status !== OrderStatus.Closed &&
                props.order.status !== OrderStatus.Cancel && (
                    <DateTimePicker
                        showButtons
                        futureOnly
                        futureTimeOnly
                        date={
                            !!props.order.deliveryTime
                                ? new Date(props.order.deliveryTime)
                                : new Date(Date.now())
                        }
                        onSubmit={props.submit}
                        isOpen={showPicker}
                        onClose={() => setShowPicker(false)}
                    />
                )}
        </div>
    );
}
