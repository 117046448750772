import React from 'react';

import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core';

import { theme } from 'config/theme';

const useStyles = makeStyles({
    root: {
        '& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled': {
            color: theme.palette.secondary.dark,
            backgroundColor: theme.palette.secondary.light,
        },
        '& + .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
            fontSize: 14,
        },
    },
});

export default function Input(props: any) {
    const { error, label, style, component } = props;
    const classes = useStyles();

    return (
        <Field
            className={classes.root}
            {...props}
            component={component ? component : TextField}
            style={style}
            label={!!error ? error : label}
        />
    );
}

/* Input.defaultProps = {
    showErrors: true,
}; */
