import React from 'react';
import { useSelector } from 'react-redux';

import i18n from 'i18n-js';

import { images } from 'assets';
import { useStyles } from './styles';
import { ApplicationState } from 'store';
import { OrderDetails } from 'store/ordersStore/reducer';
import { Text } from '../../shared';
import { OrderServices } from 'constants/enums';
import { Menu, MenuItem } from '@material-ui/core';

interface ServiceTypeProps {
    order: OrderDetails;
    onChange: (service: OrderServices) => void;
    isEditable: boolean;
}

interface Service {
    icon: string;
    name: string;
}

export default function ServiceType(props: ServiceTypeProps) {
    const classes = useStyles();
    const orders = useSelector((state: ApplicationState) => state.orders);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const services = new Map<OrderServices, Service>([
        [
            OrderServices.OrderInRestaurant,
            {
                icon: images.icons.chartVorort,
                name: i18n.t('form.jamesVorort'),
            },
        ],
        [
            OrderServices.TakeAway,
            {
                icon: images.icons.chartTakeAway,
                name: i18n.t('form.takeAway'),
            },
        ],
        [
            OrderServices.HomeDelivery,
            {
                icon: images.icons.chartHomeDelivery,
                name: i18n.t('form.homeDelivery'),
            },
        ],
    ]);

    function handleClick(event: any) {
        props.isEditable && setAnchorEl(event.currentTarget);
    }

    function handleClose(service?: OrderServices) {
        setAnchorEl(null);
        if (!!service) props.onChange(service);
    }

    function prepareMenuItems() {
        let items: JSX.Element[] = [];
        services.forEach((value, key) => {
            if (key !== props.order.service) {
                items.push(
                    <MenuItem onClick={() => handleClose(key)} key={key.toString()}>
                        <img
                            src={services.get(key)?.icon ?? images.icons.chartVorort}
                            style={{
                                height: 19,
                                marginRight: 7,
                            }}
                            alt="home-delivery-icon"
                        />
                        {value.name}
                    </MenuItem>
                );
            }
        });

        return items;
    }
    return (
        <div key={props.order.service.toString()}>
            <div
                className={`${classes.orderHeaderItem} ${classes.flexColumnSpaceBetween}`}
                aria-controls="order-service"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <Text
                    maxLength={13}
                    withoutMore
                    fontSize={10}
                    text={services.get(props.order.service)?.name ?? i18n.t('form.jamesVorort')}
                    className={classes.orderDetailsHeadingText}
                />
                <img
                    src={services.get(props.order.service)?.icon ?? images.icons.chartVorort}
                    style={{
                        height: 19,
                        margin: '0 auto',
                        visibility: orders.orderDetailsId ? 'visible' : 'hidden',
                    }}
                    alt="home-delivery-icon"
                />
            </div>
            <Menu
                id="order-service"
                className={classes.servicesDropdown}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {prepareMenuItems()}
            </Menu>
        </div>
    );
}
