import React from 'react';

import { ListItem, ListItemText } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from '../styles';

interface NavbarSectionHeaderProps {
    isOpen: boolean;
    title: string;
}

export default function NavbarSectionHeader(props: NavbarSectionHeaderProps) {
    const classes = useStyles();

    return (
        <ListItem
            className={clsx(classes.navbarSectionHeder, {
                [classes.navbarSectionHederOpened]: props.isOpen,
                [classes.navbarSectionHederCollapsed]: !props.isOpen,
            })}
            key={props.title}
        >
            <ListItemText primary={props.title} />
        </ListItem>
    );
}
