import React from 'react';
import { useHistory } from 'react-router-dom';

import i18n from 'i18n-js';
import { Button } from '@material-ui/core';

import logo from 'assets/Img-Welcome.svg';

export default function NothingToSee() {
    const history = useHistory();

    return (
        <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
            <img src={logo} alt="logo" />
            <h1 style={{ fontSize: '24px', lineHeight: '36px', color: '#5B5858' }}>
                {i18n.t('main.nothingToSee')}
            </h1>
            <Button
                onClick={() => history.goBack()}
                style={{ width: '172px', height: '36px' }}
                variant="contained"
                color="primary"
            >
                {i18n.t('button.goBack')}
            </Button>
        </div>
    );
}
