import React from 'react';

import i18n from 'i18n-js';

import { images } from 'assets';
import { NavbarList } from './NavbarList';
import NavbarListItem from './NavbarListItem';
import NavbarSectionHeader from './NavbarSectionHeader';

export default function AdminNavbar(props: { isOpen: boolean }) {
    return (
        <NavbarList isOpen={props.isOpen}>
            <NavbarSectionHeader
                isOpen={props.isOpen}
                title={i18n.t('navigationTabs.management')}
            />
            <NavbarListItem
                isOpen={props.isOpen}
                path="/users"
                icon={images.icons.users}
                title={i18n.t('navigationTabs.userManagement')}
            />
            <NavbarListItem
                isOpen={props.isOpen}
                path="/restaurants"
                icon={images.icons.restaurants}
                title={i18n.t('navigationTabs.restaurantManagement')}
                matches={['/restaurants', '/createrestaurant']}
            />
            <NavbarListItem
                isOpen={props.isOpen}
                path="/finance"
                icon={images.icons.dollar}
                title={i18n.t('navigationTabs.finance')}
            />
        </NavbarList>
    );
}
