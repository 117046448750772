import React from 'react';

import { alpha, createStyles, makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) =>
    createStyles({
        container: {
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
            height: 47,
            width: '100%',
            cursor: 'pointer',
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            fontWeight: 500,
        },
        plusText: {
            fontWeight: 'bold',
            marginRight: '10px',
            fontSize: '16px',
        },
        disabled: {
            backgroundColor: alpha(theme.palette.action.disabled, 0.16),
            pointerEvents: 'none',
        },
    })
);

interface Props {
    text: string;
    onClick: React.MouseEventHandler;
    disabled?: boolean;
}

export default function SectionTab({ text, onClick, disabled }: Props) {
    const classes = styles();
    return (
        <div className={`${classes.container} ${!!disabled && classes.disabled}`} onClick={onClick}>
            <span className={classes.plusText}>+</span>
            {text}
        </div>
    );
}
