import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import i18n from 'i18n-js';
import {
    Toolbar,
    FormControl,
    Input,
    createStyles,
    makeStyles,
    Theme,
    Select,
    MenuItem,
    Chip,
} from '@material-ui/core';

import { images } from 'assets';
import commonStyles from 'config/commonStyles';
import { Roles } from 'constants/enums';
import { ApplicationState } from 'store';
import UserRoleLabel from './UserRoleLabel';

interface RolesFilterProps {
    onlyRestaurantUsers?: boolean;
    selectedRoles: string[];
    onChange: (e: any) => void;
    onSubmit: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        root: {
            ...commonStyles.flexRowSpaceBetween,
            paddingLeft: '0px !important',
            marginTop: 28,
            '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
                position: 'absolute',
                top: '50%',
                right: 0,
            },
            '& .MuiFormLabel-root': {
                fontSize: 14,
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: 'transparent',
            },
            '& .MuiSvgIcon-root': {
                display: 'none',
            },
            '& .MuiListItem-root, & .MuiChip-root': {
                cursor: 'pointer',
            },
            '& .MuiSelect-select.MuiSelect-select': {
                padding: '0 0 0 1px',
                marginTop: 13,
            },
            '& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                top: 100,
            },
        },
        label: {
            fontWeight: 500,
            fontSize: 14,
            color: theme.palette.action.disabled,
            cursor: 'pointer',
            // width: 50,
            marginTop: 15,
            zIndex: 1,
            '&::after': {
                content: '""',
                display: 'inline-block',
                backgroundImage: `url('${images.icons.lens}')`,
                backgroundSize: 12,
                height: 12,
                width: 12,
                marginLeft: 10,
            },
        },
        formControl: {
            position: 'absolute',
            left: 0,
            zIndex: 0,
        },
        chips: {
            width: 133,
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
            '& div, & span': {
                cursor: 'pointer',
            },
        },
        selectAll: {
            cursor: 'pointer',
            width: 133,
            '& .MuiChip-label': {
                color: `${theme.palette.primary.main} !important`,
            },
        },
        selectAllSelected: {
            cursor: 'pointer',
            width: 133,
            '& .MuiChip-label': {
                color: `${theme.palette.common.white} !important`,
            },
        },
        rolesSelectDropdown: {
            width: 199,
            '& li': {
                justifyContent: 'center',
            },
        },
    })
);

export default function RolesFilter(props: RolesFilterProps) {
    const classes = useStyles();
    const roles = useSelector((state: ApplicationState) =>
        props.onlyRestaurantUsers
            ? state.roles.roles.filter((x) => x.name === Roles.OWNER || x.name === Roles.EMPLOYEE)
            : state.roles.roles
    );
    const [showSelect, setShowSelect] = useState(false);

    function preventActions(e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    function calculateLabelSize() {
        if (props.selectedRoles?.length > 2) {
            return 'small';
            // eslint-disable-next-line
        } else if (props.selectedRoles?.length == 2) {
            return 'medium';
        } else {
            return 'large';
        }
    }
    return (
        <Toolbar className={`${classes.root} search-input`}>
            {props.selectedRoles.length === 0 && (
                <span
                    className={classes.label}
                    onClick={(e) => {
                        preventActions(e);
                        setShowSelect(true);
                    }}
                >
                    {i18n.t('form.role')}
                </span>
            )}

            <FormControl
                className={classes.formControl}
                onClick={(e) => {
                    e.stopPropagation();
                    setShowSelect(true);
                }}
            >
                <Select
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                    labelId="user-role-label"
                    id="user-role"
                    multiple
                    open={showSelect}
                    disableUnderline
                    value={props.selectedRoles ?? []}
                    onChange={props.onChange}
                    onClick={(e) => preventActions(e)}
                    onClose={() => {
                        setShowSelect(false);
                        props.onSubmit();
                    }}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={() => (
                        <div className={classes.chips} onClick={() => setShowSelect(!showSelect)}>
                            {roles.map(
                                (value) =>
                                    props.selectedRoles?.indexOf(value.id) !== -1 && (
                                        <UserRoleLabel
                                            role={value.name}
                                            size={calculateLabelSize()}
                                            key={value.id}
                                        />
                                    )
                            )}
                        </div>
                    )}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        classes: {
                            paper: classes.rolesSelectDropdown,
                            list: classes.rolesSelectDropdown,
                        },
                        getContentAnchorEl: null,
                    }}
                >
                    <MenuItem
                        key="roles-select-all"
                        value="selectAll"
                        className={
                            props.selectedRoles.length === roles.length ? 'Mui-selected' : ''
                        }
                    >
                        {props.selectedRoles.length === roles.length ? (
                            <Chip
                                size="small"
                                color="primary"
                                variant="default"
                                label={i18n.t('common.all')}
                                className={`${classes.selectAllSelected}`}
                            />
                        ) : (
                            <Chip
                                size="small"
                                color="primary"
                                variant="outlined"
                                label={i18n.t('common.all')}
                                className={`${classes.selectAll}`}
                            />
                        )}
                    </MenuItem>
                    {roles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                            <UserRoleLabel role={role.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Toolbar>
    );
}
