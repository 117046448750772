import React from 'react';

import clsx from 'clsx';
import { List } from '@material-ui/core';

import { useStyles } from '../styles';

interface NavbarListProps {
    isOpen: boolean;
}

export const NavbarList: React.FC<NavbarListProps> = ({ isOpen, children }) => {
    const classes = useStyles();
    return (
        <List
            className={clsx(classes.navbarList, {
                [classes.navbarListOpened]: isOpen,
                [classes.navbarListCollapsed]: !isOpen,
            })}
        >
            {children}
        </List>
    );
};
