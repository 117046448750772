import { Positions } from './enums';

export const PositionsList = [
    {
        name: Positions.WAITER,
        value: Positions.WAITER,
    },
    {
        name: Positions.BARMEN,
        value: Positions.BARMEN,
    },
    {
        name: Positions.COOK,
        value: Positions.COOK,
    },
    {
        name: Positions.MANAGER,
        value: Positions.MANAGER,
    },
];
