import { AppThunkAction } from 'store';
import { RolesAction } from './actions';
import globalStore from 'store/globalStore';
import agent from 'api/agent';

export const actionCreators = {
    getRoles: (): AppThunkAction<RolesAction> => (dispatch) => {
        dispatch({
            type: 'ROLES_GET_START',
        });
        globalStore.actionCreators.showSpiner()(dispatch);
        agent.Roles.GetAll()
            .then((response) => dispatch({ type: 'ROLES_GET_SUCCESS', users: response }))
            .catch((e) => {
                dispatch({ type: 'ROLES_GET_ERROR' });
                globalStore.actionCreators.showToaster('error', e);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },
};
