import React from 'react';
import { useSelector } from 'react-redux';

import { Container, createStyles, makeStyles, MuiThemeProvider } from '@material-ui/core';
import i18n from 'i18n-js';

import NavigationBar from 'components/navigation';
import commonStyles from 'config/commonStyles';
import { ApplicationState } from 'store';
import { theme } from 'config/theme';
import { Positions } from 'constants/enums';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            '@media screen and (orientation: portrait)': {
                display: 'none',
            },
        },
        content: {
            ...commonStyles.flexRowCenter,
            height: '100vh',
            flexGrow: 1,
        },
        fullLayout: {
            flexGrow: 1,
            padding: 10,
        },
        messageContainer: {
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            zIndex: 999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& div': {
                backgroundColor: theme.palette.common.white,
                padding: 50,
                fontSize: 24,
            },
            '@media screen and (orientation: landscape)': {
                display: 'none',
            },
        },
    })
);

export default function Layout(props: any) {
    const classes = useStyles();
    const user = useSelector((state: ApplicationState) => state.user);
    const currentRestaurant = user.restaurants?.find((x) => x.id === user.currentRestaurantId);

    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.root}>
                <NavigationBar />
                {currentRestaurant?.currentPosition === Positions.COOK &&
                window.location.pathname === '/' ? (
                    <main className={classes.fullLayout}>{props.children}</main>
                ) : (
                    <main className={classes.content}>
                        <Container maxWidth="lg">{props.children}</Container>
                    </main>
                )}
            </div>
            <div className={classes.messageContainer}>
                <div>{i18n.t('warnings.portraitOrientationIsNotSupported')}</div>
            </div>
        </MuiThemeProvider>
    );
}
