import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Callback from 'components/callback/Callback';
import { RedirectToLogin } from 'components/callback/RedirectToLogin';
import silentRenew from 'components/silent-renew';
import { AdminRoute } from './AdminRoute';
import { RestaurantOwnerRoute } from './RestaurantOwnerRoute';
import { ApplicationState } from 'store';
import { Roles } from 'constants/enums';
import { RestaurantEmployeeRoute } from './RestaurantEmployeeRoute';
import PrivacyPolicy from 'components/policies/PrivacyPolicy';
import TermsConditions from 'components/policies/TermsConditions';
import DataDeletionInstructions from 'components/policies/DataDeletionInstructions';

export default function Routes() {
    const history = useHistory();
    const user = useSelector((state: ApplicationState) => state.user);
    const [locationKeys, setLocationKeys] = useState<(string | undefined)[]>([]);

    function prepareRoutes() {
        switch (user?.role) {
            case Roles.ADMIN:
                return <AdminRoute />;
            case Roles.OWNER:
                return <RestaurantOwnerRoute />;
            case Roles.EMPLOYEE:
                return <RestaurantEmployeeRoute />;
            default:
                return (
                    <Switch>
                        <Route exact path="/callback" component={Callback} />
                        <Route exact path="/silentRenew" component={silentRenew} />
                        <Route exact path="/redirecttologin" component={RedirectToLogin} />
                        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                        <Route exact path="/terms-conditions" component={TermsConditions} />
                        <Route exact path="/data-deletion" component={DataDeletionInstructions} />
                        <Route component={RedirectToLogin} />
                    </Switch>
                );
        }
    }

    useEffect(() => {
        return history.listen((location) => {
            if (history.action === 'PUSH') {
                if (location.key) setLocationKeys([location.key]);
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);
                    // Handle forward event
                } else {
                    setLocationKeys((keys) => [location.key, ...keys]);
                    // Handle back event
                }
            }
        });
    }, [history.location.pathname]); // eslint-disable-line

    return prepareRoutes();
}
