import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import i18n from 'i18n-js';
import {
    Avatar,
    createStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    Theme,
} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import NumberFormat from 'react-number-format';

import { UserData } from 'store/usersStore/reducer';
import {
    BasicThreeDotsMenu,
    SectionTab,
    PopupConfirmation,
    UserRoleLabel,
    Text,
} from 'components/shared';
import { AddRestaurantUserForm } from 'components/users/components';
import { EditRestaurantUserForm } from 'components/users/components';
import { default as usersStore } from 'store/usersStore';
import { default as restaurantStore } from 'store/restaurantDetailsStore';
import commonStyles from 'config/commonStyles';
import logo from 'assets/user-logo.svg';
import { ApplicationState } from 'store';
import AddNewUserModal from './AddNewUserModal';
import UsersTable from './UsersTable';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        tableRoot: {
            '& .MuiTableCell-head': {
                color: 'rgba(158, 158, 158, 1)',
            },
            '& .MuiListItem-gutters': {
                padding: '0',
            },
            '& .MuiPaper-root': {
                width: 1115,
            },
            '& .MuiToolbar-root': {
                paddingRight: 30,
                paddingLeft: 16,
                minHeight: 40,
                fontSize: 16,
            },
            '& .MuiTypography-root': {
                fontSize: 16,
            },
            '& .MuiCheckbox-root': {
                padding: 0,
            },
        },
        contactsTable: {
            minWidth: 650,
            /* '& th:last-child': {
                padding: 0,
            }, */
            '& .MuiTableHead-root .MuiTableCell-root': {
                paddingTop: 16,
            },
            '& th:first-child': {
                width: 10,
            },
            '& tr td:last-child': {
                padding: '0 15px 0 0',
            },
            '& .MuiTableCell-paddingNone': {
                padding: '0 !important',
            },
        },
        dialog: {
            '& .MuiDialogTitle-root > h2': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        },
        usersDialog: {
            overflowY: 'auto',
            '& .MuiDialog-paperWidthLg': {
                maxWidth: 936,
                minWidth: 936,
            },
        },
        cellWidth: {
            minWidth: '150px',
        },
    })
);

export default function RestaurantUsers() {
    const params = useParams<{ id: string }>();
    const classes = useStyles();
    const dispatch = useDispatch();
    // feature for selecting disabled for now
    // const [selected, setSelected] = useState<string[]>([]);
    const users = useSelector((state: ApplicationState) => state.users);
    const restaurant = useSelector((state: ApplicationState) => state.restaurantDetails);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
    const [userToEdit, setUserToEdit] = useState<UserData>({
        id: '',
        firstName: '',
        lastName: '',
        restaurants: [],
        email: '',
        phoneNumber: '',
        roles: [],
        isEnabled: false,
    });
    const [restaurantData, setRestaurantData] = useState({
        id: restaurant.restaurant.id,
        name: restaurant.restaurant.name,
        addressLine: restaurant.restaurant.addressLine,
        zip: restaurant.restaurant.zip,
        city: restaurant.restaurant.city,
        logoUrl: restaurant.restaurant.logoUrl ?? '',
    });
    const [orderBy, setOrderBy] = useState('name');
    const [asc, setAsc] = useState(true);
    const orderNames: string[] = ['name', 'email', 'phoneNumber', 'position', 'role'];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showUsersTable, setShowUsersTable] = useState(false);

    function handleOrder(id: string) {
        if (id === orderBy) {
            dispatch(
                usersStore.actionCreators.getUsers(
                    {
                        name: '',
                        restaurant: '',
                        email: '',
                        phoneNumber: '',
                        position: '',
                        roles: [],
                        restaurantId: params.id,
                    },
                    0,
                    100,
                    !asc,
                    orderBy
                )
            );
            setAsc(!asc);
        } else {
            setOrderBy(id);
            dispatch(
                usersStore.actionCreators.getUsers(
                    {
                        name: '',
                        restaurant: '',
                        email: '',
                        phoneNumber: '',
                        position: '',
                        roles: [],
                        restaurantId: params.id,
                    },
                    0,
                    100,
                    asc,
                    id
                )
            );
        }
    }

    // feature for single and group selecting disabled for now
    /* const handleSingleSelect = (id: string) => {
        if (selected.includes(id)) {
            setSelected(selected.filter((x) => x !== id));
        } else {
            setSelected([...selected, id]);
        }
    };
    const handleGroupSelect = () => {
        if (selected.length === 0) {
            setSelected(props.users?.users.map((y) => y.id));
        } else {
            setSelected([]);
        }
    }; */
    function handleEditModal() {
        setOpenEditModal(!openEditModal);
    }
    function handleCreateModal() {
        setOpenCreateModal(!openCreateModal);
    }

    function handleConfirmationPopup() {
        setOpenConfirmationPopup(!openConfirmationPopup);
    }

    function enableDisableUser(enable: boolean) {
        enable
            ? dispatch(usersStore.actionCreators.enableUser([userToEdit.id]))
            : dispatch(usersStore.actionCreators.disableUser([userToEdit.id]));
        handleConfirmationPopup();
    }

    function handleUserEdit(row: UserData) {
        setRestaurantData({
            id: restaurant.restaurant.id,
            name: restaurant.restaurant.name,
            addressLine: restaurant.restaurant.addressLine,
            zip: restaurant.restaurant.zip,
            city: restaurant.restaurant.city,
            logoUrl: restaurant.restaurant.logoUrl ?? '',
        });
        setUserToEdit({
            ...row,
            restaurants: row.restaurants.filter((x) => x.restaurantId === restaurant.restaurant.id),
        });
        handleEditModal();
    }

    useEffect(() => {
        dispatch(
            usersStore.actionCreators.getUsers(
                {
                    name: '',
                    restaurant: '',
                    email: '',
                    phoneNumber: '',
                    position: '',
                    roles: [],
                    restaurantId: params.id,
                },
                0,
                100,
                false,
                ''
            )
        );
        dispatch(restaurantStore.actionCreators.getRestaurantDetails(params.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <TableContainer className={classes.tableRoot}>
                <Table className={classes.contactsTable} aria-label="simple table">
                    <TableHead>
                        <TableRow key="table-head">
                            <TableCell className={classes.cellWidth}>
                                <TableSortLabel
                                    active={orderBy === orderNames[0]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[0])}
                                >
                                    {i18n.t('form.userName')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === orderNames[1]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[1])}
                                >
                                    {i18n.t('form.email')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === orderNames[2]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[2])}
                                >
                                    {i18n.t('form.phoneNumber')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === orderNames[3]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[3])}
                                >
                                    {i18n.t('form.position')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell colSpan={2} style={{ padding: 0 }}>
                                <TableSortLabel
                                    active={orderBy === orderNames[4]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[4])}
                                >
                                    {i18n.t('form.role')}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={7} padding="none">
                                <SectionTab
                                    text={i18n.t('user.newRestaurantUser')}
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        setRestaurantData({
                                            id: restaurant.restaurant.id,
                                            name: restaurant.restaurant.name,
                                            addressLine: restaurant.restaurant.addressLine,
                                            zip: restaurant.restaurant.zip,
                                            city: restaurant.restaurant.city,
                                            logoUrl: restaurant.restaurant.logoUrl ?? '',
                                        });
                                        setAnchorEl(event.currentTarget);
                                    }}
                                />
                                <AddNewUserModal
                                    anchorEl={anchorEl}
                                    handleClose={() => setAnchorEl(null)}
                                    openNew={() => setOpenCreateModal(true)}
                                    openExists={() => setShowUsersTable(true)}
                                />
                            </TableCell>
                        </TableRow>
                        {users?.users.map((row) => (
                            <TableRow
                                key={row.id}
                                className={row.id}
                                style={!row.isEnabled ? { opacity: '30%' } : {}}
                            >
                                <TableCell
                                    scope="row"
                                    onClick={() => {
                                        handleUserEdit(row);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <ListItem alignItems="center">
                                        <ListItemAvatar>
                                            <Avatar alt="Remy Sharp" src={logo} />
                                        </ListItemAvatar>
                                        <Text
                                            text={`${row.firstName} ${row.lastName}`}
                                            maxLength={25}
                                        />
                                    </ListItem>
                                </TableCell>
                                <TableCell align="left">
                                    <Text text={row.email} maxLength={30} fontSize={14} />
                                </TableCell>
                                <TableCell align="left">
                                    {row.phoneNumber ? (
                                        <NumberFormat
                                            format="+## (###) ### ## ##"
                                            displayType={'text'}
                                            value={row.phoneNumber}
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </TableCell>
                                <TableCell align="left">
                                    {row.restaurants
                                        .filter((x) => x.restaurantId === params.id)[0]
                                        ?.positions?.join(', ') ?? '-'}
                                </TableCell>
                                <TableCell align="left">
                                    <UserRoleLabel role={row.roles[0]?.name} />
                                </TableCell>
                                <TableCell align="right">
                                    <BasicThreeDotsMenu
                                        items={[
                                            <div
                                                className={classes.threeDotsMenuItemContainer}
                                                onClick={() => {
                                                    handleUserEdit(row);
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <BorderColorIcon
                                                        fontSize="small"
                                                        color="disabled"
                                                    />
                                                </ListItemIcon>
                                                <Typography variant="inherit">Edit</Typography>
                                            </div>,
                                            <div
                                                className={classes.threeDotsMenuItemContainer}
                                                onClick={() => {
                                                    setUserToEdit(row);
                                                    handleConfirmationPopup();
                                                }}
                                            >
                                                <ListItemIcon>
                                                    {row.isEnabled ? (
                                                        <RemoveCircleOutlineIcon
                                                            fontSize="small"
                                                            color="disabled"
                                                        />
                                                    ) : (
                                                        <SettingsBackupRestoreIcon
                                                            fontSize="small"
                                                            color="disabled"
                                                        />
                                                    )}
                                                </ListItemIcon>
                                                <Typography variant="inherit">
                                                    {row.isEnabled
                                                        ? i18n.t('button.disable')
                                                        : i18n.t('button.restore')}
                                                </Typography>
                                            </div>,
                                        ]}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={openEditModal}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
            >
                <DialogTitle id="form-dialog-title">
                    {i18n.t('user.editUser')} <UserRoleLabel role={userToEdit.roles[0]?.name} />
                </DialogTitle>

                <DialogContent>
                    <EditRestaurantUserForm
                        user={userToEdit}
                        restaurants={[restaurantData]}
                        editRestaurantUser={(model: any) =>
                            dispatch(usersStore.actionCreators.editRestaurantUser(model))
                        }
                        cancel={handleEditModal}
                        close={handleEditModal}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openCreateModal}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
            >
                <DialogTitle id="form-dialog-title">{i18n.t('user.createNewUser')}</DialogTitle>
                <DialogContent>
                    <AddRestaurantUserForm
                        createUser={(model: any) =>
                            dispatch(usersStore.actionCreators.createRestaurantUser(model))
                        }
                        restaurants={[{ ...restaurantData }]}
                        currentRestaurant={restaurantData}
                        cancel={handleCreateModal}
                    />
                </DialogContent>
            </Dialog>
            <PopupConfirmation
                open={openConfirmationPopup}
                close={handleConfirmationPopup}
                title={
                    userToEdit.isEnabled
                        ? i18n.t('common.disableUser')
                        : i18n.t('common.restoreUser')
                }
                description={
                    userToEdit.isEnabled
                        ? i18n.t('confirmation.disableUser')
                        : i18n.t('confirmation.restoreUser')
                }
                activeBtn={
                    userToEdit.isEnabled ? i18n.t('common.disable') : i18n.t('common.enable')
                }
                action={() => enableDisableUser(!userToEdit.isEnabled)}
            />
            <Dialog open={showUsersTable} maxWidth={'lg'} className={classes.usersDialog}>
                <UsersTable
                    close={() => setShowUsersTable(false)}
                    restaurant={{
                        id: params.id,
                        name: restaurantData.name,
                    }}
                />
            </Dialog>
        </>
    );
}
