import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Backdrop, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ApplicationState } from 'store';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        height: '100%',
        width: '100%',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

export default function Spinner() {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const globalState = useSelector((state: ApplicationState) => state.global);

    useEffect(() => {
        const delayRender = setTimeout(() => {
            setShow(globalState.isLoading);
        }, 500);
        return () => clearTimeout(delayRender);
    }, [globalState.isLoading]);

    return (
        <>
            {show && (
                <div className={classes.root}>
                    <Backdrop open>
                        <CircularProgress size={0.9 * 100} />
                    </Backdrop>
                </div>
            )}
        </>
    );
}
