import React, { useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import {
    alpha,
    Checkbox,
    createStyles,
    makeStyles,
    TextField,
    Theme,
    Tooltip,
} from '@material-ui/core';

import commonStyles from '../../../config/commonStyles';
import { truncateString } from '../../../helpers/helperFunctions';
import { images } from '../../../assets';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        listBox: {
            marginTop: 0,
            padding: 0,
        },
        paper: {
            marginTop: 0,
        },
        dropdownItem: {
            padding: 0,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: alpha(theme.palette.primary.main, 0.16),
            },
        },
        selectedDropdownItem: {
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
        poper: {
            position: 'fixed',
        },
    })
);
interface Props {
    options: { name: string; id: string }[];
    inputLabel: string;
    onChange: (values: { id: string; name: string }[]) => void;
    onFocus?: () => void;
    style?: React.CSSProperties;
    popupIcon?: string;
    trimLength?: number;
}

const MultiSelectInputDropdown = ({
    options,
    inputLabel,
    onChange,
    onFocus,
    style,
    popupIcon = images.icons.filter,
    trimLength = 25,
}: Props) => {
    const [values, setValues] = useState<{ id: string; name: string }[]>([]);
    const classes = useStyles();

    function getInputName() {
        let names = '';
        values.forEach((value) => {
            names += value.name + ' / ';
        });
        return names;
    }

    return (
        <Autocomplete
            style={style}
            options={options}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            disablePortal
            classes={{
                option: classes.dropdownItem,
                listbox: classes.listBox,
                paper: classes.paper,
                popper: popupIcon === images.icons.filter ? undefined : classes.poper,
            }}
            popupIcon={
                <img
                    src={popupIcon}
                    alt={'filter-icon'}
                    style={{
                        width: popupIcon === images.icons.filter ? 18 : 'auto',
                        marginRight: 5,
                    }}
                />
            }
            renderOption={(option) => {
                const exists = values.find((each) => each.id === option.id);
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let newValues;
                            if (exists) {
                                newValues = values.filter((each) => each.id !== option.id);
                            } else {
                                newValues = [...values, option];
                            }
                            setValues(newValues);
                            onChange(newValues);
                        }}
                        className={`${classes.flexRowSpaceBetween} ${classes.width100} ${
                            classes.bottomLine
                        } ${exists ? classes.selectedDropdownItem : ''}`}
                    >
                        <div className={classes.flexRow}>
                            <Checkbox color="primary" checked={!!exists} />
                            <span>{option.name}</span>
                        </div>
                    </div>
                );
            }}
            renderInput={(params) => (
                <>
                    <Tooltip title={getInputName()}>
                        <TextField
                            {...params}
                            name="type"
                            label={
                                values.length > 0
                                    ? truncateString(getInputName(), trimLength)
                                    : inputLabel
                            }
                            variant="filled"
                            style={{
                                opacity: popupIcon === images.icons.filter ? 0.6 : 1,
                                cursor: 'pointer',
                            }}
                            onFocus={onFocus}
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                            }}
                        />
                    </Tooltip>
                </>
            )}
        />
    );
};

export default MultiSelectInputDropdown;
