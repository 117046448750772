import React from 'react';

import * as yup from 'yup';
import i18n from 'i18n-js';
import { Button, DialogActions } from '@material-ui/core';
import { Form, Formik } from 'formik';

import { regexHelper } from 'helpers/regexHelper';
import { AdminCreateModel } from 'store/usersStore/reducer';
import { CustomPhoneInput, Input } from 'components/shared';

interface AddAdminProps {
    createUser: any;
    cancel: any;
}

export default function AddAdminForm(props: AddAdminProps) {
    const initalFormValues: AdminCreateModel = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
    };

    const validationSchema = yup.object().shape({
        firstName: yup
            .string()
            .matches(
                regexHelper.onlyLetters,
                i18n.t('form.errors.lettersOnly', {
                    name: 'First Name',
                })
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'First Name',
                })
            ),
        lastName: yup
            .string()
            .matches(
                regexHelper.onlyLetters,
                i18n.t('form.errors.lettersOnly', {
                    name: 'Last Name',
                })
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'Last Name',
                })
            ),
        email: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Email',
                })
            )
            .email(i18n.t('form.errors.email'))
            .matches(regexHelper.email, i18n.t('form.errors.email')),
        phoneNumber: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Phone Number',
                })
            )
            .matches(regexHelper.phoneNumber, i18n.t('form.errors.phoneNumber')),
    });

    const submitPromise = async (model: AdminCreateModel) => {
        props.createUser(model);
    };

    return (
        <Formik
            initialValues={initalFormValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
                submitPromise(values).then(() => {
                    resetForm();
                    props.cancel();
                });
            }}
        >
            {({ values, errors, setFieldValue, submitForm }) => (
                <Form>
                    <Input
                        error={errors.firstName}
                        variant="filled"
                        fullWidth={true}
                        name={`firstName`}
                        type="text"
                        label={i18n.t('form.firstName')}
                        placeholder={i18n.t('form.firstName')}
                        onBlur={(e: any) => setFieldValue('firstName', e.target.value.trim())}
                    />
                    <Input
                        error={errors.lastName}
                        variant="filled"
                        fullWidth={true}
                        name={`lastName`}
                        type="text"
                        label={i18n.t('form.lastName')}
                        placeholder={i18n.t('form.lastName')}
                        onBlur={(e: any) => setFieldValue('lastName', e.target.value.trim())}
                    />
                    <Input
                        error={errors.email}
                        variant="filled"
                        fullWidth={true}
                        name={`email`}
                        type="text"
                        label={i18n.t('form.email')}
                        placeholder={i18n.t('form.email')}
                    />
                    <CustomPhoneInput
                        containerStyle={{
                            marginTop: '0px',
                        }}
                        invalid={errors.phoneNumber}
                        placeholder={i18n.t('form.phoneNumber')}
                        value={values.phoneNumber}
                        onChange={(val) => setFieldValue('phoneNumber', val)}
                    />
                    <DialogActions>
                        <Button type="button" color="primary" onClick={props.cancel}>
                            {i18n.t('common.cancel')}
                        </Button>
                        <Button onClick={submitForm} variant="contained" color="primary">
                            {i18n.t('common.create')}
                        </Button>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    );
}
