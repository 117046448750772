import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface Props {
    label: string;
    items: { name: string; id: string }[];
}

const MultiSelectDropdown = ({ label, items }: Props) => {
    const classes = useStyles();
    const [values, setValues] = useState<string[]>([]);

    const handleChangeMultiple = (event: any) => {
        const id = event.target.value[0];
        let newValues;
        if (values.includes(id)) {
            newValues = [...values].filter((each) => each !== id);
        } else {
            newValues = [...values, id];
        }
        setValues(newValues);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-mutiple-name-label">{label}</InputLabel>
            <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                multiple
                value={values}
                onChange={handleChangeMultiple}
                input={<Input />}
                MenuProps={MenuProps}
            >
                {items.map((item) => (
                    <MenuItem key={item.name} value={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiSelectDropdown;
