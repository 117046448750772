import React from 'react';

import { Tooltip } from '@material-ui/core';

import { images } from 'assets';
import { BasicThreeDotsMenu } from 'components/shared';
import i18n from 'i18n-js';
import { helperFunctions } from 'helpers';
import { useStyles } from '../styles';

interface SectionHeaderProps {
    title?: string;
    containerId?: string;
    amount?: string | number;
    withPlus?: boolean;
    filter?: number;
    onFilterClick: (val: number) => void;
    threeDotItems?: JSX.Element[];
}

export default function SectionHeader({
    title = 'Some Title',
    containerId,
    amount = 0,
    withPlus,
    filter = 0,
    onFilterClick,
    threeDotItems,
}: SectionHeaderProps) {
    const classes = useStyles();

    function filterButton(text: string, active: boolean, i: number) {
        return (
            <div
                onClick={() => onFilterClick(i)}
                className={`${classes.filterButton} ${
                    active ? classes.activeFilterButton : classes.passiveFilterButton
                }`}
            >
                <span
                    className={
                        active ? classes.activeFilterButtonText : classes.passiveFilterButtonText
                    }
                >
                    {text}
                </span>
            </div>
        );
    }

    function hasFilterAndAmount() {
        return filter > 0 && amount > 0;
    }

    return (
        <div
            id={containerId}
            style={{ height: hasFilterAndAmount() ? 109 : 63 }}
            className={classes.sectionHeaderContainer}
        >
            <div className={classes.flexRowSpaceBetween}>
                <div className={classes.flexRow}>
                    <span className={classes.sectionHeaderText}>{title} </span>
                    <span className={classes.mHorizontal5}>{' /'} </span>
                    <span className={classes.sectionHeaderText}>{amount}</span>
                </div>
                <div className={classes.flexRow}>
                    {withPlus && (
                        <img
                            src={images.icons.circlePlus}
                            alt="circle-plus"
                            className={classes.mRight20}
                        />
                    )}
                    <BasicThreeDotsMenu items={threeDotItems} />
                </div>
            </div>
            {hasFilterAndAmount() && (
                <div className={`${classes.flexRowSpaceBetween} ${classes.filterButtonContainer}`}>
                    <div>
                        <Tooltip title={i18n.t('common.order')}>
                            {filterButton(
                                helperFunctions.truncateLangString(i18n.t('common.order'), 8),
                                filter === 1,
                                1
                            )}
                        </Tooltip>
                    </div>
                    <div>{filterButton(i18n.t('common.dish'), filter === 2, 2)}</div>
                    <div>{filterButton(i18n.t('common.qr'), filter === 3, 3)}</div>
                </div>
            )}
        </div>
    );
}
