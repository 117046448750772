import React from 'react';

import i18n from 'i18n-js';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export interface Props {
    onEditClick: () => void;
    text?: string;
}

export default function EditMenuItem({ onEditClick, text = i18n.t('common.editItem') }: Props) {
    return (
        <>
            <StyledMenuItem
                color="primary"
                onClick={(e) => {
                    e.stopPropagation();
                    onEditClick();
                }}
            >
                <EditIcon style={{ fill: 'rgba(0, 0, 0, 0.54)', paddingRight: '20px' }} />
                <ListItemText primary={text} />
            </StyledMenuItem>
        </>
    );
}
