import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import i18n from 'i18n-js';

import userManager from 'managers/userManager';

import {
    Restaurants,
    Users,
    Roles,
    Menus,
    Common,
    Category,
    Order,
    User,
    Ingredients,
} from './controllers';

axios.defaults.baseURL = process.env.REACT_APP_API;
export const identityUrl = process.env.REACT_APP_IDENTITY;
userManager.getUser().then((user) => {
    if (user && !user.expired) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access_token;
        //axios.defaults.headers.common['timezone'] = getBrowserTimezone();
    }
});

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!error.response) {
            return Promise.reject(i18n.t('warnings.connectionError'));
        } else if (400 === error.response.status) {
            return Promise.reject(i18n.t('warnings.wrongDataProvided'));
        } else if (401 === error.response.status) {
            userManager.signoutRedirect();
            userManager.removeUser();
            return Promise.reject(i18n.t('warnings.sessionExpired'));
        } else if (404 === error.response.status) {
            return Promise.reject(i18n.t('warnings.pageNotFound'));
        } else if (409 === error.response.status) {
            return Promise.reject(error.response.data);
        } else {
            return Promise.reject(i18n.t('common.somethingWrong'));
        }
    }
);

export function prepareHeaders(noJson: boolean = false) {
    return {
        headers: noJson
            ? {
                  'Accept-Language': localStorage.getItem('jamesLanguage') || 'de',
              }
            : {
                  'Content-Type': 'application/json;charset=UTF-8',
                  'Accept-Language': localStorage.getItem('jamesLanguage') || 'de',
              },
    };
}

const responseBody = (response: AxiosResponse) => response.data;

export const requests = {
    get: (url: string, params?: {}) =>
        axios
            .get(url, {
                ...params,
                ...prepareHeaders(true),
            })
            .then(responseBody),
    post: (url: string, body: {} = {}) =>
        axios.post(url, body, prepareHeaders()).then(responseBody),
    put: (url: string, body: {}, config: AxiosRequestConfig | undefined) =>
        axios.put(url, body, config).then(responseBody),
    delete: (url: string, data?: {}) =>
        axios
            .delete(url, {
                data,
            })
            .then(responseBody),
};

/* const IdentityServer = {
    SignIn: () => requests.get(''),
    SignUp: (email: string, password: string) => requests.post('', { email, password }),
}; */

const agent = {
    requests,
    /* IdentityServer, */
    Restaurants,
    Users,
    Roles,
    Menus,
    Common,
    Category,
    Order,
    User,
    Ingredients,
};

export default agent;
