import React from 'react';

import i18n from 'i18n-js';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export interface Props {
    onDeleteClick: any;
    text?: string;
}

export default function DeleteMenuItem({
    onDeleteClick,
    text = i18n.t('common.deleteSection'),
}: Props) {
    return (
        <>
            <StyledMenuItem
                color="primary"
                onClick={(e) => {
                    e.stopPropagation();
                    onDeleteClick();
                }}
            >
                <DeleteIcon fontSize="small" color="disabled" style={{ marginRight: 23 }} />
                <ListItemText primary={text} />
            </StyledMenuItem>
        </>
    );
}
