import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import i18n from 'i18n-js';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Table,
    TableBody,
    TableRow,
    TableCell,
    DialogActions,
    Button,
} from '@material-ui/core';
import { HubConnectionBuilder, HttpTransportType, HubConnection } from '@microsoft/signalr';

import agent from 'api/agent';
import images from 'assets/images';
import { default as ordersStore } from 'store/ordersStore';
import OrdersListStatus from 'components/orders/ordersList/OrdersListStatus';
import { OrderUpdateStatus } from 'constants/enums';
import { formatTime, formatDate, dateTimeIgnoreTimezone } from 'helpers/datetimeHelper';
import { Text } from 'components/shared';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';

interface OverdudedOrdersPanelProps {
    navigationBarOpen: boolean;
}

export default function OverdudedOrdersPanel(props: OverdudedOrdersPanelProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const orders = useAppSelector((state) => state.orders);
    const user = useAppSelector((state) => state.user);
    const location = useAppSelector((state) => state.router.location.pathname);
    const [showOverdudedOrdersModal, setShowOverdudedOrdersModal] = useState(false);
    const [connection, setConnection] = useState<HubConnection | null>(null);
    const interval = useRef<any | null>(null);

    function startConnection() {
        connection
            ?.start()
            .then(() => {
                console.log('SignalR Connected.');
                connection.on('UpdateOrders', handleMessage);
            })
            .catch((e: any) => {
                console.log('Connection failed: ', e);
                setTimeout(() => {
                    connection.state === 'Disconnected' && startConnection();
                }, 5000);
            });
    }

    function handleMessage(message: { actionType: number; restaurantId: string }) {
        const { actionType, restaurantId } = message;
        if (user.currentRestaurantId === restaurantId) {
            switch (actionType) {
                case OrderUpdateStatus.ShowOverduedOrders:
                    agent.Order.GetOverdudedOrders().then((result) => {
                        dispatch(ordersStore.actionCreators.setOverdudedOrders(result));
                        setShowOverdudedOrdersModal(result.length > 0);
                    });
            }
        }
    }

    const formatedDate = (date: number) => {
        const newDate = dateTimeIgnoreTimezone(date);
        return `${formatTime(newDate)} ${formatDate(newDate)}`;
    };

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_ORDERS_HUB!, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (connection) {
            startConnection();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection]);

    useEffect(() => {
        dispatch(ordersStore.actionCreators.getOverdudedOrders());
        interval.current = setInterval(() => {
            dispatch(ordersStore.actionCreators.getOverdudedOrders());
        }, 60000);
        return () => clearInterval(interval.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return orders.overdudedOrders.length > 0 ? (
        <>
            <div
                className={classes.overdudedPannel}
                onClick={() => setShowOverdudedOrdersModal(!showOverdudedOrdersModal)}
            >
                {props.navigationBarOpen && (
                    <Text
                        fontSize={13}
                        maxLength={26}
                        text={`${orders.overdudedOrders.length} ${i18n.t(
                            'messages.unclosedOrders'
                        )}`}
                        withoutMore
                    />
                )}
                <img src={images.icons.whiteWarning} alt="white-warning" />
            </div>
            <Dialog
                open={showOverdudedOrdersModal}
                //onClick={preventProp}
                className={classes.overdudedTable}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                    id="alert-dialog-title"
                >
                    {`${orders.overdudedOrders.length} ${i18n.t('messages.unclosedOrders')}`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        style={{ display: 'flex', justifyContent: 'flex-start' }}
                        id="alert-dialog-description"
                    >
                        {i18n.t('messages.ordersPreparationTimeOver')}
                    </DialogContentText>
                    <Table aria-label="simple table">
                        <TableBody>
                            {orders.overdudedOrders.map((order) => (
                                <TableRow key={order.id}>
                                    <TableCell component="th" scope="row">
                                        {`№ ${order.orderNumber}`}
                                    </TableCell>
                                    <TableCell align="right">
                                        {formatedDate(order.orderCreationDate)}
                                    </TableCell>
                                    <TableCell align="right">
                                        <OrdersListStatus status={order.status} showReady={false} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={() => setShowOverdudedOrdersModal(false)}>
                        {i18n.t('button.close')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            dispatch(
                                ordersStore.actionCreators.prepareGettingDetailedOverdudedOrders(
                                    orders.overdudedOrders
                                )
                            );
                            setShowOverdudedOrdersModal(false);
                            if (location === '/orders') {
                                dispatch(
                                    ordersStore.actionCreators.getDetailedOverdudedOrders(
                                        orders.overdudedOrders
                                    )
                                );
                            } else {
                                history.push('/orders');
                            }
                        }}
                    >
                        {i18n.t('common.details')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    ) : null;
}
