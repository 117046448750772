import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createStyles, makeStyles, Menu, MenuItem, Theme, Tooltip } from '@material-ui/core';

import { OrderStatus } from 'constants/enums';
import { OrderDetails } from 'store/ordersStore/reducer';
import { ApplicationState } from 'store';
import { default as restaurantStore } from 'store/restaurantDetailsStore';
import { truncateString, removeWhiteSpace } from 'helpers/helperFunctions';
import commonStyles from '../../../config/commonStyles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        orderHeaderItem: {
            borderRadius: 7,
            alignItems: 'flex-start',
            border: '0.5px solid transparent',
            cursor: 'pointer',
            padding: '0 5px 0 2px',
            '&>div:first-child': {
                marginBottom: 3,
            },
            '&:nth-last-child(-n+2):hover, &:nth-last-child(-n+2):active': {
                border: '0.5px solid #99567E',
            },
            marginBottom: 5,
        },
        orderQrCodeName: {
            fontSize: '20px',
            fontWeight: 700,
            color: theme.palette.common.black,
        },
    })
);

export default function OrderQRLeftCode(props: {
    order: OrderDetails;
    submit: (qrCodeName: string) => void;
    isDisabled: boolean;
}) {
    const classes = useStyles();
    const orders = useSelector((state: ApplicationState) => state.orders);
    const restaurantQRs = useSelector((state: ApplicationState) => state.restaurantDetails.qrCodes);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event: any) {
        !props.isDisabled && setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    useEffect(() => {
        restaurantQRs?.length === 0 && dispatch(restaurantStore.actionCreators.getQrCodes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div
            style={{
                position: 'relative',
                display: props.order.id.length > 0 ? 'initial' : 'none',
                width: '100%',
            }}
        >
            {props.order.qrCodeName.length > 0 ? (
                <Tooltip
                    title={removeWhiteSpace(props.order.qrCodeName)}
                    placement="bottom-start"
                    //disableHoverListener={props.isOpen}
                >
                    <span
                        style={{
                            cursor:
                                props.order.status !== OrderStatus.Closed && !props.isDisabled
                                    ? 'pointer'
                                    : 'auto',
                            position: 'relative',
                            visibility: orders.orderDetailsId ? 'visible' : 'hidden',
                        }}
                        onClick={handleClick}
                        className={`${classes.orderQrCodeName} ${classes.flexColumnSpaceBetween} 
                ${!!anchorEl && classes.selected}`}
                    >
                        {truncateString(removeWhiteSpace(props.order.qrCodeName), 11)}
                    </span>
                </Tooltip>
            ) : (
                <span className={classes.orderQrCodeName} style={{ visibility: 'hidden' }}>
                    0
                </span>
            )}
            {props.order.status !== OrderStatus.Closed && (
                <Menu
                    id="qr-codes-menu"
                    elevation={8}
                    anchorEl={anchorEl}
                    keepMounted={false}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            transform: 'translateX(10px) translateY(60px)',
                        },
                    }}
                >
                    {restaurantQRs.map((qr) => (
                        <MenuItem
                            key={qr.id}
                            onClick={() => {
                                props.submit(qr.name);
                                handleClose();
                            }}
                        >
                            {removeWhiteSpace(qr.name)}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </div>
    );
}
