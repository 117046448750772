import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Paper,
    TableContainer,
    Toolbar,
    Typography,
} from '@material-ui/core';
import i18n from 'i18n-js';

import logo from 'assets/breakfast-logo.svg';
import mainLogo from 'assets/Img-Welcome.svg';
import { MenuDetailsData } from 'store/menuDetailsStore/reducer';
import { MenuType } from 'constants/enums';
import DishCard from './DishCard';
import { MenuSections } from '..';
import agent from 'api/agent';
import { DishCreateModel } from 'api/models';
import { OrderDetailsItem } from 'store/ordersStore/reducer';
import globalStore from 'store/globalStore';
import { useStyles } from '../styles';

export default function SearchResultTable(props: {
    menus: MenuDetailsData[];
    sections: MenuSections[];
    searchString: string;
    recalculate: any;
    addItem?: (
        id?: string | undefined,
        amount?: number | undefined,
        item?: OrderDetailsItem | undefined
    ) => void;
    isModal?: boolean;
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedDishId, setSelectedDishId] = useState('');

    function updateDish(model: DishCreateModel, id: string) {
        dispatch(globalStore.actionCreators.showSpiner());
        agent.Menus.EditDish(model, id)
            .then((response) => {
                props.recalculate(response);
            })
            .catch((e) => dispatch(globalStore.actionCreators.showToaster('error', e)))
            .finally(() => dispatch(globalStore.actionCreators.hideSpiner()));
    }
    function deleteDish(id: string) {
        agent.Menus.DeleteDish({ id: id })
            .then((response) => {
                props.recalculate(response, true);
            })
            .catch((error) => alert(error));
    }

    function getDishAmount(menu: MenuDetailsData) {
        let amount = 0;
        menu.sections.forEach((eachSection) => {
            amount += eachSection.dishes.length;
        });
        return amount;
    }

    return props.menus.length > 0 ? (
        <TableContainer component={Paper} className={classes.searchResultRoot}>
            {props.menus.map((menu) => (
                <>
                    <Toolbar>
                        <Typography
                            variant="h6"
                            id="tableTitle"
                            component="div"
                            className={classes.toolbarTitle}
                        >
                            {menu.name}
                            <img src={logo} className={classes.breakfastLogo} alt="logo" />
                        </Typography>
                        <h5>{`${getDishAmount(menu)} item${
                            getDishAmount(menu) !== 1 ? 's' : ''
                        }`}</h5>
                    </Toolbar>
                    <Paper className={classes.paper}>
                        {menu.sections.map((section) => (
                            <Accordion square expanded className={classes.searchResultRoot}>
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <Typography>{section.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.detailsList}>
                                    {section.dishes?.length > 0 &&
                                        section.dishes.map((dish) => (
                                            <DishCard
                                                dish={dish}
                                                sections={
                                                    props.sections.find(
                                                        (x) => x.menuId === menu.id
                                                    )!.sections
                                                }
                                                updateAction={updateDish}
                                                deleteAction={deleteDish}
                                                isDaily={menu.type === MenuType.Daily}
                                                searchString={props.searchString}
                                                isModal={props.isModal}
                                                isSelected={selectedDishId === dish.id}
                                                setSelected={setSelectedDishId}
                                            />
                                        ))}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Paper>
                </>
            ))}
        </TableContainer>
    ) : (
        <div className={classes.nothingToSee}>
            <img src={mainLogo} alt="logo" />
            <h2>{i18n.t('main.nothingToSee')}</h2>
            <h2>{i18n.t('main.tryAnotherWord')}</h2>
            <br />
            <br />
            <br />
        </div>
    );
}
