import React from 'react';

import userManager from 'managers/userManager';

const silentRenew = () => {
    userManager.signinSilentCallback();
    return <></>;
};

export default silentRenew;
