import React from 'react';
import ColorPicker from 'react-best-gradient-color-picker';
import i18n from 'i18n-js';
import {
    Dialog,
    DialogContent,
    Button,
    DialogActions,
    makeStyles,
    createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& .MuiDialog-paperWidthXs': {
                width: 'auto',
            },
            '& .MuiDialogActions-root': {
                paddingRight: 22,
                marginTop: 10,
            },
        },
    })
);

interface ColorSelectorProps {
    isOpen: boolean;
    onChange: (value: string) => void;
    onClose: () => void;
    value: string;
    onSave: () => void;
    onCancel: () => void;
}

export default function ColorSelector(props: ColorSelectorProps) {
    const classes = useStyles();

    const customPresets = [
        'rgba(255, 205, 200, 1)',
        'rgba(243, 161, 154, 1)',
        'rgba(255, 219, 166, 1)',
        'rgba(255, 185, 80, 1)',
        'rgba(255, 242, 172, 1)',
        'rgba(255, 227, 80, 1)',
        'rgba(212, 244, 186, 1)',
        'rgba(181, 224, 148, 1)',
        'rgba(191, 233, 243, 1)',

        'rgba(245, 19, 0, 1)',
        'rgba(174, 14, 0, 1)',
        'rgba(245, 147, 0, 1)',
        'rgba(200, 120, 0, 1)',
        'rgba(245, 206, 0, 1)',
        'rgba(192, 161, 0, 1)',
        'rgba(116, 216, 37, 1)',
        'rgba(72, 155, 8, 1)',
        'rgba(37, 184, 216, 1)',
    ];
    return (
        <Dialog
            maxWidth="xs"
            open={props.isOpen}
            onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
            className={classes.root}
        >
            <DialogContent>
                <ColorPicker
                    hideControls
                    hideInputs
                    value={props.value}
                    onChange={props.onChange}
                    presets={customPresets}
                />
            </DialogContent>
            <DialogActions>
                <Button type="button" color="primary" onClick={props.onCancel}>
                    {i18n.t('button.cancel')}
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        props.onSave();
                        props.onClose();
                    }}
                >
                    {i18n.t('common.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
