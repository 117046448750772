import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NothingToSee } from 'components/shared';
import MenuDetails from 'components/menu/MenusPage/components/MenuDetails';
import { MenusPage } from 'components/menu/MenusPage';
import RestaurantInformation from 'components/restaurantInformation';
import { OrdersPage } from 'components/orders';
import Home from 'components/home';
import { RestaurantUsersPage } from 'components/restaurants/restaurantUsersPage/RestaurantUsersPage';
import Finance from 'components/restaurantInformation/finance';
import Callback from 'components/callback/Callback';
import silentRenew from 'components/silent-renew';
import Ingredients from '../components/ingredients';

export const RestaurantOwnerRoute: React.FC<{}> = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/orders" component={OrdersPage} />
            <Route exact path="/menu" component={MenusPage} />
            <Route exact path="/menu/:id" component={MenuDetails} />
            <Route exact path="/ingredients" component={Ingredients} />
            <Route exact path="/users" component={RestaurantUsersPage} />
            <Route exact path="/restaurantInformation" component={RestaurantInformation} />
            <Route exact path="/finance" component={Finance} />
            <Route exact path="/callback" component={Callback} />
            <Route exact path="/silentRenew" component={silentRenew} />
            <Route component={NothingToSee} />
        </Switch>
    );
};
