import { AppThunkAction } from 'store';

import i18n from 'i18n-js';

import agent from 'api/agent';
import { MenuCreateModel, CopyMenuModel } from 'api/models';
import globalStore from '../globalStore';
import { MenusActions } from './actions';

export const actionCreators = {
    createMenu:
        (model: MenuCreateModel): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_CREATE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.Create(model)
                .then((response) => {
                    dispatch({
                        type: 'MENU_CREATE_SUCCESS',
                        menu: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.createdSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_CREATE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    editMenu:
        (model: MenuCreateModel, id: string): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_EDIT_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.Edit(model, id)
                .then((response) => {
                    dispatch({
                        type: 'MENU_EDIT_SUCCESS',
                        menu: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_EDIT_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    getMenus:
        (id?: string): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENUS_GET_LIST_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.GetMenusDetails(id)
                .then((response) => {
                    dispatch({
                        type: 'MENUS_GET_LIST_SUCCESS',
                        menus: response,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENUS_GET_LIST_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    getDishList: (): AppThunkAction<MenusActions> => (dispatch) => {
        dispatch({
            type: 'MENU_GET_DISH_LIST_START',
        });
        agent.Menus.GetDishList()
            .then((response) => {
                dispatch({
                    type: 'MENU_GET_DISH_LIST_SUCCESS',
                    dishList: response,
                });
            })
            .catch((e) => {
                dispatch({
                    type: 'MENU_GET_DISH_LIST_ERROR',
                });
                globalStore.actionCreators.showToaster('error', e)(dispatch);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },

    disableMenu:
        (id: string): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_DISABLE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.Disable(id)
                .then((response) => {
                    dispatch({
                        type: 'MENU_DISABLE_SUCCESS',
                        menu: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_DISABLE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    deleteMenu:
        (id: string): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_DELETE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.Delete(id)
                .then(() => {
                    dispatch({
                        type: 'MENU_DELETE_SUCCESS',
                        menuId: id,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.deletedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_DELETE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    reorderMenus:
        (ids: string[]): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENUS_REORDER_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.ReorderMenus(ids)
                .then((response) => {
                    dispatch({
                        type: 'MENUS_REORDER_SUCCESS',
                        menus: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENUS_REORDER_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    copyMenu:
        (menuId: string, model: CopyMenuModel, action?: () => void): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_COPY_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.CopyMenu(menuId, model)
                .then((response) => {
                    dispatch({
                        type: 'MENU_COPY_SUCCESS',
                        payload: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                    action && action();
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_COPY_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
};
