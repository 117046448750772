import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { User } from 'oidc-client';
import axios from 'axios';

import userManager from 'managers/userManager';
import { Roles } from 'constants/enums';
import { default as userStore } from 'store/userStore';

export default function CallbackPage() {
    const dispatch = useDispatch();
    const history = useHistory();

    const successCallback = (user: User) => {
        const userId = user.profile.sub;
        dispatch({
            type: 'SET_AUTH_USER_ID',
            data: userId,
        });
        // get the user's previous location, passed during signinRedirect()
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access_token;
        dispatch(userStore.actionCreators.userSetRole(user.profile.role));
        let redirectPath = user.state?.path ? (user.state.path as string) : '/';
        if (redirectPath === '/' && !!user?.profile?.role) {
            switch (user.profile.role) {
                case Roles.ADMIN:
                    redirectPath = '/restaurants';
                    break;
                case Roles.EMPLOYEE:
                    redirectPath = '/orders';
                    break;
                case Roles.OWNER:
                    redirectPath = '/orders';
                    break;
            }
        }

        history.push(redirectPath);
    };

    const errorCallback = (_error: Error) => {
        console.log(_error);
        history.push('/');
    };

    useEffect(() => {
        userManager.getUser().then((user) => {
            if (user) {
                successCallback(user);
            } else {
                userManager
                    .signinRedirectCallback()
                    .then((user) => {
                        successCallback(user);
                    })
                    .catch((error) => {
                        errorCallback(error);
                    });
            }
        });
    }, []); // eslint-disable-line

    return null;
}
