import React from 'react';

import i18n from 'i18n-js';
import {
    Menu,
    MenuItem,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Theme,
    createStyles,
} from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AddIcon from '@material-ui/icons/Add';

import commonStyles from 'config/commonStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        chooseDialogListItem: {
            padding: 0,
            '& svg': {
                fill: 'rgba(0, 0, 0, 0.54)',
                paddingRight: '20px',
                marginBottom: -8,
            },
            '&:hover, &:active': {
                backgroundColor: 'transparent',
            },
        },
        usersChooseDropdown: {
            '& .MuiPopover-paper': {
                left: 'calc(50% - 90px) !important',
            },
        },
        bottomLine: {
            borderBottom: `1px solid ${theme.palette.grey['300']}`,
        },
    })
);

interface AddNewUserModalProps {
    anchorEl: null | HTMLElement;
    handleClose: any;
    openNew: any;
    openExists: any;
}

export default function AddNewUserModal({
    anchorEl,
    handleClose,
    openNew,
    openExists,
}: AddNewUserModalProps) {
    const classes = useStyles();
    return (
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.usersChooseDropdown}
        >
            <MenuItem onClick={handleClose} className={classes.bottomLine}>
                <ListItem
                    onClick={() => openNew()}
                    key="new"
                    className={classes.chooseDialogListItem}
                >
                    <ListItemAvatar>
                        <AddIcon color="primary" />
                    </ListItemAvatar>
                    <ListItemText primary={i18n.t('common.createNew')} />
                </ListItem>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <ListItem
                    onClick={() => openExists()}
                    key="exists"
                    className={classes.chooseDialogListItem}
                >
                    <ListItemAvatar>
                        <FormatListBulletedIcon color="primary" />
                    </ListItemAvatar>
                    <ListItemText primary={i18n.t('common.selectFromTheList')} />
                </ListItem>
            </MenuItem>
        </Menu>
    );
}
