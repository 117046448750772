import { Action, Reducer } from 'redux';

import { Positions } from 'constants/enums';
import { RestaurantsAction } from './actions';

export interface RestaurantsState {
    restaurants: RestaurantData[];
    count: number;
}

export interface RestaurantData {
    id: string;
    name: string;
    addressLine: string;
    zip: string;
    city: string;
    logoUrl: string;
    isDisabled?: boolean;
    positions?: Positions[];
    currentPosition?: Positions;
    currentCategory?: string;
}

export interface RestaurantCreateModel {
    name: string;
    addressLine: string;
    zip: string;
    city: string;
    phoneNumber: string;
    email: string;
    website: string;
    longitude: number;
    latitude: number;
}

export interface RestaurantPagedModel {
    list: RestaurantData[];
    count: number;
}

const initialState: RestaurantsState = {
    restaurants: [],
    count: 0,
};

export const reducer: Reducer<RestaurantsState> = (
    state: RestaurantsState | undefined,
    incomingAction: Action
): RestaurantsState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as RestaurantsAction;
    switch (action.type) {
        case 'RESTAURANTS_GET_SUCCESS':
            return {
                ...state,
                restaurants: action.restaurantPagedModel.list,
                count: action.restaurantPagedModel.count,
            };
        case 'RESTAURANTS_CREATE_SUCCESS':
            return {
                ...state,
                restaurants: state.restaurants.concat(action.new),
            };

        case 'RESTAURANT_SWITCH_STATUS_SUCCESS': {
            const selectedRestaurant = action.data;
            const selectedRestaurantIndex = state.restaurants.findIndex(
                (each) => each.id === selectedRestaurant.id
            );
            const newRestaurantsList = [...state.restaurants];

            if (selectedRestaurantIndex !== -1) {
                newRestaurantsList[selectedRestaurantIndex] = selectedRestaurant;
            }

            return {
                ...state,
                restaurants: newRestaurantsList,
            };
        }
        default:
            return state;
    }
};
